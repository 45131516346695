import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-form-dynamic',
  templateUrl: './form-dynamic.component.html',
  styleUrls: ['./form-dynamic.component.css']
})
export class FormDynamicComponent implements OnInit {
  files: File[] = []
  file: boolean = false;
  submitted = false;
  postulante: any = {
    id: null,
    pais: null
  };
  docs: string = '';
  documentosRequeridos: any[] = [];
  statusLoader: string = 'Cargando';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService) {
      var url = window.location.href;
      try{
        this.postulante["id"] = url.split("?id=")[1].split("&")[0];
        this.postulante["pais"] = decodeURIComponent(url.split("&pais=")[1]).split("&")[0];
        this.docs = decodeURIComponent(url.split("&docs=")[1]);
        
        const valores = this.docs.split(',');

        this.documentosRequeridos = valores.map(valor => ({ value: valor.trim() }));
        
      }
      catch(e){
        console.log(e)
      }

      if(!this.postulante.id && !this.postulante.pais){
        this.router.navigate(['/oportunidades-laborales']);
      }
    }

  ngOnInit(): void {
    console.log(this.documentosRequeridos)
  }
  
  submitFiles(){
    this.submitted = true;
    this.files.forEach(file => {
      console.log(file.name);
    });

    if (this.files.length == this.documentosRequeridos.length) {
      console.log("Todos los archivos fueron llenados")
      const request = new FormData();

      this.files.forEach((file, index) => {
        request.append(`file${index}`, file);
      });
    
      request.append("id", this.postulante.id)
      request.append("pais", this.postulante.pais)

      this.spinner.show();
      this.service.postFormDocuments(request).subscribe(
        (response: any) => {
          console.log(response);
          this.statusLoader = 'Envio Exitoso';
          this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: '#45b8ea',
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        },
        (error: any) => {
          console.error("Error en la solicitud HTTP:", error);
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        }
      );
      this.statusLoader = 'Cargando'
      this.files = [];
      this.documentosRequeridos.forEach((documento, i) => {
        const input = document.getElementById(`archivo${i}`) as HTMLInputElement;
        if (input) {
            input.value = '';
        }
    });

    }else{
      console.log("Faltan archivos por cargar")
    }

    
  }


  onDocumentosSeleccionados(event: Event, index: number, label: string | undefined) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    
    if (file && label) {
      const formattedLabel = this.formatFileName(label);
      const fileName = `${formattedLabel}_dp.pdf`;
      const newFile = new File([file], fileName, { type: file.type });
      this.files[index] = newFile;
    }
  }
  
  formatFileName(label: string): string {
    // Reemplaza espacios y caracteres especiales (excepto letras, números y acentos) por guiones bajos
    return label.trim().replace(/[^\p{L}\p{N}\s]+/gu, "_");
  }  
  
}
