import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.css']
})
export class PrivacyPolicyModalComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  check: boolean = false;
  constructor() { }
  
  

  ngOnInit(): void {
  } 
  closeModal() {
    this.closeModalEvent.emit(this.check);
  }
  aceptedEvent(){
    if (this.check!= true) {
      // console.log('cerrando: ',this.check)
      setTimeout(() => {
        this.closeModal()
      }, 2000);
    }
    

  }

}
