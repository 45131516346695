<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
    <!-- <button routerLink="/terms-conditions" class="btn btn-rounded"><img src="assets/traducir.png" style="height:20px" alt=""></button> -->
  </div>
  
  <div class="content" role="main">
    <div class="main-container">
      <div class="form-container mt-3">
            <h2 class="text-center mb-3">TÉRMINOS Y CONDICIONES</h2>
            <hr class="mb-4">
            <p>La red de sitios web (en conjunto, los "Sitios web", "Sitios" o "Sitio") operados por las empresas de que forman parte de Genomma Lab (en adelante "Genomma Lab" o "nosotros") están compuestos por varios sitios web y páginas web. Los términos, condiciones y avisos aquí contenidos (en conjunto, los "Términos") se aplican a todos los sitios web de Genomma Lab.</p>
            <p>El acceso y uso del Sitio está sujeto a los términos y condiciones que se describen en este documento y a todas las leyes y regulaciones aplicables, incluyendo, aquellas que regulan los derechos de autor, marcas, patentes o cualquier otro derecho de propiedad intelectual en cualquier de los países donde opera Genomma Lab AL ACCEDER AL SITIO, CONFIRMA Y ESTÁ DE ACUERDO EN ACEPTAR, SIN LIMITACIÓN O CALIFICACIÓNALGUNA, LA TOTALIDAD DE LOS PRESENTES TÉRMINOS Y CONDICIONES. Genomma Lab se reserva el derecho de cambiar estos términos y condiciones de vez en cuando al actualizar esta publicación. Si no está de acuerdo con estos Términos, no use este Sitio ni ningún otro de los Sitios de Genomma Lab.</p>
            <p>El uso de los Sitios web de Genomma Lab también está sujeto al Aviso de Privacidad de Genomma Lab, mismo que se pone a su disposición en https://www.genommalab.com/politicas.</p>
            <p>A menos que se especifique lo contrario, el Sitio es sólo para su uso personal y no comercial. No puede modificar, copiar, distribuir, comunicar públicamente, transmitir, mostrar, ejecutar, reproducir, publicar, licenciar, crear trabajos derivados, transferir o vender cualquiera de los contenidos o comunicaciones contenidas o que se muestran en el Sitio ("Contenido"), incluyendo, de forma enunciativa mas no limitativa, textos, gráficos, fotografías, imágenes, imágenes en movimiento, sonido, ilustraciones, información, software, productos o servicios y sus respectivos arreglos, excepto conforme a lo expresamente permitido en el presente. El Contenido es propiedad de Genomma Lab y está protegido por derechos de autor, marcas, secretos industriales y otros derechos derivados de las leyes vigentes sobre propiedad intelectual. Usted reconoce que cualquier uso no autorizado de algún Contenido podría causarnos un daño irreparable y está de acuerdo en que, en caso de que se produzca dicho uso no autorizado, tendremos derecho a ejercer las acciones legales correspondientes además de cualquier otro recurso disponible por las leyes y/o regulaciones aplicables. Cualquier otro uso del Contenido, incluida la modificación, reproducción, comunicación pública, distribución, publicación, visualización o transmisión de cualquier Contenido, sin nuestro permiso previo por escrito, está estrictamente prohibido.</p>
            <p>La información que se llegue a publicar en este Sitio respecto a padecimientos que afectan la salud no debe ser considerada como diagnóstico final u opinión profesional respecto a algún malestar o padecimiento. El contenido del Sitio es meramente informativo, por lo que Genomma Lab recomienda que cualquier padecimiento o duda derivada de la información presentada sea atendida directamente por un especialista de la salud. En caso de que cualquier persona use la información publicada como diagnóstico final o como opinión profesional, Genomma Lab se deslinda de cualquier responsabilidad presente o futura derivada del mal uso que se haga de la información contenida en el Sitio.</p>
            <p>EL SITIO Y LA INFORMACIÓN CONTENIDA EN EL MISMO, SE PROPORCIONA "TAL CUAL" SIN GARANTÍAS DE NINGÚN TIPO, YA SEA EXPRESAS O IMPLÍCITAS, INCLUYENDO, DE FORMA ENUNCIATIVA MAS NO LIMITATIVA, GARANTÍAS DE TÍTULO O PROPIEDAD, O GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD O IDONEIDAD PARA UN PRÓPOSITO EN PARTICULAR, EXCEPTO POR LAS GARANTÍAS QUE SON IMPUESTAS E INCAPACES DE SER EXCLUIDAS, RESTRINGIDAS O MODIFICADAS BAJO, LAS LEYES Y/O REGULACIONES APLICABLES A ESTOS TÉRMINOS.</p>
            <p>EN LA MEDIDA QUE LO PERMITAN LAS LEYES Y/O REGULACIONES APLICABLES, NI GENOMMA LAB NI NINGUNA DE SUS ENTIDADES CONTROLADORAS, SUBSIDIARIAS, DIVISIONES, AFILIADOS, AGENTES, REPRESENTANTES O LICENCIANTES TENDRÁN RESPONSABILIDAD ALGUNA FRENTE A USTED O FRENTE A CUALQUIER OTRA PERSONA POR PÉRDIDAS O PERJUICIOS O POR CUALESQUIERA DAÑOS, PERJUICIOS, DIRECTOS, INDIRECTOS, INCIDENTALES, CONSECUENCIALES, ESPECIALES, PUNITIVOS O DAÑOS SIMILARES QUE SURJAN, O DERIVEN, DE SU ACCESO O USO, O DE SU INCAPACIDAD PARA ACCEDER O USAR, ESTE SITIO.</p>
            <p>La información comunicada en los Sitios puede contener información de terceros y/o enlaces a páginas externas de terceros. Genomma Lab no es responsable, de forma alguna, de cualquier contenido de terceros que se publique, se cargue, sea creado, se comparta o, que, de cualquier otra manera, exista en cualquier presencia web de Genomma Lab a la que aplican estos Términos. Genomma Lab no tiene ninguna responsabilidad con, o frente a, usted ni con cualquier otra parte por la existencia o la eliminación de cualquier contenido de terceros. El contenido de terceros no refleja las opiniones, creencias, declaraciones o acciones de Genomma Lab. Genomma Lab rechaza y excluye específicamente cualquier responsabilidad que pueda surgir de lo antes mencionado. Adicionalmente y sin limitar lo anterior, la información presentada en dichas páginas de terceros es responsabilidad de los titulares de las mismas. Genomma Lab, se deslinda de cualquier responsabilidad de la información publicada en nombres de dominio y/o páginas web de terceros.</p>
            <p>Usted reconoce: (i) que es técnicamente imposible proporcionar el Sitio libre de cualquier defecto y que Genomma Lab no puede comprometerse a hacerlo; (ii) que los defectos pueden hacer que el Sitio no esté disponible temporalmente; y que (iii) el funcionamiento del Sitio puede verse afectado por eventos y/o asuntos que Genomma Lab no controla, como, por ejemplo, los medios de transmisión y comunicación entre usted y Genomma Lab y entre Genomma Lab y otras redes, por lo que Genomma Lab se deslinda de por la interrupción en los servicios, el retraso en la operación o transmisión de la información, por posibles virus de computadora, pérdida de datos o cualquier otra falla relacionada con el funcionamiento del Sitio.</p>
            <p>Genomma Lab pone a su disposición nuestro aviso de privacidad en el sitio https://www.genommalab.com/politicas,  por lo que se les recomienda leerlo en su totalidad antes de usar éste o cualquiera de los Sitios y/o cualquiera de nuestros servicios vía internet o a través de aplicaciones informáticas, ya que dicho Aviso de Privacidad regula nuestro tratamiento de cualquier información personal, incluidos los datos personales que nos proporcione. Todos los usuarios de este Sitio deben considerar que cierta información, declaraciones, datos y contenido que proporcionen voluntariamente a Genomma Lab podrían, o muy probablemente pueden utilizarse para identificar a los titulares de dichos datos o revelar su sexo, origen étnico, nacionalidad, edad u otros datos personales. </p>
            <p>Estos Términos y cualquier disputa o controversia que surja se regirán e intepretarán de conformidad con las leyes y los tribunales de la localidad aplicable, renunciando desde este momento a cualquier otra jurisdicción que les pudiera corresponder a las partes por razones de su domicilio o cualquier otro motivo</p>
      </div>
    </div>
  
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
      <path id="Path_39" data-name="Path 39"
        d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
        transform="translate(142.69 -634.312)" fill="#eee" />
    </svg>
  
  </div>
  <div>
    <app-footer [idioma]="idiomaActual"></app-footer>
  </div>
  