import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServices } from '../api.service';


@Component({
  selector: 'app-formato-compensacion',
  templateUrl: './formato-compensacion.component.html',
  styleUrls: ['./formato-compensacion.component.css']
})
export class FormatoCompensacionComponent implements OnInit {
  files: [] = [];
  fileSelected: File [] = [];
  statusLoader: string = 'Cargando';
  file: boolean = false;
  submitted = false;
  postulante: any = {
    id: null,
    pais: null
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService
  ) { 
    var url = window.location.href;
      try{
        this.postulante["id"] = url.split("?id=")[1].split("&")[0];
        this.postulante["pais"] = decodeURIComponent(url.split("&pais=")[1]);
      }
      catch(e){
        console.log(e)
      }

      if(!this.postulante.id && !this.postulante.pais){
        this.router.navigate(['/oportunidades-laborales']);
      }
  }

  ngOnInit(): void {
  }

  submitForm(){
    this.submitted = true
    if (this.fileSelected) {
      console.log(this.fileSelected)

      const request = new FormData();
      this.fileSelected.forEach((file, index) => {
        request.append(`file${index}`, file);
      });

      request.append("id", this.postulante.id)
      request.append("pais", this.postulante.pais)
      this.spinner.show();
      this.service.postFormDocuments(request).subscribe(
        (response: any) => {
          console.log(response);
          this.statusLoader = 'Envio Exitoso';
          this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: '#45b8ea',
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
          
          const input = document.getElementById('idArchivo') as HTMLInputElement;
          input.value = '';
          const nominas = document.getElementById('reciboNomina') as HTMLInputElement;
          nominas.value = '';
          this.fileSelected = [];
        },
        (error: any) => {
          console.error("Error en la solicitud HTTP:", error);
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        }
      );
      this.statusLoader = 'Cargando'

    }
  }
  onFileSelected(event: any, label: string) {
    if (event.target.files.length > 0) {
      this.file = true;
      
      
      const file = event.target.files[0];
      const extensionName = file.name.split('.').pop()
      const newFileName = `${label}_onepager.${extensionName}`;
      // Crear un nuevo File con el Blob renombrado
      const renamedFile = new File([file], newFileName, { type: file.type });
  
      this.fileSelected.push(renamedFile)
    }else {
      this.file = false;
    }
  } 

}
