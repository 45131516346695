import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tabla-reportes',
  templateUrl: './tabla-reportes.component.html',
  styleUrls: ['./tabla-reportes.component.css']
})
export class TablaReportesComponent implements OnInit {
  @Input() selectedReport: string = '';
  @Input() selectedCluster: string = '';
  constructor() { }

  ngOnInit(): void {
  }

 
}
