import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServices } from '../api.service';

@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosComponent implements OnInit {
  fileSelected: File | undefined = undefined;
  statusLoader: string = 'Cargando';
  postulante: any = {
    id: null,
    pais: null,
    documento: null,
  };
  constructor(
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService
  ) { 
    var url = window.location.href;
      try{
        this.postulante["id"] = url.split("?id=")[1].split("&")[0];
        this.postulante["pais"] = decodeURIComponent(url.split("&pais=")[1]).split("&")[0];
        this.postulante["documento"] = decodeURIComponent(url.split("&documento=")[1]);
      }
      catch(e){
        console.log(e)
      }

      if(!this.postulante.id && !this.postulante.pais){
        this.router.navigate(['/oportunidades-laborales']);
      }
  }

  ngOnInit(): void {
  }

  submitForm(){
    if (this.fileSelected) {
      
      const request = new FormData();
      request.append("file", this.fileSelected)

      request.append("id", this.postulante.id)
      request.append("pais", this.postulante.pais)
      this.spinner.show();
      this.service.uploadDocumentsInVacants(request).subscribe(
        (response: any) => {
          console.log(response);
          this.statusLoader = 'Envio Exitoso';
          this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: '#45b8ea',
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        },
        (error: any) => {
          console.error("Error en la solicitud HTTP:", error);
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        }
      );
      this.statusLoader = 'Cargando'

    }
  }
  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      var newFileName;
      if(this.postulante["documento"].toLowerCase().includes("caso de negocio")){
        newFileName = 'caso_negocio.pdf';
      } else {
        newFileName = 'formato_compensacion.xlsx';
      }
      
      const file = event.target.files[0];
      
      // Crear un nuevo File con el Blob renombrado
      const renamedFile = new File([file], newFileName, { type: file.type });
  
      this.fileSelected = renamedFile;
    }
  }  
}
