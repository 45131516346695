<div class="toolbar" role="banner">
  <img
    style="padding-left: 3rem"
    width="200"
    src="assets/genommalab-logo.svg"
  />
</div>

<div class="centered-content">
    <div class="content" role="main">
      <div class="centered-container">
        <div class="toolbar" role="banner">
          <img
            id="success-image"
            width="300"
            src="assets/success.png"
          />
        </div>
  
        <div class="btn-section">
          <button class="btn btn-primary btn-login" (click)="onClick()">Finalizar</button>
        </div>
      </div>
    </div>
  </div>
  