import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-redirect-linkedin',
  templateUrl: './redirect-linkedin.component.html',
  styleUrls: ['./redirect-linkedin.component.css']
})
export class RedirectLinkedinComponent implements OnInit {
  referenciasForm!: FormGroup;
  paramsRouter: any = {
    code: null,
    email: null
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService
    ) {

      var url = window.location.href;
      try{
        this.paramsRouter["code"] = url.split("?code=")[1].split("&")[0];
        this.paramsRouter["email"] = decodeURIComponent(url.split("&state=")[1]);
      }
      catch(e){
        console.log(e)
      }

      if(!this.paramsRouter.code){
        this.router.navigate(['/oportunidades-laborales']);
      }
    }

    ngOnInit(): void {
        console.log(this.paramsRouter.code);
        this.submit();
    }
    

    submit(){
      if(this.paramsRouter.code && this.paramsRouter.email){
        const formData = new FormData();

        formData.append("code", this.paramsRouter.code);
        formData.append("email", this.paramsRouter.email);

        this.service.generateNewToken(formData).subscribe(
          (response: any) => {
            console.log(response);
          },
          (error: any) => {
            console.log("ocurrió un error en la solicitd: ", error);
          }
        );

      }
    }

    onClick(){
        this.router.navigate(['/oportunidades-laborales']);
    }
}

