<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
</div>
<div class="content">
    <div class="main-form-head">
          <div class="form-container mt-3">
            <div class="row ">
              <div class="col">
                <h4 class="margin-h4"><Strong>Solicitud</Strong></h4>
                <h5><p>{{vacante["vacante"]}}</p></h5>
                <p>Llena los siguientes campos que se te solicitan</p>
              </div>
          </div>
          <form [formGroup]="modalForm" (ngSubmit)="openModal()" >
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    
                        <div class="row">
                          <div class="form-group mb-3">
                              <label class="color-primary mb-1 "><strong>Nombre completo *</strong></label>
                              <input type="text" class="form-control" formControlName="nombre" placeholder="Ingresa tu nombre completo" title="Ingresa tu nombre completo"
                              [ngClass]="{ 'is-invalid': submitted && fa['nombre']['errors'], 'is-valid': fa['nombre'].valid }"   />
                              <small class="form-text text-muted danger" *ngIf="submitted && fa['nombre']['errors']"
                                class="invalid-feedback">
                                <div class="" *ngIf="fa['nombre']['errors']['required']">
                                  Olvidaste llenar el campo *</div>
                              </small>
                            </div>
                            <!-- <div class="form-group mb-3">
                              <label class="color-primary mb-1 "><strong>Número de teléfono *</strong></label>
                              <input type="text" maxlength="10" (keydown)="blockLetras($event)" class="form-control" formControlName="numero" placeholder="Ingresa tu número de teléfono" title="Ingresa tu número de teléfono"
                              [ngClass]="{ 'is-invalid': submitted && fa['numero']['errors'], 'is-valid': fa['numero'].valid }"   />
                              <small class="form-text text-muted danger" *ngIf="submitted && fa['numero']['errors']"
                                class="invalid-feedback">
                                <div class="" *ngIf="fa['numero']['errors']['required']">
                                  Olvidaste llenar el campo *</div>
                              </small>
                            </div> -->
                        </div>
      
                        <div class="form-group mb-3">
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <label class="color-primary"><strong>Formación Académica *</strong></label>
                              <select class="custom-select" formControlName="formacionInput" id="FormControlSelectFormacionAcademica" [ngClass]="{'is-invalid': submitted && fa['formacionInput']['errors'], 'is-valid': fa['formacionInput'].valid }">
                                <option>Primaria</option>
                                <option>Secundaria</option>
                                <option>Auxiliares de salud</option>
                                <option>Bachillerato o Preparatoria</option>
                                <option>Curso Posténico de Enfermería</option>
                                <option>Carrera Técnica</option>
                                <option>Licenciatura, Ingeniera y Técnico en superior universitario</option>
                                <option>Especialización en Educación Superior</option>
                                <option>Maestría</option>
                                <option>Doctorado</option>
                                <option>Especialidades Médicas</option>
                                <option>Curso de Alta Especialidad</option>
                              </select>
                              <small class="form-text text-muted danger" *ngIf="submitted && fa['formacionInput']['errors']"
                              class="invalid-feedback">
                                <div *ngIf="fa['formacionInput']['errors']['required']">
                                  Selecciona una opción *</div>
                              </small>
                            </div>
                          </div>
                        </div>
        
                          <div class="form-group mb-3">
                            <label class="color-primary "><strong>Idiomas hablados *</strong></label>
                            <ng-select
                            [ngClass]="{ 'is-invalid': submitted && fa['idiomas']['errors'], 'is-valid': fa['idiomas'].valid }"
                            [items]="idiomasOptions"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="true"
                            formControlName="idiomas"
                          ></ng-select>
                            <small class="form-text text-muted danger" *ngIf="submitted && fa['idiomas']['errors']"
                            class="invalid-feedback">
                              <div class="" *ngIf="fa['idiomas']['errors']['required']">
                                Selecciona una opción *</div>
                            </small>
                          </div>
                          
                          <div class="form-group mb-3 gen-space">
                            <label class="color-primary " for="exampleFormControlSkills"><strong>Habilidades relevantes para el puesto *</strong></label>
                            <textarea class="form-control" formControlName="Habilidades" id="exampleFormControlSkills" rows="1"  [ngClass]="{ 'is-invalid': submitted && fa['Habilidades']['errors'], 'is-valid': fa['Habilidades'].valid }" ></textarea>
                            <small class="form-text text-muted danger" *ngIf="submitted && fa['Habilidades']['errors']"
                              class="invalid-feedback">
                              <div *ngIf="fa['Habilidades']['errors']['required']">
                                Olvidaste llenar el campo *</div>
                            </small>
                          </div>


                          <div class="form-group mb-3 gen-space">
                            <label class="color-primary " for="inputisActiveToNotyfy"><strong>Habilitar Notificaciones *</strong></label>
                            <select id="inputisActiveToNotyfy" formControlName="isActiveToNotyfy" class="custom-select " title="" 
                             [ngClass]="{ 'is-invalid': submitted && fa['isActiveToNotyfy']['errors'], 'is-valid': fa['isActiveToNotyfy'].valid }" >
                              <option value="1">SÍ</option>
                              <option value="2">NO</option>
                            </select>
                            <small class="form-text text-muted danger" *ngIf="submitted && fa['isActiveToNotyfy']['errors']"
                              class="invalid-feedback">
                              <div class="" *ngIf="fa['isActiveToNotyfy']['errors']['required']">
                                Selecciona una opción *</div>
                            </small>
                          </div>

                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="form-group mb-3">
                      <label class="color-primary mb-1 "><strong>Dirección *</strong></label>
                      <input type="text" class="form-control" formControlName="direccion" placeholder="Ingresa tu dirección" title="Ingresa tu dirección"
                      [ngClass]="{ 'is-invalid': submitted && fa['direccion']['errors'], 'is-valid': fa['direccion'].valid }"   />
                      <small class="form-text text-muted danger " *ngIf="submitted && fa['direccion']['errors']"
                        class="invalid-feedback">
                        <div class="" *ngIf="fa['direccion']['errors']['required']">
                          Olvidaste llenar el campo *</div>
                      </small>
                    </div>

                    <div class="form-group mb-3">
                      <label class="color-primary mb-1 "><strong>Correo electrónico *</strong></label>
                      <input type="email" class="form-control" formControlName="correoElectronico" placeholder="Ingresa tu correo electrónico" title="Ingresa tu correo electrónico"
                      [ngClass]="{ 'is-invalid': submitted && fa['correoElectronico']['errors'], 'is-valid': fa['correoElectronico'].valid }"   />
                      <small class="form-text text-muted danger" *ngIf="submitted && fa['correoElectronico']['errors']"
                        class="invalid-feedback">
                        <div class="" *ngIf="fa['correoElectronico']['errors']['required']">
                          Olvidaste llenar el campo *</div>
                      </small>
                    </div>

                    <div class="row mb-3">
                      <div class="form-group col-md-6 col-lg-4">
                        <label class="color-primary "><strong>Rango salarial *</strong></label>
                        <input type="number" class="form-control" formControlName="rangoSalarialMin" placeholder="Min" title="Ingreso mínimo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMin']['errors'], 'is-valid': fa['rangoSalarialMin'].valid }" />
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMin']['errors']"
                          class="invalid-feedback">
                          <div class="" *ngIf="fa['rangoSalarialMin']['errors']['required']">
                            Olvidaste llenar el campo *</div>
                        </small>
                      </div>
                      <div class="form-group col-md-6 col-lg-4 range-max">
                        <input type="number" class="form-control" formControlName="rangoSalarialMax" placeholder="Max" title="Ingreso máximo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMax']['errors'], 'is-valid': fa['rangoSalarialMax'].valid }" />
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMax']['errors']"
                          class="invalid-feedback">
                          <div class="" *ngIf="fa['rangoSalarialMax']['errors']['required']">
                            Olvidaste llenar el campo *</div>
                        </small>
                      </div>

                      <div class="form-group col-md-6 col-lg-4">
                        <label class="color-primary " for="inputMoneda"><strong>Moneda *</strong></label>
                        <select id="inputMoneda" formControlName="moneda" class="custom-select " title="Selecciona el tipo de moneda"  [ngClass]="{ 'is-invalid': submitted && fa['moneda']['errors'], 'is-valid': fa['moneda'].valid }" >
                          <option value="MXN">MXN</option>
                          <option value="USD">USD</option>
                          <option value="COP">COP</option>
                          <option value="PEN">PEN</option>
                          <option value="CRC">CRC</option>
                          <option value="PAB">PAB</option>
                          <option value="HNL">HNL</option>
                          <option value="SVC">SVC</option>
                          <option value="NIO">NIO</option>
                          <option value="GTQ">GTQ</option>
                          <option value="DOP">DOP</option>
                          <option value="ARS">ARS</option>
                          <option value="CLP">CLP</option>
                          <option value="UYU">UYU</option>
                          <option value="PYG">PYG</option>
                          <option value="BRL">BRL</option>
                        </select>
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['moneda']['errors']"
                          class="invalid-feedback">
                          <div class="" *ngIf="fa['moneda']['errors']['required']">
                            Selecciona una opción *</div>
                        </small>
                      </div>
                    </div>

                    <div class="form-group mb-3">
                      <label class="color-primary mb-1"><strong>Sube tu CV * (.PDF)</strong></label>
                      <input type="file" class="form-control" formControlName="fileCV" placeholder="Ingresa tu nombre completo" title="Ingresa tu nombre completo"
                      [ngClass]="{ 'is-invalid': submitted && fa['fileCV']['errors'], 'is-valid': fa['fileCV'].valid }" (change)="uploadFile($event)" accept=".pdf" />
                      <small class="form-text text-muted danger" *ngIf="submitted && fa['fileCV']['errors']"
                        class="invalid-feedback">
                        <div *ngIf="fa['fileCV']['errors']['required']">
                          Olvidaste llenar el campo *</div>
                      </small>
                    </div>
                </div>
            </div>
          
            <div class="row text-center">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="btn-section">
                      <button  type="button" (click)="openModal()" class="btn btn-primary mb-3">
                        Enviar solicitud
                      </button>
                      <app-privacy-policy-modal *ngIf="isModalOpen" (closeModalEvent)="closeModal($event)"></app-privacy-policy-modal>
                      <small class="form-text text-muted danger" *ngIf="accept_policies === true">
                      <p class="fw-bold" style="color: red;">Acepta las políticas, términos y condiciones *</p>
                    </small>
                    </div>
                </div>
            </div>
          </form>
          </div>
          
    </div>
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
        <path id="Path_39" data-name="Path 39"
          d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
          transform="translate(142.69 -634.312)" fill="#eee" />
      </svg>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
  
