import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RoleProfileComponent } from './role-profile/role-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OportunidadesLaboralesComponent } from './oportunidades-laborales/oportunidades-laborales.component';
import { DetailsComponent } from './details/details.component';
import { FormularioHeadHuntingComponent } from './formulario-head-hunting/formulario-head-hunting.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { PrivacyContentComponent } from './privacy-content/privacy-content.component';
import { TermsContentComponent } from './terms-content/terms-content.component';
import { BusinessCaseComponent } from './business-case/business-case.component';
import { FormDynamicComponent } from './form-dynamic/form-dynamic.component';
import { EjercicioFinanzasComponent } from './ejercicio-finanzas/ejercicio-finanzas.component';
import { FormatoCompensacionComponent } from './formato-compensacion/formato-compensacion.component';
import { ReferenciasLaboralesComponent } from './referencias-laborales/referencias-laborales.component';
import { FormatosComponent } from './formatos/formatos.component';
import { CanActivateTeam } from './active-token.service';
import { RedirectLinkedinComponent } from './redirect-linkedin/redirect-linkedin.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { TermosCondiComponent } from './termos-condi/termos-condi.component';

const routes: Routes = [
  { path: 'login', 
    component: LoginComponent ,
  },
  { path: 'role-profile', 
    component: RoleProfileComponent,
    canActivate: [CanActivateTeam]
  },
  { path: 'dashboard', 
    component: DashboardComponent,
    canActivate: [CanActivateTeam]
  },
  { path: 'oportunidades-laborales', 
    component: OportunidadesLaboralesComponent 
  },
  { path: 'details',
    component: DetailsComponent
  },
  { path: 'politicas',
    component: PoliticasPrivacidadComponent
  },
  { path: 'aviso-legal',
    component: TerminosCondicionesComponent
  },
  { path: 'terms-conditions',
    component: TermsContentComponent
  },
  { path: 'privacy-policy',
    component: PrivacyContentComponent
  },
  { path: 'terminos-po',
    component: TermosCondiComponent
  },
  { path: 'politicas-po',
    component: PoliticaPrivacidadeComponent
  },
  { path: 'formulario-headHunting',
      component: FormularioHeadHuntingComponent
    },
  {path: 'form-business-case',
    component: BusinessCaseComponent
  },
  {path: 'form-documents',
    component: FormDynamicComponent
  },
  {path: 'form-ejercicio-finanzas',
    component: EjercicioFinanzasComponent
  },
  {path: 'form-compensacion',
    component: FormatoCompensacionComponent
  },
  {
    path:'referencia-laboral',
    component: ReferenciasLaboralesComponent
  },
  {
    path:'formatos',
    component: FormatosComponent
  },
  {
    path:'redirect-linkedin',
    component: RedirectLinkedinComponent
  },
  { path: '', redirectTo: '/oportunidades-laborales', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
