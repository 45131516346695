import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-referencias-laborales',
  templateUrl: './referencias-laborales.component.html',
  styleUrls: ['./referencias-laborales.component.css']
})
export class ReferenciasLaboralesComponent implements OnInit {
  referenciasForm!: FormGroup;
  statusLoader: string = 'Cargando';
  submitted = false;
  postulante: any = {
    id: null,
    pais: null
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService) {

      var url = window.location.href;
      try{
        this.postulante["id"] = url.split("?id=")[1].split("&")[0];
        this.postulante["pais"] = decodeURIComponent(url.split("&pais=")[1]);
      }
      catch(e){
        console.log(e)
      }

      if(!this.postulante.id && !this.postulante.pais){
        this.router.navigate(['/oportunidades-laborales']);
      }
    }

    ngOnInit(): void {
      this.referenciasForm = this.formBuilder.group({
        nombreReferencia1: [null, Validators.required],
        cargoReferencia1: [null, Validators.required],
        correoReferencia1: [null, [Validators.required, Validators.email]],
        numeroTelefonoReferencia1: [null, Validators.required],
        nombreReferencia2: [null, Validators.required],
        cargoReferencia2: [null, Validators.required],
        correoReferencia2: [null, [Validators.required, Validators.email]],
        numeroTelefonoReferencia2: [null, Validators.required],
        empresaReferencia1: [null, Validators.required],
        empresaReferencia2: [null, Validators.required]

      });
    }

    get fa(){
      return this.referenciasForm.controls;
    }

    submitForm() {
      this.submitted = true;
      console.log(this.submitted)
      if (this.referenciasForm.valid) {

        const formData = new FormData();
        Object.keys(this.referenciasForm.value).forEach(key => {
          formData.append(key, this.referenciasForm.get(key)!.value);
        });
        
        formData.append("id", this.postulante.id);
        formData.append("pais", this.postulante.pais);

        this.spinner.show();
        this.service.registerJobReferency(formData).subscribe(
          (response: any) => {
            console.log(response);
            this.statusLoader = 'Envio Exitoso';
            this.spinner.show(undefined, { 
                type: 'ball-scale-multiple', 
                color: '#45b8ea',
            });
            setTimeout(() => {
              this.spinner.hide();
            }, 3000);
            this.referenciasForm.reset();
          },
          (error: any) => {
            console.error("Error en la solicitud HTTP:", error);
            this.statusLoader = 'Ha ocurrido un error intenta más tarde';
            this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: 'red', 
            });
            setTimeout(() => {
              this.spinner.hide();
            }, 3000);
          }
        );
        this.statusLoader = 'Cargando'
      }
      else{
        return;
      }
    }
}

