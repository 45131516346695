<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
</div>
<div class="container-form">
    <div class="form-data">
        <div class="title">
            <h2 class="text-center">Documentos</h2>
            <p class="text-center">Sube los archivos que se te solicitan</p>
        </div>
        <form class="scroll-documents">
            <div class="row padding-form">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 class="text-danger text-center" *ngIf="submitted && !file">Por favor verifica que todos los campos estén llenos.*</h4>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3" *ngFor="let documento of documentosRequeridos; let i = index">
                    <label for="archivo{{i}}" class="color-primary mb-2">{{documento?.value}}<small class="text-danger">*</small></label>
                    <input type="file" class="form-control" id="archivo{{i}}" title="Ingresa tu archivo" accept=".pdf"
                    (change)="onDocumentosSeleccionados($event, i, documento?.value)"/>


                             </div>
                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                    <button type="submit" (click)="submitFiles()"  class="btn btn-primary btn-login mb-3" >Enviar archivos</button>
                </div>
            </div>
        </form>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
