import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla-success-rate-global',
  templateUrl: './tabla-success-rate-global.component.html',
  styleUrls: ['./tabla-success-rate-global.component.css']
})
export class TablaSuccessRateGlobalComponent implements OnInit {
  
  @Input() NameTableSuccess: String = '';
  @Input() dataSuccessRate: any;
  successRate: any;
  current_year: number = 0;


  constructor() {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.successRate = this.dataSuccessRate["general"][0]["report"];
    console.log(this.successRate)
  }

  calculatePercentage(numerator: number, denominator: number): string {
    if (numerator === 0 || denominator === 0) {
      return '0%';  // Retorna '0%' si el numerador o el denominador son 0
    } else {
      let percentage = this.round((numerator / denominator) * 100);
      if (percentage > 100) {
        return `100%`; 
      }
      return `${percentage}%`;
    }
  }
 
  round(value: number): number {
    return Math.round(value);
  }

}
