import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ejercicio-finanzas',
  templateUrl: './ejercicio-finanzas.component.html',
  styleUrls: ['./ejercicio-finanzas.component.css']
})
export class EjercicioFinanzasComponent implements OnInit {
  fileSelected: File | undefined = undefined;
  file: boolean = false;
  submitted = false;
  postulante: any = {
    id: null,
    pais: null,
    name: null,
    vacante: null,
    responsable: null,
    email: null
  };
  statusLoader: string = 'Cargando';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiServices,
    private spinner: NgxSpinnerService) 
    { 
      var url = window.location.href;
      try{
        this.postulante["id"] = url.split("?id=")[1].split("&")[0];
        this.postulante["pais"] = decodeURIComponent(url.split("&pais=")[1].split("&")[0]);
        this.postulante["name"] = decodeURIComponent(url.split("&candidato=")[1].split("&")[0]);
        this.postulante["vacante"] = decodeURIComponent(url.split("&vacante=")[1].split("&")[0]);
        this.postulante["responsable"] = decodeURIComponent(url.split("&responsable=")[1].split("&")[0]);
        this.postulante["email"] = decodeURIComponent(url.split("&email=")[1]);
      }
      catch(e){
        console.log(e)
      }

      if(!this.postulante.id && !this.postulante.pais){
        this.router.navigate(['/oportunidades-laborales']);
      }
    }

  ngOnInit(): void {
  }

  submitForm(){
    this.submitted = true;
    if (this.fileSelected) {
      console.log(this.fileSelected.name)

      const request = new FormData();
      request.append("file", this.fileSelected)

      request.append("id", this.postulante.id)
      request.append("pais", this.postulante.pais)
      request.append("vacante", this.postulante.vacante)
      request.append("candidato", this.postulante.name)
      request.append("responsable", this.postulante.responsable)
      request.append("email", this.postulante.email)

      this.spinner.show();

      this.service.sendEmailAdrianaToDocFinanzas(request).subscribe(
        (response: any) => {
          console.log(response);
          this.statusLoader = 'Envio Exitoso';
          this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: '#45b8ea',
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
          this.fileSelected = undefined;
          const input = document.getElementById('idArchivo') as HTMLInputElement;
          input.value = '';
        },
        (error: any) => {
          console.error("Error en la solicitud HTTP:", error);
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
          this.fileSelected = undefined;
          const input = document.getElementById('idArchivo') as HTMLInputElement;
          input.value = '';
        }
      );
      this.statusLoader = 'Cargando'
    }else{
      return;
    }

  }
  
  
  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.file = true;
      console.log(this.file);
      const file = event.target.files[0];
      const newFileName = 'comparativa_económica'; // Nombre deseado sin la extensión
  
      // Agrega la extensión original al nuevo nombre de archivo
      const extension = file.name.split('.').pop();
      const renamedFileName = `${newFileName}.${extension}`;
  
      // Crea un nuevo File con el Blob renombrado
      const renamedFile = new File([file], renamedFileName, { type: file.type });
  
      this.fileSelected = renamedFile;
    } else {
      this.file = false;
      console.log(this.file);
    }
  }
  
}
