<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" loading="lazy" alt="logo-principal" width="200" src="assets/genommalab-logo.svg" />
      <!-- <button (click)="onTraducir()" class="btn btn-rounded">
        <img src="assets/traducir.png" loading="lazy" alt="icono traducir" style="height:20px">
      </button> -->
      <div class="dropdown" title="idioma">
        <button class="btn btn-rounded dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/traducir.png" loading="lazy" alt="icono traducir" style="height:20px">
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('espanol')"> <img src="assets/espana.png" alt="bandera" style="height:20px; margin-right: 8px;">Español</a></li>
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('ingles')"> <img src="assets/estados-unidos.png" alt="bandera" style="height:20px; margin-right: 8px;">Inglés</a></li>
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('portugues')"> <img src="assets/bandera.png" alt="bandera" style="height:20px; margin-right: 8px;">Portugués</a></li>
        </ul>
      </div>
      
</div>

  <div class="content" role="main">
  
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
      <path id="Path_39" data-name="Path 39"
        d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
        transform="translate(142.69 -634.312)" fill="#eee" />
    </svg>
  </div>

<div class="espaciado">
  <div class="text-center">
      <h1 class="title-jobs">{{ traducciones.title }}</h1>
  </div>
  <div>
    <div class="d-flex justify-content-center mt-4">
      <form class="d-flex input-search" role="search">
        <input class="form-control input-style me-2 " type="search" placeholder="{{ traducciones.placeholderSearch }}" aria-label="Search" (keyup)="search($event)">
        <button class="btn button-search me-2" type="submit">{{ traducciones.textButton }}</button>
      </form>
    </div>
  </div>
  
  <div class="row" style="margin-top: 8px; display: flex; justify-content: center; margin-right: 2px;">
    <div class="" style="width: 225px; max-width: 280px;">
      <div class="form-group mb-3">
          <label class="color-primary" for="select">{{ traducciones.filtroPais }}</label>
          <ng-select
          class="custom"
          [items]="paisOptions"
          bindLabel="name"
          bindValue="id"
          [multiple]="true"
          [placeholder]='traducciones.placeholderPais'
          [(ngModel)]="selectedCountryArray"
          (ngModelChange)="searchByCountry()"
        ></ng-select>
      </div>
    </div>
    <div class="" style="width: 200px; max-width: 280px;">
      <div class="form-group mb-3">
          <label class="color-primary" for="select">{{ traducciones.filtroArea }}</label>
          <ng-select
          class="custom"
          [items]="areaOptions"
          bindLabel="name"
          bindValue="id"
          [multiple]="true"
          [placeholder]='traducciones.placeholderArea'
          [(ngModel)]="selectedByArea"
          (ngModelChange)="searchByArea()"
        ></ng-select>
      </div>
    </div>
    <div class="" style="width: 170px; max-width: 220px;">
      <div class="form-group mb-3">
          <label class="color-primary" for="select">{{ traducciones.filtroTipo }}</label>
          <ng-select
          class="custom"
          [items]="tipoOptions"
          bindLabel="name"
          bindValue="id"
          [multiple]="true"
          [placeholder]='traducciones.placeholderTipo'
          [(ngModel)]="selectedTipoArray"
          (ngModelChange)="searchByType()"
        ></ng-select>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <div class="card-grid">
      <div class="card card2" *ngFor="let vac of getVacantsForPage(pageAct)">
        <div class="go-corner" (click)="redirect(vac)">
          <div class="go-arrow">
            →
          </div>
        </div>
          <div class="container-img" (click)="redirect(vac)">
            <img class="img-card" src="{{vac['url-image'] || defualImage}}" alt="Image of vacants">
          </div>
          <h6 class="cursor" style="text-align: left;" (click)="redirect(vac)"><strong>{{vac["nombre-de-la-vacante"]}}</strong></h6>
          <p class="card-text">{{vac['area-2']}}</p>
          <p class="card-text"><small class="text-muted">{{vac['esquema-de-trabajo']}} - {{vac['pais']}}</small></p>
      </div>
    </div>
  </div>
  <div *ngIf="totalVacantes > 9" class="d-flex justify-content-center">
    <div class="pagination">
      <button class="btn btn-primary" (click)="changePage(pageAct - 1)" [disabled]="pageAct === 1">{{ traducciones.paginador1 }}</button>
      <span class="page-link ms-1 me-1">{{ traducciones.pagina }} {{pageAct}} {{ traducciones.paginaof }} {{totalPages}}</span>
      <button class="btn btn-primary" (click)="changePage(pageAct + 1)" [disabled]="pageAct === totalPages">{{ traducciones.paginador2 }}</button>
    </div>
  </div>
 
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
<div>
  <app-footer [idioma]="idiomaActual"></app-footer>
</div>


<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Selecciona idioma</p>
      <div style="display: flex; justify-content: space-between;">
        <div style="padding: 15px; background-color: aliceblue;"><a class="dropdown-item cursor"(click)="cambiarIdioma('espanol')"> <img src="assets/espana.png" alt="bandera" style="height:20px; margin-right: 8px;">Español</a></div>
        <div style="padding: 15px; background-color: aliceblue;"><a class="dropdown-item cursor"(click)="cambiarIdioma('ingles')"> <img src="assets/estados-unidos.png" alt="bandera" style="height:20px; margin-right: 8px;">Inglés</a></div>
        <div style="padding: 15px; background-color: aliceblue;"><a class="dropdown-item cursor"(click)="cambiarIdioma('portugues')"> <img src="assets/bandera.png" alt="bandera" style="height:20px; margin-right: 8px;">Portugués</a></div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Cerrar</button>
  </div>
</ng-template>


