import { Component, Input, OnInit } from '@angular/core';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @Input() selectedReport: number = 0;
  @Input() pais: string = '';
  @Input() data: any;
  @Input() type: any;
  total: number = 29;
  selectedHeaderId: number = 2;

  vacantes_oferta: any = [];
  vacantes_oferta_aux: any = [];
  vacantes_cerradas_mes: any = [];
  vacantes_cerradas_mes_aux: any = [];
  vacantes_sin_atender: any = [];
  vacantes_sin_atender_aux: any = [];
  finalistas_dry_run: any = [];
  finalistas_dry_run_aux: any = [];
  descartados_finalistas_dry_run: any = [];
  descartados_finalistas_dry_run_aux: any = [];
  vacantes_internas_cerradas: any = [];
  vacantes_internas_cerradas_aux: any = [];
  contratados: any = [];
  contratados_aux: any = [];
  turnover: any = [];
  turnover_aux: any = [];

  startDate: any;
  endDate: any;
  minDate: any;


  ejemplo: any []= [
    { nombre: 'Programador Jr',nivel:[ '2B','Confidencial'],days:5,fecha: '12/09/2023', responsable: ['Sebastian Sanchez','juan@example.com'] },
    { nombre: 'Product Owner',nivel:[ '1B','Publico'],days:5, fecha: '12/09/2023',responsable: ['Luis Perez','juan@example.com']  },
    { nombre: 'Scrum Master',nivel:[ '2B','Confidencial'],days:5,fecha: '12/09/2023', responsable: ['Juan Example','juan@example.com']  },
  ];
  headers: any [] =[
    {id:1, title1:'Nombre de la vacante', title2:'Nivel', title3:'Fecha de apertura', title4:'Días transcurridos', title5:'Responsable', column: ["A","B","C","D","E"] },//ok
    {id:2, title1:'Nombre de la vacante', title2:'Nivel', title3:'Días transcurridos', title4:'Estatus de cierre', title5:'Responsable', column: ["A","B","C","D","E"] },//ok
    {id:3, title1:'Nombre de la vacante', title2:'Nivel', title3:'Días transcurridos', title4:'Estatus', title5:'Responsable', column: ["A","B","C","D","E"] },//ok
    {id:4, title1:'Finalista', title2:'Estatus', title3:'Resultados de la prueba', title4:'Vacante', title5:'Responsable', column: ["A","B","C","D","E"] },//ok
    {id:5, title1:'Finalista', title2:'Estatus', title3:'Motivo de descarte', title4:'Vacante', title5:'Responsable', column: ["A","B","C","D","E"] },//ok
    {id:6, title1:'Vacante', title2:'Responsable', title3:'Fecha de ingreso', title4:'Candidato', title5:'Enlace', column: ["A","B","C"] },
    {id:9, title1:'Candidato', title2:'Vacante', title3:'Nivel', title4:'Responsable', title5:'Enlace', column: ["A","B","C","D"] },
    {id:10, title1:'Candidato', title2:'Vacante', title3:'Nivel', title4:'Responsable', title5:'Enlace', column: ["A","B","C","D"] },
  ]
  constructor() {
    this.minDate = new Date().toISOString().split("T")[0];
  }

  ngOnInit(): void {

    if(this.selectedReport == 1){
      this.vacantes_oferta = this.data["vacants"]["offert"];
      this.vacantes_oferta_aux = this.data["vacants"]["offert"];
    }
    else if(this.selectedReport == 2){
      this.vacantes_cerradas_mes = this.data["vacants"]["current_month_close"];
      this.vacantes_cerradas_mes_aux = this.data["vacants"]["current_month_close"];
    }

    else if(this.selectedReport == 3){
      this.vacantes_sin_atender = this.data["vacants"]["sin_atender"];
      this.vacantes_sin_atender_aux = this.data["vacants"]["sin_atender"];
    }

    else if(this.selectedReport == 4){
      this.finalistas_dry_run = this.data["candidates"]["finalist_dry_run"];
      this.finalistas_dry_run_aux = this.data["candidates"]["finalist_dry_run"];
    }

    else if(this.selectedReport == 5){
      this.descartados_finalistas_dry_run = this.data["candidates"]["discard_finalist_dry_run"];
      this.descartados_finalistas_dry_run_aux = this.data["candidates"]["discard_finalist_dry_run"];
    }

    else if(this.selectedReport == 6){
      this.vacantes_internas_cerradas = this.data["vacants"]["internal_close"];
      this.vacantes_internas_cerradas_aux = this.data["vacants"]["internal_close"];
    }

    else if(this.selectedReport == 9){
      this.contratados = this.data["candidates"]["selected"];
      this.contratados_aux = this.data["candidates"]["selected"];
    }

    else if(this.selectedReport == 10){
      this.turnover = this.data["candidates"]["turnover"];
      this.turnover_aux = this.data["candidates"]["turnover"];
    }
  }

  getTotal(){
    if(this.selectedReport == 1){
      return this.vacantes_oferta.length;
    }
    else if(this.selectedReport == 2){
      return this.vacantes_cerradas_mes.length;
    }

    else if(this.selectedReport == 3){
      return this.vacantes_sin_atender.length
    }

    else if(this.selectedReport == 4){
      return this.finalistas_dry_run.length
    }

    else if(this.selectedReport == 5){
      return this.descartados_finalistas_dry_run.length
    }

    else if(this.selectedReport == 6){
      return this.vacantes_internas_cerradas.length;
    }

    else if(this.selectedReport == 9){
      return this.contratados.length;
    }

    else if(this.selectedReport == 10){
      return this.turnover.length;
    } else {
      return 0
    }
  }

  filterByCountry(event: any){
    const data = event.target.value;

    if(this.selectedReport == 1){
      if(data == "Todos"){
        this.vacantes_oferta = this.vacantes_oferta_aux.map( (x: any) => { return x; });
        return;
      }
      this.vacantes_oferta = this.vacantes_oferta_aux.filter( (x: any) => x["pais"] == data );
    }
    else if(this.selectedReport == 2){
      if(data == "Todos"){
        this.vacantes_cerradas_mes = this.vacantes_cerradas_mes_aux.map( (x: any) => { return x; });
        return;
      }
      this.vacantes_cerradas_mes = this.vacantes_cerradas_mes_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 3){
      if(data == "Todos"){
        this.vacantes_sin_atender = this.vacantes_sin_atender_aux.map( (x: any) => { return x; });
        return;
      }
      this.vacantes_sin_atender = this.vacantes_sin_atender_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 4){
      if(data == "Todos"){
        this.finalistas_dry_run = this.finalistas_dry_run_aux.map( (x: any) => { return x; });
        return;
      }
      this.finalistas_dry_run = this.finalistas_dry_run_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 5){
      if(data == "Todos"){
        this.descartados_finalistas_dry_run = this.descartados_finalistas_dry_run_aux.map( (x: any) => { return x; });
        return;
      }
      this.descartados_finalistas_dry_run = this.descartados_finalistas_dry_run_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 6){
      if(data == "Todos"){
        this.vacantes_internas_cerradas = this.vacantes_internas_cerradas_aux.map( (x: any) => { return x; });
        return;
      }
      this.vacantes_internas_cerradas = this.vacantes_internas_cerradas_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 9){
      if(data == "Todos"){
        this.contratados = this.contratados_aux.map( (x: any) => { return x; });
        return;
      }
      this.contratados = this.contratados_aux.filter( (x: any) => x["pais"] == data );
    }

    else if(this.selectedReport == 10){
      if(data == "Todos"){
        this.turnover = this.turnover_aux.map( (x: any) => { return x; });
        return;
      }
      this.turnover = this.turnover_aux.filter( (x: any) => x["pais"] == data );
    }
  }

  filterByDate(type_date: string, event: any){
    const date = event.target.value;
    if(type_date == "start"){
      this.startDate = date;
      this.minDate= date;
    } else {
      this.endDate = date;
    }

    
    if(!date && type_date == "start"){
      this.endDate = null;
      this.contratados = this.contratados_aux.map( (x: any) => { return x; });
      return;
    }

    const endDate = !this.endDate ? new Date().toISOString().split("T")[0] : this.endDate;
    this.contratados = this.contratados_aux.filter( (x: any) =>  new Date(x["fecha"].split(" ")[0]) >= new Date(this.startDate) &&  new Date(x["fecha"].split(" ")[0]) <= new Date(endDate) );
    
  }

  // time to fill
  exportToExcelReport() {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Reportes')

    const aRows: any[] = ['0','1','2','3','4'];

    for (let index = 0; index < aRows.length; index++) {
        let aRowValue = aRows[index];
        worksheet.getRow(aRowValue).height = 48
      }

      worksheet.getColumn('A').width = 30;
      worksheet.getColumn('B').width = 30;
      worksheet.getColumn('C').width = 30;
      worksheet.getColumn('D').width = 30;
      worksheet.getColumn('E').width = 30;

      const header = this.headers.find( (x: any) => x.id == this.selectedReport );
      
      header.column.forEach((element: any, index: number) => {
        worksheet.getCell(`${element}1`).value = header[`title${index+1}`];
      });

      worksheet.mergeCells('I1:J1');
      worksheet.getCell('I1').value = 'Total'
      worksheet.mergeCells('I2:J2');
      worksheet.getCell('I2').value = `${this.getTotal()} elementos`

      worksheet.getCell('A1').alignment = {
        vertical: 'middle',
       horizontal: 'left',
      }
      worksheet.getCell('A1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '45b8ea',
        },
        bgColor: {
          argb: '45b8ea',
        },
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const headData1Q = ['B1', 'C1','D1','E1'];
      headData1Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '92D050',
          },
          bgColor: {
            argb: '92D050',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });


        worksheet.getCell('I1').alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell('I1').font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell('I1').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '00B050',
          },
          bgColor: {
            argb: '00B050',
          },
        }
        worksheet.getCell('I1').border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }


        if(this.selectedReport == 1){
          this.vacantes_oferta.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["nombre"];
            worksheet.getCell(`B${index+2}`).value = element["nivel"];
            worksheet.getCell(`C${index+2}`).value = element["fecha-apertura"];
            worksheet.getCell(`D${index+2}`).value = `${element["dias-transcurridos"].split(".")[0]} days`;
            worksheet.getCell(`E${index+2}`).value = `${element["responsable"]["name"]}/${element["responsable"]["mail"][0]}`;
          });
        }

        if(this.selectedReport == 2){
          this.vacantes_cerradas_mes.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["nombre"];
            worksheet.getCell(`B${index+2}`).value = element["nivel"];
            worksheet.getCell(`C${index+2}`).value = `${element["dias-transcurridos"].split(".")[0]} days`;
            worksheet.getCell(`D${index+2}`).value = element["estatus"];
            worksheet.getCell(`E${index+2}`).value = `${element["responsable"]["name"]}/${element["responsable"]["mail"][0]}`;
          });
        }

        if(this.selectedReport == 3){
          this.vacantes_sin_atender.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["nombre"];
            worksheet.getCell(`B${index+2}`).value = element["nivel"];
            worksheet.getCell(`C${index+2}`).value = `${element["dias-transcurridos"].split(".")[0]} days`;
            worksheet.getCell(`D${index+2}`).value = element["estatus"];
            worksheet.getCell(`E${index+2}`).value = `${element["responsable"]["name"]}/${element["responsable"]["mail"][0]}`;
          });
        }

        if(this.selectedReport == 4){
          this.finalistas_dry_run.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["finalista"];
            worksheet.getCell(`B${index+2}`).value = element["estatus"];
            worksheet.getCell(`C${index+2}`).value = element["resultado"];
            worksheet.getCell(`D${index+2}`).value = element["vacante"].split(".")[0];
            worksheet.getCell(`E${index+2}`).value = element["responsable"];
          });
        }

        if(this.selectedReport == 5){
          this.descartados_finalistas_dry_run.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["finalista"];
            worksheet.getCell(`B${index+2}`).value = element["estatus"];
            worksheet.getCell(`C${index+2}`).value = element["motivo"];
            worksheet.getCell(`D${index+2}`).value = element["vacante"];
            worksheet.getCell(`E${index+2}`).value = element["responsable"];
          });
        }

        if(this.selectedReport == 6){
          this.vacantes_internas_cerradas.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["nombre"];
            worksheet.getCell(`B${index+2}`).value = `${element["responsable"]["name"]}/${element["responsable"]["mail"][0]}`;
            worksheet.getCell(`C${index+2}`).value = element["fecha-ingreso"].split(" ")[0];
          });
        }

        if(this.selectedReport == 9){
          this.contratados.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["finalista"];
            worksheet.getCell(`B${index+2}`).value = element["vacante"];
            worksheet.getCell(`C${index+2}`).value = element["nivel"];
            worksheet.getCell(`D${index+2}`).value = element["responsable"];
          });
        }

        if(this.selectedReport == 10){
          this.turnover.forEach((element: any, index: number) => {
            worksheet.getCell(`A${index+2}`).value = element["candidato"];
            worksheet.getCell(`B${index+2}`).value = element["vacante"];
            worksheet.getCell(`C${index+2}`).value = element["nivel"];
            worksheet.getCell(`D${index+2}`).value = element["responsable"];
          });
        }
        


      workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          document.body.appendChild(a)
          a.setAttribute('style', 'display: none')
          a.href = url
          a.download = "KPI´s & Reportes.xlsx"
          a.click()
          window.URL.revokeObjectURL(url)
          a.remove()
        })
        .catch((e) => {
          console.log(e)
        })
  }

}
