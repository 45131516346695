<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
    
  </div>
  
  <div class="content" role="main">
    <div class="main-container">
      <div class="form-container mt-3">
        <h2 class="text-center mb-3">TERMS AND CONDITIONS</h2>
        <hr class="mb-3">
        <p>The network of websites (collectively, the "Websites", "Sites" or "Site") operated by the companies that are part of Genomma Lab (hereinafter "Genomma Lab" or "we") are composed of various websites and web pages. The terms, conditions, and notices contained herein (collectively, the "Terms") apply to all Genomma Lab websites.</p>
        <p>Access and use of the Site is subject to the terms and conditions described in this document and all applicable laws and regulations, including those that regulate copyright, trademarks, patents or any other intellectual property rights. in any of the countries where Genomma Lab operates BY ACCESSING THE SITE, YOU CONFIRM AND AGREE TO ACCEPT, WITHOUT LIMITATION OR QUALIFICATION, THE ENTIRETY OF THESE TERMS AND CONDITIONS. Genomma Lab reserves the right to change these terms and conditions from time to time by updating this posting. If you do not agree to these Terms, do not use this Site or any other Genomma Lab Sites.</p>
        <p>Use of the Genomma Lab Websites is also subject to the Genomma Lab Privacy Notice, which is made available to you at https://www.genommalab.com/politicas.</p>
        <p>Unless otherwise specified, the Site is for your personal, non-commercial use only. You may not modify, copy, distribute, publicly communicate, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any of the content or communications contained or displayed on the Site ("Content"), including, but not limited to, text, graphics, photographs, images, moving images, sound, illustrations, information, software, products or services and their respective arrangements, except as expressly permitted herein. The Content is the property of Genomma Lab and is protected by copyright, trademarks, trade secrets and other rights derived from current intellectual property laws. You acknowledge that any unauthorized use of any Content could cause us irreparable harm and agree that in the event of such unauthorized use, we will have the right to pursue appropriate legal action in addition to any other remedies available at law. and/or applicable regulations. Any other use of the Content, including modification, reproduction, public communication, distribution, publication, display or transmission of any Content, without our prior written permission, is strictly prohibited.</p>
        <p>The information published on this Site regarding conditions that affect health should not be considered a final diagnosis or professional opinion regarding any discomfort or condition. The content of the Site is merely informative, so Genomma Lab recommends that any condition or question arising from the information presented be addressed directly by a health specialist. In the event that any person uses the published information as a final diagnosis or as a professional opinion, Genomma Lab disclaims any present or future responsibility derived from the misuse made of the information contained on the Site.</p>
        <p>THE SITE AND THE INFORMATION CONTAINED THEREIN, ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR OWNERSHIP, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, EXCEPT FOR WARRANTIES WHICH ARE IMPOSED AND INABLE TO BE EXCLUDED, RESTRICTED OR MODIFIED UNDER THE LAWS AND/OR REGULATIONS APPLICABLE TO THESE TERMS.</p>
        <p>TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND/OR REGULATIONS, NEITHER GENOMMA LAB NOR ANY OF ITS CONTROLLING ENTITIES, SUBSIDIARIES, DIVISIONS, AFFILIATES, AGENTS, REPRESENTATIVES OR LICENSORS SHALL HAVE ANY LIABILITY TO YOU OR ANY OTHER PERSON. FOR LOSSES OR INJURIES OR FOR ANY DAMAGES, DAMAGES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR SIMILAR DAMAGES ARISING FROM, OR ARISING FROM, YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THIS SITE.</p>
        <p>The information communicated on the Sites may contain third-party information and/or links to third-party external pages. Genomma Lab is not responsible or liable in any way for any Third Party Content that is posted, uploaded, created, shared, or otherwise exists on any Genomma Lab web presence to which these Terms apply. . Genomma Lab has no liability or responsibility to you or any other party for the existence or deletion of any third party content. Third party content does not reflect the opinions, beliefs, statements or actions of Genomma Lab. Genomma Lab specifically disclaims and excludes any liability that may arise from the aforementioned. Additionally and without limiting the above, the information presented on said third-party pages is the responsibility of their owners. Genomma Lab disclaims any responsibility for the information published on domain names and/or third-party websites.</p>
             <p>You acknowledge: (i) that it is technically impossible to provide the Site free of any defects and that Genomma Lab cannot undertake to do so; (ii) that defects may make the Site temporarily unavailable; and that (iii) the operation of the Site may be affected by events and/or matters that Genomma Lab does not control, such as, for example, the means of transmission and communication between you and Genomma Lab and between Genomma Lab and other networks, therefore that Genomma Lab is not responsible for any interruption in services, delay in the operation or transmission of information, for possible computer viruses, loss of data or any other failure related to the operation of the Site.</p>
             <p>Genomma Lab makes our privacy notice available to you on the site https://www.genommalab.com/politicas, so it is recommended that you read it in its entirety before using this or any of the Sites and/or any of our services via the Internet or through computer applications, since this Privacy Notice regulates our treatment of any personal information, including the personal data that you provide to us. All users of this Site should consider that certain information, statements, data and content that they voluntarily provide to Genomma Lab could, or very likely could be used to identify the owners of such data or reveal their gender, ethnicity, nationality, age or other personal data. </p>
             <p>These Terms and any dispute or controversy that may arise will be governed and interpreted in accordance with the laws and courts of the applicable locality, waiving from this moment on any other jurisdiction that may correspond to the parties by reason of their domicile or any other reason</p>
      </div>
    </div>
  
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
      <path id="Path_39" data-name="Path 39"
        d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
        transform="translate(142.69 -634.312)" fill="#eee" />
    </svg>
  
  </div>
  <div>
    <app-footer [idioma]="idiomaActual"></app-footer>
  </div>
  