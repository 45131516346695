<label class="color-primary mb-1" for="select">Selecciona un año</label>
<select class="custom-select" id="FormControlSelectCluster" title="Selecciona año" [(ngModel)]="current_year" (ngModelChange)="filterDate()">
    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
</select>
<!-- <div style="display: block;">
    <canvas
      baseChart
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [legend]="barChartLegend"
      [type]="'bar'">
    </canvas>
</div>
  <div class="row">
    <div class="col">
        <table class="second-table">
            <thead>
                <tr>
                    <th id="timeToFillHeader">Success Rate Histórico(1er Semestre)</th>
                    <th id="vacio" class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="">TOTAL ABIERTAS</td>
                    <td class="center">{{vacant["vacants_semester"]["first"]["abiertas"]}}</td>
                </tr>
                <tr>
                    <td class="">TOTAL CANCELADAS</td>
                    <td class="center">{{vacant["vacants_semester"]["first"]["canceladas"]}}</td>
                </tr>
                <tr>
                    <td class="center ">TOTAL CERRADAS</td>
                    <td class="center">{{vacant["vacants_semester"]["first"]["cerradas"]}}</td>
                </tr>
                <tr>
                    <td class="center border-success-rate">Success Rate Histórico(1er Semestre)</td>
                    <td class="center border-success-rate">{{
                        calculatePercentage(
                            vacant["vacants_semester"]["first"]["cerradas"],
                            vacant["vacants_semester"]["first"]["abiertas"] - vacant["vacants_semester"]["first"]["canceladas"]

                        )

                    }}</td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="col">
        <table class="second-table">
            <thead>
                <tr>
                    <th id="timeToFillHeader">Success Rate Histórico(2do Semestre)</th>
                    <th id="vacio" class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="">TOTAL ABIERTAS</td>
                    <td class="center">{{vacant["vacants_semester"]["second"]["abiertas"]}}</td>
                </tr>
                <tr>
                    <td class="">TOTAL CANCELADAS</td>
                    <td class="center">{{vacant["vacants_semester"]["second"]["canceladas"]}}</td>
                </tr>
                <tr>
                    <td class="center ">TOTAL CERRADAS</td>
                    <td class="center">{{vacant["vacants_semester"]["second"]["cerradas"]}}</td>
                </tr>
                <tr>
                    <td class="center border-success-rate">Success Rate Histórico(2do Semestre)</td>
                    <td class="center border-success-rate">{{
                        calculatePercentage(
                        vacant["vacants_semester"]["second"]["cerradas"],
                        vacant["vacants_semester"]["second"]["abiertas"] - vacant["vacants_semester"]["second"]["canceladas"]
                        )
                    }}</td>
                </tr>
            </tbody>
        </table>
      </div>
  </div> -->
<div style="width: 100%;" class="d-flex justify-content-center">
    <div style="min-width: 80%; max-width: 80%; padding: 15px;">
        <canvas
        class="canva-g"
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [type]="'bar'">
        </canvas>
    </div>
    <div style="min-width: 20%; max-width: 20%;  display: flex; justify-content: center; align-items: center;">
        <div  class="row">
            <div  class="col-lg-12 d-flex justify-content-end align-items-end">
                <table class="second-table">
                    <thead>
                        <tr>
                            <th id="timeToFillHeader">Success Rate Histórico(1er Semestre)</th>
                            <th id="vacio" class=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">TOTAL ABIERTAS</td>
                            <td class="center">{{vacant["vacants_semester"]["first"]["abiertas"]}}</td>
                        </tr>
                        <tr>
                            <td class="">TOTAL CANCELADAS</td>
                            <td class="center">{{vacant["vacants_semester"]["first"]["canceladas"]}}</td>
                        </tr>
                        <tr>
                            <td class="center ">TOTAL CERRADAS</td>
                            <td class="center">{{vacant["vacants_semester"]["first"]["cerradas"]}}</td>
                        </tr>
                        <tr>
                            <td class="center border-success-rate">Success Rate Histórico(1er Semestre)</td>
                            <td class="center border-success-rate">
                            {{
                                calculatePercentage(
                                    vacant["vacants_semester"]["first"]["cerradas"], (
                                        vacant["vacants_semester"]["first"]["abiertas"]
                                        ) - (
                                        vacant["vacants_semester"]["first"]["canceladas"]
                                        )
                                )
                            }}
                        
                        </td>
                        </tr>
                    </tbody>
                </table>
              </div>
              <div class="col-lg-12 d-flex justify-content-end align-items-end mt-2">
                <table class="second-table">
                    <thead>
                        <tr>
                            <th id="timeToFillHeader">Success Rate Histórico(2do Semestre)</th>
                            <th id="vacio" class=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">TOTAL ABIERTAS</td>
                            <td class="center">{{vacant["vacants_semester"]["second"]["abiertas"]}}</td>
                        </tr>
                        <tr>
                            <td class="">TOTAL CANCELADAS</td>
                            <td class="center">{{vacant["vacants_semester"]["second"]["canceladas"]}}</td>
                        </tr>
                        <tr>
                            <td class="center ">TOTAL CERRADAS</td>
                            <td class="center">{{vacant["vacants_semester"]["second"]["cerradas"]}}</td>
                        </tr>
                        <tr>
                            <td class="center border-success-rate">Success Rate Histórico(2do Semestre)</td>
                            <td class="center border-success-rate">{{
                               calculatePercentage(
                                    vacant["vacants_semester"]["second"]["cerradas"], (
                                        vacant["vacants_semester"]["second"]["abiertas"]
                                        ) - (
                                        vacant["vacants_semester"]["second"]["canceladas"]
                                        )
                                )
                            }}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
        </div>
    </div>
</div>