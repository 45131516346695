import { Component, ElementRef, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  activeButton: boolean = false;
  
  viewHistorico: boolean = false;
  NameTableToFill: string = '(Global)';
  NameTableSuccess: string = '(Global)';
  selectedCountry: string = '';
  selectedResponsable: string = '';
  multiSelectedCountry_one: string = '';
  multiSelectedCountry_two: string = '';
  selectedCountryArray: any[] = [];
  selectedClustersArray: any[] = [];
  selectedResponsableArray: any[] = [];
  selectedCluster: string = '';
  report_selected: number = 0;
  numberOption: number = 2;
  numberOptionTwo: number = 2;
  time_to_fill: any;
  success_rate: any;
  histories: any;
  load: boolean = false;
  reports: any;
  @ViewChild('historicoId') historicoScroll: ElementRef | undefined;

  paisOptions = [
    { id: "México", name: 'México' },
    { id: "EE.UU", name: 'EE.UU' },
    { id: "Colombia", name: 'Colombia' },
    { id: "Perú", name: 'Perú' },
    { id: "Costa Rica", name: 'Costa Rica' },
    { id: "Panamá", name: 'Panamá' },
    { id: "Honduras", name: 'Honduras' },
    { id: "El Salvador", name: 'El Salvador' },
    { id: "Nicaragua", name: 'Nicaragua' },
    { id: "Guatemala", name: 'Guatemala' },
    { id: "República Dominicana", name: 'República Dominicana' },
    { id: "Argentina", name: 'Argentina' },
    { id: "Chile", name: 'Chile' },
    { id: "Uruguay", name: 'Uruguay' },
    { id: "Paraguay", name: 'Paraguay' },
    { id: "Brazil", name: 'Brazil' }
  ];

  clusterOptions = [
    { id: 'México & USA', name: 'México & USA' },
    { id: 'Andino y Caricam', name: 'Andino y Caricam' },
    { id: 'Cono Sur', name: 'Cono Sur' },
    { id: 'Brazil', name: 'Brazil' },
  ]
  responsableOptions = [];

  statusLoader = 'Cargando'
  
  constructor(private router: Router, private service: ApiServices, private spinner: NgxSpinnerService) {
    this.spinner.show()
    this.getReports();
  }

  getReports() {

    if (this.reports) {
      this.spinner.hide();
      return;
    }

    this.service.getReports().subscribe(
      (response: any) => {
        this.reports = response["reports"];
        this.time_to_fill = response["kpis"]["time_to_fill"];
        this.success_rate = response["kpis"]["success_rate"];
        this.histories = response["histories"];
        this.load = true;
        console.log("##################");
        console.log(this.success_rate);
        console.log("##################");
        console.log("##################");
        console.log(this.time_to_fill);
        console.log("##################");
        this.responsableOptions = this.time_to_fill["responsable"].map((element: any) => {
          return { id: element.responsable, name: element.responsable }
        });

        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.statusLoader = "Error al procesar la petición"
        if (error.status == 422 || error.status == 403 || error.status == 401) {
          this.statusLoader = "Token Invalido o Expirado"
        }
        this.spinner.show(undefined, {
          type: 'ball-scale-multiple',
          color: 'red',
        });
          this.spinner.hide();
          
          if (error.status == 422 || error.status == 403 || error.status == 401) {
            localStorage.clear();
            this.router.navigate(['/login']);
          }
      }
    );
  }
  onSelectResponsable(event: any) {
    this.selectedResponsable = event.target.value;
  }
  
  onSelectCountry(event: any) {
    this.selectedCountry = event.target.value;

    console.log(this.selectedCountryArray)
  }

  onSelectCluster(event: any) {
    this.selectedCluster = '';
    setTimeout(()=>{
    this.selectedCluster = event.target.value;
    },400);
  }

  onSelectReport(event: string) {
    this.report_selected = parseInt(event)
    this.limpiarSelectCountry()
    this.limpiarSelectCluster()
  }

  limpiarSelectCluster() {
    this.selectedCluster = '';
    this.selectedResponsable = '';
  }

  limpiarSelectCountry() {
    this.selectedCountry = '';
  }

  isDropdownOpen: boolean = false;
  isDropdownOpen1: boolean = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleDropdown1() {
    this.isDropdownOpen1 = !this.isDropdownOpen1;
  }

  cerrarSesion() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
 
  onHistorico() {
    this.viewHistorico = !this.viewHistorico
    this.activeButton = !this.activeButton
    
    setTimeout(() => {
      this.historicoScroll!.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }  
  
// time to fill
  exportToExcelTimeToFill() {
    const workbook = new Workbook()
  
    const general =[
      { worksheet: "General", values: this.time_to_fill["general"][0]["report"] }
    ]

    general.forEach( (element: any) =>{
      var worksheet = workbook.addWorksheet(`Reportes&KPIS ${element.worksheet}`);
      worksheet = this.createExcelTimeToFill(worksheet, element.values);
    });

    const countries: any= [];
    this.time_to_fill["country"].forEach((element: any) => {
      countries.push({ worksheet: element.pais, values: element.report });
    });

    countries.forEach( (element: any) =>{
      var worksheet = workbook.addWorksheet(`Reportes&KPIS ${element.worksheet}`);
      worksheet = this.createExcelTimeToFill(worksheet, element.values);
    });
    
    
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display: none')
      a.href = url
      a.download = "KPI´s & Reportes.xlsx"
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  createExcelTimeToFill(worksheet: any, values: any) {
    const aRows: any[] = ['0','1','2','3','4','5','6',];
    const current_year = new Date().getFullYear();

    for (let index = 0; index < aRows.length; index++) {
        let aRowValue = aRows[index];
        worksheet.getRow(aRowValue).height = 48
      }

      worksheet.getColumn('A').width = 25
      worksheet.getColumn('B').width = 10
      worksheet.getColumn('C').width = 10
      worksheet.getColumn('D').width = 10
      worksheet.getColumn('E').width = 8
      worksheet.getColumn('F').width = 10
      worksheet.getColumn('G').width = 10
      worksheet.getColumn('H').width = 10
      worksheet.getColumn('I').width = 8
      worksheet.getColumn('J').width = 20
      worksheet.getColumn('K').width = 10
      worksheet.getColumn('L').width = 10
      worksheet.getColumn('M').width = 15
      worksheet.getColumn('N').width = 8
      worksheet.getColumn('O').width = 10
      worksheet.getColumn('P').width = 15
      worksheet.getColumn('Q').width = 15
      worksheet.getColumn('R').width = 8
      worksheet.getColumn('S').width = 20
      worksheet.getColumn('T').width = 10

      worksheet.getCell('A1').value = 'Time to Fill (GLOBAL)'
      worksheet.getCell('B1').value = 'Enero'
      worksheet.getCell('C1').value = 'Febrero'
      worksheet.getCell('D1').value = 'Marzo'
      worksheet.getCell('E1').value = '1°Q'
      worksheet.getCell('F1').value = 'Abril'
      worksheet.getCell('G1').value = 'Mayo'
      worksheet.getCell('H1').value = 'Junio'
      worksheet.getCell('I1').value = '2°Q'
      worksheet.getCell('J1').value = '1er Semestre ' + current_year
      worksheet.getCell('K1').value = 'Julio'
      worksheet.getCell('L1').value = 'Agosto'
      worksheet.getCell('M1').value = 'Septiembre'
      worksheet.getCell('N1').value = '3°Q'
      worksheet.getCell('O1').value = 'Octubre'
      worksheet.getCell('P1').value = 'Noviembre'
      worksheet.getCell('Q1').value = 'Diciembre'
      worksheet.getCell('R1').value = '4°Q'
      worksheet.getCell('S1').value = '2do Semestre ' + current_year
      worksheet.getCell('T1').value = 'Actual'
      worksheet.getCell('A2').value = 'Time to Fill N0 (Live)'
      worksheet.getCell('A3').value = 'Time to Fill N1 (Live)'
      worksheet.getCell('A4').value = 'Time to Fill N2B (Live)'
      worksheet.getCell('A5').value = 'Time to Fill N2A y N3 (Live)'

      worksheet.getCell('A1').alignment = {
        vertical: 'middle',
       horizontal: 'left',
      }
      worksheet.getCell('A1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '45b8ea',
        },
        bgColor: {
          argb: '45b8ea',
        },
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const headData1Q = ['B1', 'C1','D1','E1'];
      headData1Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '92D050',
          },
          bgColor: {
            argb: '92D050',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const headData2Q = ['F1', 'G1','H1','I1'];
      headData2Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '00B050',
          },
          bgColor: {
            argb: '00B050',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      worksheet.getCell('J1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('J1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('J1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFC000',
        },
        bgColor: {
          argb: 'FFC000',
        },
      }
      worksheet.getCell('J1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const headData3Q = ['K1', 'L1','M1','N1'];
      headData3Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '00B0F0',
          },
          bgColor: {
            argb: '00B0F0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const headData4Q = ['O1', 'P1','Q1','R1'];
      headData4Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '0070C0',
          },
          bgColor: {
            argb: '0070C0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      worksheet.getCell('S1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('S1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('S1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFC000',
        },
        bgColor: {
          argb: 'FFC000',
        },
      }
      worksheet.getCell('S1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      worksheet.getCell('T1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('T1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('T1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF0000',
        },
        bgColor: {
          argb: 'FF0000',
        },
      }
      worksheet.getCell('T1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const labels = ['A2', 'A3','A4','A5'];
      labels.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'left',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 10,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f0f0f0',
          },
          bgColor: {
            argb: 'f0f0f0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      var number_row = 2;
      values.forEach((element: any) => {
        worksheet.getCell(`B${number_row}`).value = element["total-vacants-date"]["1"];
        worksheet.getCell(`C${number_row}`).value = element["total-vacants-date"]["2"];
        worksheet.getCell(`D${number_row}`).value = element["total-vacants-date"]["3"];
        worksheet.getCell(`E${number_row}`).value = element["total-vacants-date"]["1q"];
        worksheet.getCell(`F${number_row}`).value = element["total-vacants-date"]["4"];
        worksheet.getCell(`G${number_row}`).value = element["total-vacants-date"]["5"];
        worksheet.getCell(`H${number_row}`).value = element["total-vacants-date"]["6"];
        worksheet.getCell(`I${number_row}`).value = element["total-vacants-date"]["2q"];
        worksheet.getCell(`J${number_row}`).value = element["total-vacants-date"]["1s"];
        worksheet.getCell(`K${number_row}`).value = element["total-vacants-date"]["7"];
        worksheet.getCell(`L${number_row}`).value = element["total-vacants-date"]["8"];
        worksheet.getCell(`M${number_row}`).value = element["total-vacants-date"]["9"];
        worksheet.getCell(`N${number_row}`).value = element["total-vacants-date"]["3q"];
        worksheet.getCell(`O${number_row}`).value = element["total-vacants-date"]["10"];
        worksheet.getCell(`P${number_row}`).value = element["total-vacants-date"]["11"];
        worksheet.getCell(`Q${number_row}`).value = element["total-vacants-date"]["12"];
        worksheet.getCell(`R${number_row}`).value = element["total-vacants-date"]["4q"];
        worksheet.getCell(`S${number_row}`).value = element["total-vacants-date"]["2s"];
        worksheet.getCell(`T${number_row}`).value = element["total-vacants"];        
        number_row = number_row +1;
      });
      

      return worksheet;
  }

// success rate
  exportToExcelSuccessRate() {

    const workbook = new Workbook()
  
    var data= this.success_rate["general"][0]["report"];
    var worksheet = workbook.addWorksheet(`Reportes&KPIS General`);
    this.createExcelSuccessRate(worksheet, data);

    const countries: any= [];
    this.success_rate["cluster"].forEach((element: any) => {
      var worksheet = workbook.addWorksheet(`Reportes&KPIS ${element.cluster}`);
      this.createExcelSuccessRate(worksheet, element.report);
    });


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display: none')
      a.href = url
      a.download = "KPI´s & Reportes.xlsx"
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  createExcelSuccessRate(worksheet: any, values: any){

    const aRows: any[] = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19'];
    const current_year= new Date().getFullYear();

    for (let index = 0; index < aRows.length; index++) {
        let aRowValue = aRows[index];
        worksheet.getRow(aRowValue).height = 30
      }

      worksheet.getColumn('A').width = 25
      worksheet.getColumn('B').width = 10
      worksheet.getColumn('C').width = 10
      worksheet.getColumn('D').width = 10
      worksheet.getColumn('E').width = 8
      worksheet.getColumn('F').width = 10
      worksheet.getColumn('G').width = 10
      worksheet.getColumn('H').width = 10
      worksheet.getColumn('I').width = 8
      worksheet.getColumn('J').width = 20
      worksheet.getColumn('K').width = 10
      worksheet.getColumn('L').width = 10
      worksheet.getColumn('M').width = 15
      worksheet.getColumn('N').width = 8
      worksheet.getColumn('O').width = 10
      worksheet.getColumn('P').width = 15
      worksheet.getColumn('Q').width = 15
      worksheet.getColumn('R').width = 8
      worksheet.getColumn('S').width = 20
      worksheet.getColumn('T').width = 10

      worksheet.getCell('A1').value = 'Success Rate (GLOBAL)'
      worksheet.getCell('B1').value = 'Enero'
      worksheet.getCell('C1').value = 'Febrero'
      worksheet.getCell('D1').value = 'Marzo'
      worksheet.getCell('E1').value = '1°Q'
      worksheet.getCell('F1').value = 'Abril'
      worksheet.getCell('G1').value = 'Mayo'
      worksheet.getCell('H1').value = 'Junio'
      worksheet.getCell('I1').value = '2°Q'
      worksheet.getCell('J1').value = '1er Semestre ' + current_year
      worksheet.getCell('K1').value = 'Julio'
      worksheet.getCell('L1').value = 'Agosto'
      worksheet.getCell('M1').value = 'Septiembre'
      worksheet.getCell('N1').value = '3°Q'
      worksheet.getCell('O1').value = 'Octubre'
      worksheet.getCell('P1').value = 'Noviembre'
      worksheet.getCell('Q1').value = 'Diciembre'
      worksheet.getCell('R1').value = '4°Q'
      worksheet.getCell('S1').value = '2do Semestre ' + current_year
      worksheet.getCell('T1').value = 'Actual'
      worksheet.getCell('A2').value = 'N0 (Abiertas)'
      worksheet.getCell('A3').value = 'N0 (Canceladas)'
      worksheet.getCell('A4').value = 'N0 (Cerradas)'
      worksheet.getCell('A5').value = 'Success Rate N0'
      worksheet.getCell('A6').value = 'N1 (Abiertas)'
      worksheet.getCell('A7').value = 'N1 (Canceladas)'
      worksheet.getCell('A8').value = 'N1 (Cerradas)'
      worksheet.getCell('A9').value = 'Success Rate N1'
      worksheet.getCell('A10').value = 'N2 y N3 (Abiertas)'
      worksheet.getCell('A11').value = 'N2 y N3 (Canceldas)'
      worksheet.getCell('A12').value = 'N2 y N3 (Cerradas)'
      worksheet.getCell('A13').value = 'Success Rate N2 y N3'

      worksheet.getCell('A15').value = 'Success Rate ' + current_year + ' (1er Semestre)'
      worksheet.mergeCells('A16:B16');
      worksheet.getCell('A16').value = 'TOTAL ABIERTAS'
      worksheet.mergeCells('A17:B17');
      worksheet.getCell('A17').value = 'TOTAL CANCELADAS'
      worksheet.mergeCells('A18:B18');
      worksheet.getCell('A18').value = 'TOTAL CERRADAS'
      worksheet.mergeCells('A19:B19');
      worksheet.getCell('A19').value = 'Success Rate ' + current_year + ' (1er Semestre)'


      worksheet.getCell('H15').value = 'Success Rate ' + current_year + ' (2do Semestre)'
      worksheet.mergeCells('H16:J16');
      worksheet.getCell('H16').value = 'TOTAL ABIERTAS'
      worksheet.mergeCells('H17:J17')
      worksheet.getCell('H17').value = 'TOTAL CANCELADAS'
      worksheet.mergeCells('H18:J18')
      worksheet.getCell('H18').value = 'TOTAL CERRADAS'
      worksheet.mergeCells('H19:J19')
      worksheet.getCell('H19').value = 'Success Rate ' + current_year + ' (2do Semestre)'


      worksheet.getCell('A1').alignment = {
        vertical: 'middle',
       horizontal: 'left',
      }
      worksheet.getCell('A1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '45b8ea',
        },
        bgColor: {
          argb: '45b8ea',
        },
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const headData1Q = ['B1', 'C1','D1','E1'];
      headData1Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '92D050',
          },
          bgColor: {
            argb: '92D050',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const headData2Q = ['F1', 'G1','H1','I1'];
      headData2Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '00B050',
          },
          bgColor: {
            argb: '00B050',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      worksheet.getCell('J1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('J1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('J1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFC000',
        },
        bgColor: {
          argb: 'FFC000',
        },
      }
      worksheet.getCell('J1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const headData3Q = ['K1', 'L1','M1','N1'];
      headData3Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '00B0F0',
          },
          bgColor: {
            argb: '00B0F0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const headData4Q = ['O1', 'P1','Q1','R1'];
      headData4Q.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '0070C0',
          },
          bgColor: {
            argb: '0070C0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      worksheet.getCell('S1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('S1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('S1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFC000',
        },
        bgColor: {
          argb: 'FFC000',
        },
      }
      worksheet.getCell('S1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      worksheet.getCell('T1').alignment = {
        vertical: 'middle',
       horizontal: 'center',
      }
      worksheet.getCell('T1').font = {
        name: 'Quattrocento Sans',
        color: { argb: '000000' },
        size: 12,
        bold: true,
      }
      worksheet.getCell('T1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF0000',
        },
        bgColor: {
          argb: 'FF0000',
        },
      }
      worksheet.getCell('T1').border = {
        top: { style: 'thin', color: { argb: 'D9E1F2' } },
        left: { style: 'thin', color: { argb: 'D9E1F2' } },
        bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
        right: { style: 'thin', color: { argb: 'D9E1F2' } },
      }

      const labels = ['A2', 'A3','A4','A5','A6','A7','A8','A9','A10','A11','A12','A13'];
      labels.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'left',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 10,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f0f0f0',
          },
          bgColor: {
            argb: 'f0f0f0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      worksheet.mergeCells('A15:C15');
      worksheet.mergeCells('H15:K15');

      const headerSemestres = ['A15', 'H15'];
      headerSemestres.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'center',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 12,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '45b8ea',
          },
          bgColor: {
            argb: '45b8ea',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const labelSemestres = ['A16', 'A17','A18','H16','H17','H18'];
      labelSemestres.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'left',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 10,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f0f0f0',
          },
          bgColor: {
            argb: 'f0f0f0',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      const resultadoSemestres = ['A19','H19'];
      resultadoSemestres.forEach((ele) => {
        worksheet.getCell(ele).alignment = {
          vertical: 'middle',
         horizontal: 'left',
        }
        worksheet.getCell(ele).font = {
          name: 'Quattrocento Sans',
          color: { argb: '000000' },
          size: 11,
          bold: true,
        }
        worksheet.getCell(ele).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'a9e1f5',
          },
          bgColor: {
            argb: 'a9e1f5',
          },
        }
        worksheet.getCell(ele).border = {
          top: { style: 'thin', color: { argb: 'D9E1F2' } },
          left: { style: 'thin', color: { argb: 'D9E1F2' } },
          bottom: { style: 'thin', color: { argb: 'D9E1F2' } },
          right: { style: 'thin', color: { argb: 'D9E1F2' } },
        }
      });

      var number_row = 2;
      const status = [
        "abiertas",
        "canceladas",
        "cerradas"
      ];
      const levels = [
        {level: "n0", status: status},
        {level: "n1", status: status},
        {level: "n2_n3", status: status}
      ]
      
      levels.forEach((level: any) => {
        const level_rate = { "B": 0, "C": 0, "D": 0, "E": 0, "F": 0, "G": 0, "H": 0, "I": 0, "J": 0, "K": 0, "L": 0, "M": 0, "N": 0, "O": 0, "P": 0, "Q": 0, "R": 0, "S": 0, "T": 0 };
        level.status.forEach((status: any) => {
          level_rate["B"] = level_rate["B"] + values[level.level][status].meses["1"];
          worksheet.getCell(`B${number_row}`).value = values[level.level][status].meses["1"];
          level_rate["C"] = level_rate["C"] + values[level.level][status].meses["2"];
          worksheet.getCell(`C${number_row}`).value = values[level.level][status].meses["2"];
          level_rate["D"] = level_rate["D"] + values[level.level][status].meses["3"];
          worksheet.getCell(`D${number_row}`).value = values[level.level][status].meses["3"];
          level_rate["E"] = level_rate["E"] + values[level.level][status].meses["1q"];
          worksheet.getCell(`E${number_row}`).value = values[level.level][status].meses["1q"]
          level_rate["F"] = level_rate["F"] + values[level.level][status].meses["4"];
          worksheet.getCell(`F${number_row}`).value = values[level.level][status].meses["4"];
          level_rate["G"] = level_rate["G"] + values[level.level][status].meses["5"];
          worksheet.getCell(`G${number_row}`).value = values[level.level][status].meses["5"];
          level_rate["H"] = level_rate["H"] + values[level.level][status].meses["6"];
          worksheet.getCell(`H${number_row}`).value = values[level.level][status].meses["6"];
          level_rate["I"] = level_rate["I"] + values[level.level][status].meses["2q"];
          worksheet.getCell(`I${number_row}`).value = values[level.level][status].meses["2q"];
          level_rate["J"] = level_rate["J"] + values[level.level][status].meses["1s"];
          worksheet.getCell(`J${number_row}`).value = values[level.level][status].meses["1s"]
          level_rate["K"] = level_rate["K"] + values[level.level][status].meses["7"];
          worksheet.getCell(`K${number_row}`).value = values[level.level][status].meses["7"];
          level_rate["L"] = level_rate["L"] + values[level.level][status].meses["8"];
          worksheet.getCell(`L${number_row}`).value = values[level.level][status].meses["8"];
          level_rate["M"] = level_rate["M"] + values[level.level][status].meses["9"];
          worksheet.getCell(`M${number_row}`).value = values[level.level][status].meses["9"];
          level_rate["N"] = level_rate["N"] + values[level.level][status].meses["3q"];
          worksheet.getCell(`N${number_row}`).value = values[level.level][status].meses["3q"];
          level_rate["O"] = level_rate["O"] + values[level.level][status].meses["10"];
          worksheet.getCell(`O${number_row}`).value = values[level.level][status].meses["10"];
          level_rate["P"] = level_rate["P"] + values[level.level][status].meses["11"];
          worksheet.getCell(`P${number_row}`).value = values[level.level][status].meses["11"];
          level_rate["Q"] = level_rate["Q"] + values[level.level][status].meses["12"];
          worksheet.getCell(`Q${number_row}`).value = values[level.level][status].meses["12"];
          level_rate["R"] = level_rate["R"] + values[level.level][status].meses["4q"];
          worksheet.getCell(`R${number_row}`).value = values[level.level][status].meses["4q"];
          level_rate["S"] = level_rate["S"] + values[level.level][status].meses["2s"];
          worksheet.getCell(`S${number_row}`).value = values[level.level][status].meses["2s"];
          level_rate["T"] = level_rate["T"] + values[level.level][status].meses["1s"] + values[level.level][status].meses["2s"]
          worksheet.getCell(`T${number_row}`).value = values[level.level][status].meses["1s"] + values[level.level][status].meses["2s"];
          number_row = number_row +1;
        });

        

        worksheet.getCell(`B${number_row}`).value = level_rate["B"];
        worksheet.getCell(`C${number_row}`).value = level_rate["C"];
        worksheet.getCell(`D${number_row}`).value = level_rate["D"];
        worksheet.getCell(`E${number_row}`).value = level_rate["E"];
        worksheet.getCell(`F${number_row}`).value = level_rate["F"];
        worksheet.getCell(`G${number_row}`).value = level_rate["G"];
        worksheet.getCell(`H${number_row}`).value = level_rate["H"];
        worksheet.getCell(`I${number_row}`).value = level_rate["I"];
        worksheet.getCell(`J${number_row}`).value = level_rate["J"];
        worksheet.getCell(`K${number_row}`).value = level_rate["K"];
        worksheet.getCell(`L${number_row}`).value = level_rate["L"];
        worksheet.getCell(`M${number_row}`).value = level_rate["M"];
        worksheet.getCell(`N${number_row}`).value = level_rate["N"];
        worksheet.getCell(`O${number_row}`).value = level_rate["O"];
        worksheet.getCell(`P${number_row}`).value = level_rate["P"];
        worksheet.getCell(`Q${number_row}`).value = level_rate["Q"];
        worksheet.getCell(`R${number_row}`).value = level_rate["R"];
        worksheet.getCell(`S${number_row}`).value = level_rate["S"];
        worksheet.getCell(`T${number_row}`).value = level_rate["T"];
        number_row = number_row +1;

        let sumJ:any[] = [], sumS:any[] = [], sumT:any[]=[];
        for (let i = 2; i <= 4; i++) {
            sumJ.push(worksheet.getCell(`J${i}`).value);
            sumS.push(worksheet.getCell(`S${i}`).value);
            sumT.push(worksheet.getCell(`T${i}`).value);
        }

        let valueJ9:any[] = [], valueS9:any[] = [], valueT9:any[]=[];
        for (let i = 6; i <= 8; i++) {
            valueJ9.push(worksheet.getCell(`J${i}`).value);
            valueS9.push(worksheet.getCell(`S${i}`).value);
            valueT9.push(worksheet.getCell(`T${i}`).value);
        }

        let valueJ13:any[] = [], valueS13:any[] = [], valueT13:any[]=[];
        for (let i = 10; i <= 12; i++) {
          valueJ13.push(worksheet.getCell(`J${i}`).value);
          valueS13.push(worksheet.getCell(`S${i}`).value);
          valueT13.push(worksheet.getCell(`T${i}`).value);
        }

        // Asigna las sumas a las celdas J5, S5 y T5
        worksheet.getCell('J5').value = this.calculatePercentage(sumJ[2], sumJ[0] - sumJ[1]);
        worksheet.getCell('J5').alignment = { horizontal: 'right' };
        worksheet.getCell('S5').value = this.calculatePercentage(sumS[2], sumS[0] - sumS[1]);
        worksheet.getCell('S5').alignment = { horizontal: 'right' };
        worksheet.getCell('T5').value = this.calculatePercentage(sumT[2], sumT[0] - sumT[1]);
        worksheet.getCell('T5').alignment = { horizontal: 'right' };

        worksheet.getCell('J9').value = this.calculatePercentage(valueJ9[2], valueJ9[0] - valueJ9[1]);
        worksheet.getCell('J9').alignment = { horizontal: 'right' };
        worksheet.getCell('S9').value = this.calculatePercentage(valueS9[2], valueS9[0] - valueS9[1]);
        worksheet.getCell('S9').alignment = { horizontal: 'right' };
        worksheet.getCell('T9').value = this.calculatePercentage(valueT9[2], valueT9[0] - valueT9[1]);
        worksheet.getCell('T9').alignment = { horizontal: 'right' };

        worksheet.getCell('J13').value = this.calculatePercentage(valueJ13[2], valueJ13[0] - valueJ13[1]);
        worksheet.getCell('J13').alignment = { horizontal: 'right' };

        worksheet.getCell('S13').value = this.calculatePercentage(valueS13[2], valueS13[0] - valueS13[1]);
        worksheet.getCell('S13').alignment = { horizontal: 'right' };

        worksheet.getCell('T13').value = this.calculatePercentage(valueT13[2], valueT13[0] - valueT13[1]);
        worksheet.getCell('T13').alignment = { horizontal: 'right' };

        
        // Limpia las demás celdas en la fila 5
        const columns = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'];
        for (const column of columns) {
            worksheet.getCell(`${column}5`).value = null;
            worksheet.getCell(`${column}9`).value = null;
            worksheet.getCell(`${column}13`).value = null;
        }

        const semester = {
          firts: {
            abiertas: 0,
            cerradas: 0,
            canceladas: 0,
          },
          second: {
            abiertas: 0,
            cerradas: 0,
            canceladas: 0,
          }
        }
        levels.forEach((level: any) => {
          
          level.status.forEach((status: any) => {
            if(status == "abiertas"){
              semester.firts.abiertas = semester.firts.abiertas + values[level.level][status].meses["1s"];
              semester.second.abiertas = semester.second.abiertas + values[level.level][status].meses["2s"];
            } else if(status == "canceladas"){
              semester.firts.canceladas = semester.firts.canceladas + values[level.level][status].meses["1s"];
              semester.second.canceladas = semester.second.canceladas + values[level.level][status].meses["2s"];
            } else {
              semester.firts.cerradas = semester.firts.cerradas + values[level.level][status].meses["1s"];
              semester.second.cerradas = semester.second.cerradas + values[level.level][status].meses["2s"];
            }
          });
  
          worksheet.getCell(`C16`).value = semester.firts.abiertas;
          worksheet.getCell(`C17`).value = semester.firts.canceladas;
          worksheet.getCell(`C18`).value = semester.firts.cerradas;
          worksheet.getCell(`C19`).value = this.calculatePercentage(semester.firts.cerradas, semester.firts.abiertas - semester.firts.canceladas);
          worksheet.getCell('C19').alignment = { horizontal: 'right' };

          worksheet.getCell(`K16`).value = semester.second.abiertas;
          worksheet.getCell(`K17`).value = semester.second.canceladas;
          worksheet.getCell(`K18`).value = semester.second.cerradas;
          worksheet.getCell(`K19`).value = this.calculatePercentage(semester.second.cerradas, semester.second.abiertas - semester.second.canceladas);
          worksheet.getCell('K19').alignment = { horizontal: 'right' };

        })


        return worksheet;


      });


  }


  
  
  calculatePercentage(numerator: number, denominator: number): string {
    if (numerator === 0 || denominator === 0) {
      return '0%';  // Retorna '0%' si el numerador o el denominador son 0
    } else {
      let percentage = this.round((numerator / denominator) * 100);
      if (percentage > 100) {
        return `100%`;  
      }
      return `${percentage}%`;
    }
  }
 
  round(value: number): number {
    return Math.round(value);
  }

  
  ngOnInit(): void {
  }

}
