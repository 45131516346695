import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
})
export class TablaComponent implements OnInit {

  @Input() NameTableToFill: String = '';
  @Input() dataTimeToFill: any;
  timeToFill: any = []
  current_year: number = 0;
  
  constructor() {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.timeToFill = this.dataTimeToFill["general"][0]["report"];
  }

}
