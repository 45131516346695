<div>
  <!-- <div style="overflow-x: scroll;">
    <table style="background-color: white; ">
        <thead>
            <tr>
                <th id="timeToFillHeader">Success Rate {{NameTableSuccess}}</th>
                <th id="enero" class="padding-headers">Enero</th>
                  <th id="febrero" class="padding-headers">Febrero</th>
                  <th id="marzo" class="padding-headers">Marzo</th>
                  <th id="q1" class="padding-headers">1°Q</th>
                  <th id="abril" class="padding-headers">Abril</th>
                  <th id="mayo" class="padding-headers">Mayo</th>
                  <th id="junio" class="padding-headers">Junio</th>
                  <th id="q1" class="padding-headers">2°Q</th>
                  <th id="first-semestre" class="padding-headers">1er Semestre {{current_year}}</th>
                  <th id="julio" class="padding-headers">Julio</th>
                  <th id="agosto" class="padding-headers">Agosto</th>
                  <th id="septiembre" class="padding-headers">Septiembre</th>
                  <th id="q1" class="padding-headers">3°Q</th>
                  <th id="octubre" class="padding-headers">Octubre</th>
                  <th id="noviembre" class="padding-headers">Noviembre</th>
                  <th id="diciembre" class="padding-headers">Diciembre</th>
                  <th id="q1" class="padding-headers">4°Q</th>
                  <th id="second-semestre" class="padding-headers">2do Semestre {{current_year}}</th>
                  <th id="total" class="padding-headers">TOTAL</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="n1">N0 (Abiertas)</td>
                <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["abiertas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N0 (Canceladas)</td>
                <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["canceladas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N0 (Cerradas)</td>
                <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n0"]["cerradas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1"><b>Success Rate N0</b></td>
                <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["1q"], successRate["n0"]["abiertas"]["meses"]["1q"] - successRate["n0"]["canceladas"]["meses"]["1q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["2q"], successRate["n0"]["abiertas"]["meses"]["2q"] - successRate["n0"]["canceladas"]["meses"]["2q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["1s"], successRate["n0"]["abiertas"]["meses"]["1s"] - successRate["n0"]["canceladas"]["meses"]["1s"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["3q"], successRate["n0"]["abiertas"]["meses"]["3q"] - successRate["n0"]["canceladas"]["meses"]["3q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["4q"], successRate["n0"]["abiertas"]["meses"]["4q"] - successRate["n0"]["canceladas"]["meses"]["4q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["meses"]["2s"], successRate["n0"]["abiertas"]["meses"]["2s"] - successRate["n0"]["canceladas"]["meses"]["2s"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n0"]["cerradas"]["total"], successRate["n0"]["abiertas"]["total"] - successRate["n0"]["canceladas"]["total"]) }}</b></td>
            </tr>

            <tr>
                <td class="n1">N1 (Abiertas)</td>
                <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["abiertas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N1 (Canceladas)</td>
                <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["canceladas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N1 (Cerradas)</td>
                <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n1"]["cerradas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1"><b>Success Rate N1</b></td>
                <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["1q"], successRate["n1"]["abiertas"]["meses"]["1q"] - successRate["n1"]["canceladas"]["meses"]["1q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["2q"], successRate["n1"]["abiertas"]["meses"]["2q"] - successRate["n1"]["canceladas"]["meses"]["2q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["1s"], successRate["n1"]["abiertas"]["meses"]["1s"] - successRate["n1"]["canceladas"]["meses"]["1s"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["3q"], successRate["n1"]["abiertas"]["meses"]["3q"] - successRate["n1"]["canceladas"]["meses"]["3q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["4q"], successRate["n1"]["abiertas"]["meses"]["4q"] - successRate["n1"]["canceladas"]["meses"]["4q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["meses"]["2s"], successRate["n1"]["abiertas"]["meses"]["2s"] - successRate["n1"]["canceladas"]["meses"]["2s"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n1"]["cerradas"]["total"], successRate["n1"]["abiertas"]["total"] - successRate["n1"]["canceladas"]["total"]) }}</b></td>
            </tr>

            <tr>
                <td class="n1">N2 y N3 (Abiertas)</td>
                <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["abiertas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N2 y N3 (Canceladas)</td>
                <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["canceladas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1">N2 y N3 (Cerradas)</td>
                <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["1"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["2"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["3"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["1q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["4"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["5"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["6"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["2q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["1s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["7"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["8"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["9"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["3q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["10"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["11"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["12"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["4q"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["meses"]["2s"]}}</td>
                  <td class="text-center">{{successRate["n2_n3"]["cerradas"]["total"]}}</td>
            </tr>
            <tr>
                <td class="n1"><b>Success Rate N2 y N3</b></td>
                <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["1q"], successRate["n2_n3"]["abiertas"]["meses"]["1q"] - successRate["n2_n3"]["canceladas"]["meses"]["1q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["2q"], successRate["n2_n3"]["abiertas"]["meses"]["2q"] - successRate["n2_n3"]["canceladas"]["meses"]["2q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["1s"], successRate["n2_n3"]["abiertas"]["meses"]["1s"] - successRate["n2_n3"]["canceladas"]["meses"]["1s"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["3q"], successRate["n2_n3"]["abiertas"]["meses"]["3q"] - successRate["n2_n3"]["canceladas"]["meses"]["3q"]) }}</b></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["4q"], successRate["n2_n3"]["abiertas"]["meses"]["4q"] - successRate["n2_n3"]["canceladas"]["meses"]["4q"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["meses"]["2s"], successRate["n2_n3"]["abiertas"]["meses"]["2s"] - successRate["n2_n3"]["canceladas"]["meses"]["2s"]) }}</b></td>
                  <td class="text-center"><b>{{ calculatePercentage(successRate["n2_n3"]["cerradas"]["total"], successRate["n2_n3"]["abiertas"]["total"] - successRate["n2_n3"]["canceladas"]["total"]) }}</b></td>
            </tr>
        </tbody>
    </table>
  </div> -->

<div class="row" style="justify-content: center;">
    <div class="col" style="padding: 0 15px;">
        <table class="second-table" style="width: 100%; text-align: center; margin: 0 auto;">
            <thead>
                <tr>
                    <th id="timeToFillHeader" colspan="2" style="text-align: center;">Success Rate (Global) {{current_year}} (1er Semestre)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="padding: 10px;">TOTAL ABIERTAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["abiertas"]["meses"]["1s"] + successRate["n1"]["abiertas"]["meses"]["1s"] + successRate["n2_n3"]["abiertas"]["meses"]["1s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">TOTAL CANCELADAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["canceladas"]["meses"]["1s"] + successRate["n1"]["canceladas"]["meses"]["1s"] + successRate["n2_n3"]["canceladas"]["meses"]["1s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">TOTAL CERRADAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["cerradas"]["meses"]["1s"] + successRate["n1"]["cerradas"]["meses"]["1s"] + successRate["n2_n3"]["cerradas"]["meses"]["1s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px; border: 2px solid green; font-weight: bold;">Success Rate {{current_year}} (1er Semestre)</td>
                    <td style="padding: 10px; border: 2px solid green; background-color: #DFF0D8; font-weight: bold;">
                        {{
                            calculatePercentage(
                                successRate["n0"]["cerradas"]["meses"]["1s"] + 
                                successRate["n1"]["cerradas"]["meses"]["1s"] + 
                                successRate["n2_n3"]["cerradas"]["meses"]["1s"], (
                                    successRate["n0"]["abiertas"]["meses"]["1s"] +
                                    successRate["n1"]["abiertas"]["meses"]["1s"] +
                                    successRate["n2_n3"]["abiertas"]["meses"]["1s"]
                                    ) - (
                                        successRate["n0"]["canceladas"]["meses"]["1s"] +
                                        successRate["n1"]["canceladas"]["meses"]["1s"] +
                                        successRate["n2_n3"]["canceladas"]["meses"]["1s"]
                                    )
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col" style="padding: 0 15px;">
        <table class="second-table" style="width: 100%; text-align: center; margin: 0 auto;">
            <thead>
                <tr>
                    <th id="timeToFillHeader" colspan="2" style="text-align: center;">Success Rate (Global) {{current_year}} (2do Semestre)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="padding: 10px;">TOTAL ABIERTAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["abiertas"]["meses"]["2s"] + successRate["n1"]["abiertas"]["meses"]["2s"] + successRate["n2_n3"]["abiertas"]["meses"]["2s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">TOTAL CANCELADAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["canceladas"]["meses"]["2s"] + successRate["n1"]["canceladas"]["meses"]["2s"] + successRate["n2_n3"]["canceladas"]["meses"]["2s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px;">TOTAL CERRADAS</td>
                    <td style="padding: 10px;">{{successRate["n0"]["cerradas"]["meses"]["2s"] + successRate["n1"]["cerradas"]["meses"]["2s"] + successRate["n2_n3"]["cerradas"]["meses"]["2s"]}}</td>
                </tr>
                <tr>
                    <td style="padding: 10px; border: 2px solid green; font-weight: bold;">Success Rate {{current_year}} (2do Semestre)</td>
                    <td style="padding: 10px; border: 2px solid green; background-color: #DFF0D8; font-weight: bold;">
                        {{
                            calculatePercentage(
                                successRate["n0"]["cerradas"]["meses"]["2s"] + 
                                successRate["n1"]["cerradas"]["meses"]["2s"] + 
                                successRate["n2_n3"]["cerradas"]["meses"]["2s"], (
                                    successRate["n0"]["abiertas"]["meses"]["2s"] +
                                    successRate["n1"]["abiertas"]["meses"]["2s"] +
                                    successRate["n2_n3"]["abiertas"]["meses"]["2s"]
                                    ) - (
                                        successRate["n0"]["canceladas"]["meses"]["2s"] +
                                        successRate["n1"]["canceladas"]["meses"]["2s"] +
                                        successRate["n2_n3"]["canceladas"]["meses"]["2s"]
                                    )
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>