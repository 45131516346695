import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ApiServices } from '../api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  statusLoader = 'Cargando'


  constructor(private formBuilder: FormBuilder, private service: ApiServices, private router: Router, private spinner: NgxSpinnerService) {
    const access_token = localStorage.getItem("AKSIpln");
    if(access_token){
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
  get fa() {
    return this.loginForm.controls;
  }

  onLoginFormSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    const data = {
      name: this.fa["email"].value,
      password: this.fa["password"].value
    }

    this.spinner.show()

    this.service.login(data).subscribe( 
      (response: any) =>{
        if(response.msg){
          this.statusLoader = response.msg;
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
        } else if(response.access_token){
          localStorage.setItem("data_user", JSON.stringify(response));
          localStorage.setItem("AKSIpln", response.access_token);
          if(response.perfil == "Administrador"){
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/role-profile']);
          }
        }

        setTimeout(() => {
          this.spinner.hide();
        }, 1200);
    },
    error=>{
      this.statusLoader = "Error al procesar la petición"
      this.spinner.show(undefined, { 
        type: 'ball-scale-multiple', 
        color: 'red', 
      });
      console.log(error);
      setTimeout(() => {
        this.spinner.hide();
      }, 1200);
    }
    )


  }

}
