import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.css']
})
export class TerminosCondicionesComponent implements OnInit {

  idiomaActual: string = 'espanol';

  constructor() { }

  ngOnInit(): void {
  }

}
