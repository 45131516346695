<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
    <div class="dropdown" title="idioma">
        <button class="btn btn-rounded dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/traducir.png" loading="lazy" alt="icono traducir" style="height:20px">
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('espanol')"> <img src="assets/espana.png" alt="bandera" style="height:20px; margin-right: 8px;">Español</a></li>
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('ingles')"> <img src="assets/estados-unidos.png" alt="bandera" style="height:20px; margin-right: 8px;">Inglés</a></li>
          <li><a class="dropdown-item cursor"(click)="cambiarIdioma('portugues')"> <img src="assets/bandera.png" alt="bandera" style="height:20px; margin-right: 8px;">Portugués</a></li>
        </ul>
      </div>
</div>
<div class="main-details">
    <div class="container" style="display: flex; justify-content: center;  flex-direction: column;">
        <div class="header-label">
            <div class="align-text">
                <h3>{{ traducciones.title }}</h3>
            </div>
           <div class="position-button">
                <button (click)="goBack()"  type="button" title="Volver" class="button-back">
                    <img src="assets/atras.png" alt="atras" style="height: 36px;">
                </button>
           </div>
        </div>
        <div class="col-lg-10 box-shadow-custom" style="background-color: white; border: 1px solid #45b8ea; border-radius: 10px; padding: 1rem; margin-top: 50px; width: 100%;">
            <div class="row" style="display: flex; justify-content: space-between;">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2>{{vacante['nombre-de-la-vacante']}}</h2>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6">
                    <p>{{vacante['area-2']}}</p>
                    <p>{{vacante['pais']}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <img class="img-details" src="{{vacante['url-image'] || defualImage}}" alt="image of vacants">
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <button (click)="openModal()" class="btn btn-modal mb-2">{{ traducciones.textButton }}</button>
                    <app-modal-form *ngIf="isModalOpen" (closeModalEvent)="closeModal()" [idiomaActual]="idiomaActual"></app-modal-form>
                </div>
            </div>
            
            <hr>
                <div class="row box-shadow-custom mb-2">
                    <div class="col-lg-6">
                        <h5 class="main-color">{{ traducciones.text1 }}</h5>
                        <div style="display: flex;">
                            <i class="fas fa-money-check-dollar"></i>
                            <p>${{vacante['rango-salarial-minimo'].split(".")[0] |  currency: vacante["divisa"] :''}} a ${{vacante['rango-salarial-maximo'].split(".")[0] | currency: vacante["divisa"]: ''}} {{vacante["divisa"]}}
                                </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h5 class="main-color">{{ traducciones.text2 }}</h5>
                        <div style="display: flex;">
                            <i class="fas fa-bolt"></i>
                            <p>{{vacante["anos-de-experiencia-requeridos-2"]}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h5 class="main-color">{{ traducciones.text3 }}</h5>
                        <div style="display: flex;">
                            <i class="fas fa-briefcase"></i>
                            <p>{{vacante['esquema-de-trabajo']}}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h5 class="main-color">{{ traducciones.text4 }}</h5>
                        <div style="display: flex;">
                            <i class="fas fa-map-location-dot"></i>
                            <p>{{vacante['ubicacion']}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-job-details">
            
                  <h3 class="text-center">{{ traducciones.subtitle }}</h3>
                  <hr>
  
                    <div class="row ">
                        <div class="col">
                            <h4 class="color-h3"><strong>{{ traducciones.text5 }}</strong></h4> 
                        </div>
                    </div>
            
                    <div class="row mb-4">
                        <div class="col">
                            <div [innerHTML]="vacante['descripcion-de-la-vacante']"></div>
                        </div>
                    </div>
            
                </div>
        </div>

    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
        <path id="Path_39" data-name="Path 39"
          d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
          transform="translate(142.69 -634.312)" fill="#eee" />
      </svg>
    </div>
    <div style="margin-top: 2rem;">
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
<div>
    <app-footer  [idioma]="idiomaActual"></app-footer>
   
  </div>