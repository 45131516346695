import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiServices } from '../api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-oportunidades-laborales',
  templateUrl: './oportunidades-laborales.component.html',
  styleUrls: ['./oportunidades-laborales.component.css']
})
export class OportunidadesLaboralesComponent implements AfterViewInit {
  @ViewChild('content') content: any;
  private modalRef!: NgbModalRef;


  translate: boolean = false;
  vacants: any[] = [];
  vacants_aux: any[] = [];
  vacantsGlobal: any[] = [];
  vacantsCountry: any[] = [];
  vacantsArea: any[] = [];
  vacantsType: any[] = [];
  itemsForPage = 9;
  pageAct = 1;
  totalPages = 0;
  totalVacantes = 0;
  disableButton = false;
  isSearchActiveGlobal = false;
  isSearchActiveCountry = false;
  isSearchArea = false;
  isSearchType = false;
  isModalOpen = false;

  msgSuccess = '';
  statusLoader = 'Cargando'
  defualImage = 'assets/img-vacantes/Imagen9.png'

  selectedCountryArray: any[] = [];
  selectedTipoArray: any[] = [];
  selectedCountry: string = '';
  selectedByArea: any[] = [];
  paisOptions = [
    { id: "México", name: 'México' },
    { id: "EE.UU", name: 'EE.UU' },
    { id: "Colombia", name: 'Colombia' },
    { id: "Perú", name: 'Perú' },
    { id: "Costa Rica", name: 'Costa Rica' },
    { id: "Panamá", name: 'Panamá' },
    { id: "Honduras", name: 'Honduras' },
    { id: "El Salvador", name: 'El Salvador' },
    { id: "Nicaragua", name: 'Nicaragua' },
    { id: "Guatemala", name: 'Guatemala' },
    { id: "República Dominicana", name: 'República Dominicana' },
    { id: "Argentina", name: 'Argentina' },
    { id: "Chile", name: 'Chile' },
    { id: "Uruguay", name: 'Uruguay' },
    { id: "Paraguay", name: 'Paraguay' },
    { id: "Brazil", name: 'Brazil' }
  ];

  tipoOptions = [
    { id: "Remoto", name: 'Remoto' },
    { id: "Presencial", name: 'Presencial' },
    { id: "Mixto", name: 'Mixto' }
  ];
  areaOptions = [
    { id: "Asuntos Regulatorios", name: 'Asuntos Regulatorios' },
    { id: "Brand Operations", name: 'Brand Operations' },
    { id: "Comercial", name: 'Comercial' },
    { id: "Comunicación y Publicidad", name: 'Comunicación y Publicidad' },
    { id: "Dirección Médica", name: 'Dirección Médica' },
    { id: "Finanzas", name: 'Finanzas' },
    { id: "Compras", name: 'Compras' },
    { id: "Legal", name: 'Legal' },
    { id: "Recuros Humanos", name: 'Recuros Humanos' },
    { id: "Sistemas (IT)", name: 'Sistemas (IT)' },
    { id: "Supply", name: 'Supply' },
    { id: "Trade Marketing", name: 'Trade Marketing' },
    { id: "Servicios Adicionales", name: 'Servicios Adicionales' },    
  ];


  idiomaActual: string = 'espanol';
  traducciones: any = {};
  idiomas: any = {
    'espanol': {
      'title': 'Encuentra tu oportunidad de carrera',
      'placeholderSearch': 'Encuentra el trabajo de tus sueños',
      'textButton': 'Buscar empleo',
      'filtroPais': 'País',
      'placeholderPais': 'Selecciona país',
      'filtroArea': 'Área',
      'placeholderArea': 'Selecciona área',
      'filtroTipo': 'Tipo',
      'placeholderTipo': 'Selecciona tipo',
      'pagina': 'Página',
      'paginaof': 'de',
      'paginador1': 'Anterior',
      'paginador2': 'Siguiente',

    },
    'ingles': {
      'title': 'Find your career opportunity',
      'placeholderSearch': 'Find your dream job',
      'textButton': 'Find a job',
      "filtroPais": "Country",
      'placeholderPais': 'Select country',
      "filtroArea": "Area",
      'placeholderArea': 'Select area',
      "filtroTipo": "Type",
      'placeholderTipo': 'Select type',
      "pagina": "Page",
      "paginaof": "of",
      "paginador1": "Previous",
      "paginador2": "Next"
    },
    'portugues': {
      'title': 'Encontre sua oportunidade de carreira',
      'placeholderSearch': 'Encontre o trabalho dos seus sonhos',
      'textButton': 'Buscar emprego',
      'filtroPais': 'País',
      'placeholderPais': 'Selecione país',
      'filtroArea': 'Área',
      'placeholderArea': 'Selecione área',
      'filtroTipo': 'Tipo',
      'placeholderTipo': 'Selecione tipo',
      "pagina": "Página",
     "paginaof": "de",
      "paginador1": "Anterior",
      "paginador2": "Próximo"
    }
  };



  constructor(private service: ApiServices, private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService) {
   this.spinner.show()
    this.service.getAllVacantesGenerales().subscribe(
      (response:any) => {
        this.vacants = response;
        this.vacants_aux = response;
        this.totalPages = Math.ceil(this.vacants.length / this.itemsForPage);
        this.totalVacantes = this.vacants.length;
        if (this.vacants.length!=0){
          this.statusLoader = 'Carga Exitosa';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: '#45b8ea',
          });

        } else {
          console.log("An ocurred error", response.Error);
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 1200);
        
      },
      (error) => {
        console.error('Error', error);
        this.statusLoader = 'Ha ocurrido un error intenta más tarde';
        this.spinner.show(undefined, { 
          type: 'ball-scale-multiple',  
          color: 'red', 
        });
        setTimeout(() => {
          this.spinner.hide();
        }, 2200);
       
      }
    );
    this.statusLoader = 'Cargando vacantes';

  }

  ngAfterViewInit(): void {
    this.cambiarIdioma(this.idiomaActual);
    console.log(`Idioma inicial seleccionado: ${this.idiomaActual}`);

    this.openModal()
  }

  filterByFields(vacants:any, text:string) {
    const fields = [
      "anos-de-experiencia-requeridos-2",
      "area-2",
      "esquema-de-trabajo",
      "nombre-de-la-vacante",
      "pais",
      "tipo-de-vacante",
      "ubicacion"
    ];
    return vacants.filter((x:any) =>
      fields.some((field) => x[field].toUpperCase().includes(text.toUpperCase()))
    );
  }
  
  search(event: any) {
    const text = event.target.value;
    if (!text) {
      this.vacants = this.vacants_aux;
      this.isSearchActiveGlobal = false;
      return;
    }
  
    this.vacantsGlobal = this.filterByFields(this.vacants_aux, text);
    this.isSearchActiveGlobal = true;
    this.combineFilters();
  }
  
  searchByCountry() {
    if (this.selectedCountryArray.length > 0) {
      this.vacantsCountry = this.vacants_aux.filter((x) =>
        this.selectedCountryArray.includes(x['pais'])
      );
      this.isSearchActiveCountry = true;
    } else {
      this.vacantsCountry = this.vacants_aux;
      this.isSearchActiveCountry = false;
    }
    this.combineFilters();
  }
  
  searchByArea(){
    if (this.selectedByArea.length > 0) {
      this.vacantsArea = this.vacants_aux.filter((x) =>
        this.selectedByArea.includes(x['area-2'])
      );
      this.isSearchArea = true;
    } else {
      this.vacantsArea = this.vacants_aux;
      this.isSearchArea = false;
    }
    this.combineFilters();
  }
  
  searchByType(){
    if (this.selectedTipoArray.length > 0) {
      this.vacantsType = this.vacants_aux.filter((x) =>
        this.selectedTipoArray.includes(x['esquema-de-trabajo'])
      );
      this.isSearchType = true;
    } else {
      this.vacantsType = this.vacants_aux;
      this.isSearchType = false;
    }
    this.combineFilters();
  }
  
  combineFilters() {
    let filteredVacants = this.vacants_aux;
    if (this.isSearchActiveGlobal) {
      filteredVacants = filteredVacants.filter(vacant => this.vacantsGlobal.includes(vacant));
    }
    if (this.isSearchActiveCountry) {
      filteredVacants = filteredVacants.filter(vacant => this.vacantsCountry.includes(vacant));
    }
    if (this.isSearchArea) {
      filteredVacants = filteredVacants.filter(vacant => this.vacantsArea.includes(vacant));
    }
    if (this.isSearchType) {
      filteredVacants = filteredVacants.filter(vacant => this.vacantsType.includes(vacant));
    }
    this.vacants = filteredVacants;
  }
  

  getVacantsForPage(page: number): any[] {
    const startIndex = (page - 1) * this.itemsForPage;
    const endIndex = startIndex + this.itemsForPage;
    return this.vacants.slice(startIndex, endIndex);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.pageAct = page;
    }
  }
  
  onTraducir(){
    this.translate = !this.translate;
    console.log(this.translate)
  }
  
  redirect(vacant: any){
    localStorage.setItem("vacant", JSON.stringify(vacant));
    this.router.navigate(['/details'], { queryParams: { transalte: this.translate } });
  }

  cambiarIdioma(lang: string) {
    this.idiomaActual = lang;
    this.traducciones = this.idiomas[lang];
    console.log(`Idioma cambiado a: ${lang}`);
  }

  
  openModal(): void {
    this.modalRef = this.modalService.open(this.content);
  }

  closeModal(): void {
    this.modalRef.close();
  }

}
