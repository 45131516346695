<div class="toolbar d-flex justify-content-between" role="banner">
  <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
  <div class="rounded-dropdown" style="padding-right: 3rem;">
    <button class="rounded-button" (click)="openMenu = !openMenu">
        <img src="assets/usuario.png" alt="Usuario">
    </button>
    <div class="dropdown-content" [ngClass]="{'show': openMenu}">
        <a (click)="cerrarSesion()">Cerrar sesión</a>
    </div>
</div>
</div>

<div class="content" role="main">
  <div class="main-container">
    <div class="form-container mt-3">
      <div class="row">
        <div class="col-md-12">
          <h2 class="mb-3">Role profile</h2>
          <p>Llena los siguientes campos que se te solicitan</p>
        </div>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-6 col-sm-12">
            <div class="form-body ">

              <div class="form-group mb-3 ">
                <label class="color-primary"><strong>Nombre de vacante *</strong></label>
                <input type="text" class="form-control" formControlName="nombreVacante" placeholder="Ingresa vacante" title="Ingresa nombre de la vacante"
                  [ngClass]="{ 'is-invalid': submitted && fa['nombreVacante']['errors'], 'is-valid': fa['nombreVacante'].valid }" />
                <small class="form-text text-muted danger" *ngIf="submitted && fa['nombreVacante']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['nombreVacante']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="color-primary"><strong>Jefe directo *</strong></label>
                <input type="text" class="form-control" formControlName="jefeDirecto" placeholder="Ingresa nombre de jefe directo" title="Ingresa nombre del jefe directo"
                  [ngClass]="{ 'is-invalid': submitted && fa['jefeDirecto']['errors'], 'is-valid': fa['jefeDirecto'].valid }" />
                <small class="form-text text-muted danger" *ngIf="submitted && fa['jefeDirecto']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['jefeDirecto']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="color-primary"><strong>Área *</strong></label>
                <select class="custom-select" formControlName="area" [ngClass]="{'is-invalid': submitted && fa['area']['errors'], 'is-valid': fa['area'].valid }">
                    <option>Asuntos Regulatorios</option>
                    <option>Brand Operations</option>
                    <option>Comercial</option>
                    <option>Comunicación y Publicidad</option>
                    <option>Dirección Médica</option>
                    <option>Finanzas</option>
                    <option>Compras</option>
                    <option>Legal</option>
                    <option>Recuros Humanos</option>
                    <option>Sistemas (IT)</option>
                    <option>Supply</option>

                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fa['area']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['area']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="color-primary"><strong>Tipo de Vacante *</strong></label>
                <select class="custom-select" formControlName="tipoVacante" [ngClass]="{'is-invalid': submitted && fa['tipoVacante']['errors'], 'is-valid': fa['tipoVacante'].valid }">
                  <option>Confidencial</option>
                  <option>Pública</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fa['tipoVacante']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['tipoVacante']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="color-primary"><strong>Formación académica *</strong></label>
                    <select class="custom-select" formControlName="FormacionAcademica" id="FormControlSelectFormacionAcademica" [ngClass]="{'is-invalid': submitted && fa['FormacionAcademica']['errors'], 'is-valid': fa['FormacionAcademica'].valid }">
                      <option>Primaria</option>
                      <option>Secundaria</option>
                      <option>Auxiliares de salud</option>
                      <option>Bachillerato o Preparatoria</option>
                      <option>Curso Posténico de Enfermería</option>
                      <option>Carrera Técnica</option>
                      <option>Licenciatura, Ingeniera y Técnico en superior universitario</option>
                      <option>Especialización en Educación Superior</option>
                      <option>Maestría</option>
                      <option>Doctorado</option>
                      <option>Especialidades Médicas</option>
                      <option>Curso de Alta Especialidad</option>
                    </select>
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['FormacionAcademica']['errors']"
                    class="invalid-feedback">
                      <div *ngIf="fa['FormacionAcademica']['errors']['required']">
                        Selecciona una opción *</div>
                    </small>
                  </div>
                </div>
              </div>
          

              <div class="form-group mb-3">
                <label class="color-primary" for="exampleFormControldescripcionVacante"><strong>Descripción de la vacante *</strong></label>
                <textarea class="form-control" formControlName="descripcionVacante" id="exampleFormControldescripcionVacante" rows="2"  [ngClass]="{ 'is-invalid': submitted && fa['descripcionVacante']['errors'], 'is-valid': fa['descripcionVacante'].valid }"></textarea>
                <small class="form-text text-muted danger" *ngIf="submitted && fa['descripcionVacante']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['descripcionVacante']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
              </div>

            </div>

        </div>
        <div class="col-md-6 col-sm-12">
          
            <div class="form-body">
              <div class="form-group mb-3">
                <label class="color-primary"><strong>País *</strong></label>
                <select class="custom-select" formControlName="selectedPais" id="FormControlSelectPais" title="Selecciona el país" [ngClass]="{ 'is-invalid': submitted && fa['selectedPais']['errors'], 'is-valid': fa['selectedPais'].valid }" (ngModelChange)="onSelectedCountryChanged()">
                    <option value="Selecciona" disabled selected>Selecciona País</option>
                    <option value="1">México</option>
                    <option value="2">EE.UU</option>
                    <option value="3">Colombia</option>
                    <option value="4">Perú</option>
                    <option value="5">Costa Rica</option>
                    <option value="6">Panamá</option>
                    <option value="7">Honduras</option>
                    <option value="8">El Salvador</option>
                    <option value="9">Nicaragua</option>
                    <option value="10">Guatemala</option>
                    <option value="11">República Dominicana</option>
                    <option value="12">Argentina</option>
                    <option value="13">Chile</option>
                    <option value="14">Uruguay</option>
                    <option value="15">Paraguay</option>
                    <option value="16">Brazil</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fa['selectedPais']['errors']"
                    class="invalid-feedback">
                    <div *ngIf="fa['selectedPais']['errors']['required']">
                      Selecciona una opción *</div>
                  </small>
              </div>

              <div class="row mb-3">
                <div class="form-group col-md-6 col-lg-4">
                  <label class="color-primary "><strong>Rango salarial *</strong></label>
                  <input type="number" class="form-control" formControlName="rangoSalarialMin" placeholder="Min" title="Ingreso mínimo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMin']['errors'], 'is-valid': fa['rangoSalarialMin'].valid }" />
                  <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMin']['errors']"
                    class="invalid-feedback">
                    <div class="" *ngIf="fa['rangoSalarialMin']['errors']['required']">
                      Olvidaste llenar el campo *</div>
                  </small>
                </div>
                <div class="form-group col-md-6 col-lg-4 range-max">
                  <input type="number" (ngModelChange)="validateMaxValue()" class="form-control" formControlName="rangoSalarialMax" placeholder="Max" title="Ingreso máximo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMax']['errors'], 'is-valid': fa['rangoSalarialMax'].valid }" />
                  <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMax']['errors']"
                    class="invalid-feedback">
                    <div class="" *ngIf="fa['rangoSalarialMax']['errors']['required']">
                      Olvidaste llenar el campo *</div>
                      <div class="" *ngIf="fa['rangoSalarialMax']['errors']['maxValueInvalid']">
                        El valor máximo debe ser mayor que el valor mínimo.
                      </div>
                  </small>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                  <label class="color-primary " for="inputMoneda"><strong>Moneda *</strong></label>
                  <select id="inputMoneda" formControlName="moneda" class="custom-select " title="Selecciona el tipo de moneda"  [ngClass]="{ 'is-invalid': submitted && fa['moneda']['errors'], 'is-valid': fa['moneda'].valid }" >
                    <option value="MXN">MXN</option>
                    <option value="USD">USD</option>
                    <option value="COP">COP</option>
                    <option value="PEN">PEN</option>
                    <option value="CRC">CRC</option>
                    <option value="PAB">PAB</option>
                    <option value="HNL">HNL</option>
                    <option value="SVC">SVC</option>
                    <option value="NIO">NIO</option>
                    <option value="GTQ">GTQ</option>
                    <option value="DOP">DOP</option>
                    <option value="ARS">ARS</option>
                    <option value="CLP">CLP</option>
                    <option value="UYU">UYU</option>
                    <option value="PYG">PYG</option>
                    <option value="BRL">BRL</option>
                  </select>
                  <small class="form-text text-muted danger" *ngIf="submitted && fa['moneda']['errors']"
                    class="invalid-feedback">
                    <div class="" *ngIf="fa['moneda']['errors']['required']">
                      Selecciona una opción *</div>
                  </small>
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="color-primary"><strong>Nivel de la vacante *</strong></label>
                <select class="custom-select" formControlName="nivel" id="FormControlSelectPais" title="Selecciona el país" [ngClass]="{ 'is-invalid': submitted && fa['nivel']['errors'], 'is-valid': fa['nivel'].valid }" (ngModelChange)="onSelectedCountryChanged()">
                  <option>0</option>
                  <option>1B</option>
                  <option>1A</option>
                  <option>2B</option>
                  <option>2A</option>
                  <option>3B</option>
                  <option>3A</option>
              </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fa['nivel']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['nivel']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="color-primary"><strong>Ubicación *</strong></label>
                <input type="text" class="form-control" formControlName="ubicacion" placeholder="Ingresa nombre de la ubicación" title="Ingresa nombre de la ubicación"
                  [ngClass]="{ 'is-invalid': submitted && fa['ubicacion']['errors'], 'is-valid': fa['ubicacion'].valid }" />
                <small class="form-text text-muted danger" *ngIf="submitted && fa['ubicacion']['errors']"
                  class="invalid-feedback">
                  <div *ngIf="fa['ubicacion']['errors']['required']">
                    Olvidaste llenar el campo *</div>
                </small>
                <div class="valid-feedback">
                  
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label class="color-primary"><strong>Años de experiencia *</strong></label>
                      <select class="custom-select" formControlName="experiencia" id="FormControlSelectAniosExperiencia" [ngClass]="{ 'is-invalid': submitted && fa['experiencia']['errors'], 'is-valid': fa['experiencia'].valid }">
                        <option value="1">Sin experiencia</option>
                        <option value="2">Menos de un año</option>
                        <option value="3">Más de dos años</option>
                        <option value="4">Más de tres años</option>
                        <option value="5">Más de nueve años</option>
                      </select>
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['experiencia']['errors']"
                    class="invalid-feedback">
                    <div *ngIf="fa['experiencia']['errors']['required']">
                      Selecciona una opción *</div>
                    </small>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label class="color-primary"><strong>Esquema de trabajo *</strong></label>
                      <select class="custom-select" formControlName="esquemaTrabajo" id="FormControlSelectAniosExperiencia" [ngClass]="{ 'is-invalid': submitted && fa['esquemaTrabajo']['errors'], 'is-valid': fa['esquemaTrabajo'].valid }">
                        <option value="1">Presencial</option>
                        <option value="2">Remoto</option>
                        <option value="3">Mixto</option>
                      </select>
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['esquemaTrabajo']['errors']"
                    class="invalid-feedback">
                    <div *ngIf="fa['esquemaTrabajo']['errors']['required']">
                      Selecciona una opción *</div>
                    </small>
                  </div>
                </div>
                
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="btn-section">
              <button type="submit" class="btn btn-primary">
                Enviar role profile
              </button>
            </div>
          
        </div>
      </div>
    </form>


    </div>
  </div>

  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39"
      d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
      transform="translate(142.69 -634.312)" fill="#eee" />
  </svg>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>