<div class="container-footer">
    <div class="row color-level-one">
       <div class="col-lg-12 col-md-12">
           <div class="row">
             <div class="col-lg-2 col-md-0 col-sm-0"></div>
             <div class="col-lg-2 col-md-3 col-sm-4 text-center">
               <img class="color-img" src="assets/genommalab-isologo_mx.svg" />
             </div>
             <div class="col-lg-6 col-md-8 col-sm-8">
               <p class="text-justify">{{ traducciones.footerText1 }}</p>
               <p class="nosotros">{{ traducciones.footerText2 }}</p>
             </div>
             <div class="col-lg-2 col-md-1 col-sm-0"></div>
           </div>
         </div>
         
    </div>
   </div>
   <div class="color-level-two">
       <div class="container-level-two d-flex justify-content-between"  style="color: white !important;" >
           <div class="row row-size">
               <div class="col-lg-4 col-md-12 col-sm-12"><p class="copyright">© COPYRIGHT - GENOMMA LAB 2021</p></div>
               <div class="col-lg-4 col-md-12 col-sm-12"><a routerLink="{{ traducciones.UrlLink1 }}" target="_blank" style="color: white;">{{ traducciones.TextLink1 }}</a></div>
               <div class="col-lg-4 col-md-12 col-sm-12"><a routerLink="{{ traducciones.UrlLink2 }}" target="_blank" style="color: white;">{{ traducciones.TextLink2 }}</a></div>
           </div>
       </div>
   </div>