import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tabla-pais-success',
  templateUrl: './tabla-pais-success.component.html',
  styleUrls: ['./tabla-pais-success.component.css']
})
export class TablaPaisSuccessComponent implements OnInit {
  @Input() selectedCountry: string = '';
  @Input() dataSuccessRate: any;
  @Input() type: string = '';

  successRate: any;
  current_year: number = 0;

  constructor() {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit(): void {

    if(this.type == 'country'){
      this.successRate = this.dataSuccessRate["country"].find( (x: any) => decodeURIComponent(x["pais"]) == this.selectedCountry)["report"];
    } else if(this.type == 'cluster'){
      this.successRate = this.dataSuccessRate["cluster"].find( (x: any) => x["cluster"] == this.selectedCountry)["report"];
    } else if(this.type == 'responsable'){
      this.successRate = this.dataSuccessRate["responsable"].find( (x: any) => x["responsable"] == this.selectedCountry)["report"];
    }
  }
  onChanges(changes: SimpleChanges){
    if(changes['selectedCountry']){
      const newCountry =  changes['selectedCountry'].currentValue;
    }
  }

  calculatePercentage(numerator: number, denominator: number): string {
    if (numerator === 0 || denominator === 0) {
      return '0%';  // Retorna '0%' si el numerador o el denominador son 0
    } else {
      let percentage = this.round((numerator / denominator) * 100);
      if (percentage > 100) {
        return `100%`;  
      }
      return `${percentage}%`;
    }
  }
  
  round(value: number): number {
    return Math.round(value);
  }
  
}
