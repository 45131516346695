<div class="modal">
    <div class="modal-content">
      <div class="container text-container">
        <div class="position-button">
          <button class="button-back" (click)="closeModal()">
            <svg  width="35" height="35" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#38ACE7"  stroke-width="2" />
              <path d="M6 6L18 18" stroke="#38ACE7" stroke-width="2" />
            </svg>
          </button>
        </div>
        <div class="row text-center mb-2">
          <div class="col-sm-12 col-lg-12 col-md-12">
             <label class="margin-h4"><Strong>Por favor lee el siguiente texto</Strong></label>
          </div>
      </div>
      <div class="policy-content">
        <div class="main-container">
          <div class="form-container mt-4">
                <h1 class="text-center mb-4">Política de Privacidad Global</h1>
                <h5 class="mb-3">AVISO DE PRIVACIDAD GLOBAL</h5>
                <p>Las empresas que forman parte del grupo Genomma Lab (en adelante “Genomma Lab”), serán las responsables del tratamiento de sus datos personales según sea el caso y le informan lo siguiente:</p>            
                <h5 class="mb-3">¿Qué datos personales se someten a tratamiento?</h5>
                <p>Genomma Lab, para llevar a cabo las finalidades descritas en el presente aviso de privacidad, da tratamiento a los siguientes datos personales:</p>
                <ul>
                  <li>
                    <h5>Datos personales que nos proporcione directamente: </h5>
                    <p>Recopilamos datos sobre cómo utiliza nuestros servicios y productos, así como los tipos de contenido que ve o con los que interactúa, o la frecuencia y duración de sus actividades. También recopilamos su nombre, apellidos, correo electrónico, fecha de nacimiento, número de teléfono, dirección y genero cuando se pone en contacto con nosotros.</p>
                  </li>
                  <li>
                    <h5>Datos personales que recopilamos automáticamente:  </h5>
                    <p>Recibimos y almacenamos ciertos tipos de datos personales cada vez que usted interactúa con nosotros en línea. Utilizamos cookies y tecnologías de seguimiento para obtener datos personales cuando su navegador web accede a nuestros sitios web por medio de anuncios u otro contenido suministrado por Gennomma Lab.</p>
                  </li>
                </ul> 
              <h5 class="mb-3">¿Para qué fines utilizaremos sus datos personales?</h5>
              <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
              <h5 class="mb-3">Finalidades Primarias</h5>
              <ul>
                <li>
                  <p>Cotización, seguimiento y evaluación de los bienes y/o servicios que suministramos.</p>
                </li>
                <li>
                  <p>Comercialización, envío y entrega de productos de Genomma Lab.</p>
                </li>
                <li>
                  <p>Fines de identificación y contacto vía telefónica o a través del correo electrónico.</p>
                </li>
                <li>
                  <p>Llevar a cabo las actividades necesarias para el desarrollo y cumplimiento de las obligaciones que se originen y se deriven de la relación contractual y/o comercial que tenga con Genomma Lab.</p>
                </li>
                <li>
                  <p>Atención de dudas y sugerencias. </p>
                </li>
              </ul>
                <p>De manera adicional, y contando con su consentimiento, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio y/o producto solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>
    
        <h5 class="mb-3">Finalidades secundarias </h5>
        <ul>
          <li>
            <p>
              Análisis, elaboración de estadística y reportes internos.
            </p>
          </li>
          <li>
            <p>
              Fines mercadotécnicos, publicitarios y de prospección comercial relacionados con productos y servicios que pueda realizar Genomma Lab o de terceros con los que Genomma Lab tenga celebrados convenios, contratos y una relación comercial.
            </p>
          </li>
        </ul>
      <p>Le informamos que el mecanismo que el responsable tiene implementado para que usted pueda manifestar su negativa para el tratamiento de sus datos personales con relación a las finalidades secundarias, es a través del envio de un correo electrónico a la direccion datospersonales@genommalab.com mediante el cual usted manifieste dicha negativa e identifique la o las finalidades secundarias respecto a las cuales desea manifestar dicha negativa.
        En la recolección y tratamiento de datos personales que usted nos proporcione, cumplimos todos los principios establecidos en las distintas normativas aplicables: Licitud, calidad, consentimiento, información, lealtad, proporcionalidad, responsabilidad, seguridad, veracidad y exactitud, etc.</p>
        <h5 class="mb-3">Seguridad de la información </h5>
        <p>Sus datos personales serán almacenados en una base de datos propiedad de Genomma Lab y serán tratados en forma confidencial y con las debidas medidas de seguridad que se detallarán más adelante. Las operaciones que Genomma Lab realizará sobre sus datos incluyen su recolección, uso, análisis, almacenamiento, perfilamiento, supresión, transferencia, transmisión y, en general, su procesamiento. </p>
        <h5 class="mb-3">Transferencias de datos  </h5>
        <h5 class="mb-3">¿Con quién compartimos su información personal y para qué fines?</h5>
        <p>Le informamos que sus datos personales son compartidos dentro y fuera del país con las siguientes personas, empresas, organizaciones y autoridades distintas a nosotros, para los siguientes fines:</p>
        <h5 class="mb-3">Genomma Lab podrá transferir sus datos en los siguientes casos:</h5>
        <ul>
          <li>
            <p>Cuando se trate de empresas nacionales o internacionales del mismo grupo, como subsidiarias, filiales, afiliadas, controladas o controladoras bajo el control común del responsable o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que lo opere bajo los mismos procesos y políticas internas.</p>
          </li>
          <li>
            <p>Con uno o varios terceros, con quienes previamente hayamos celebrado contratos con cláusula de confidencialidad y de protección de datos personales y quienes prestan servicios a Genomma Lab que son complementarios y/o se relacionan con los servicios y/o productos que usted ha solicitado y/o cuyos servicios prestados a la Compañía son necesarios para permitir que Genomma Lab pueda proveer a usted los servicios y/o productos que ha solicitado.</p>
          </li>
          <li>
            <p>Con uno o varios terceros como proveedores de servicios seleccionados para manejar u administrar tanto este portal como los datos personales que se recaban a través de este.</p>
          </li>
          <li>
            <p>Cuando la transferencia sea necesaria o legalmente exigida por alguna autoridad para salvaguardar un interés público, o para la procuración o administración de justicia.</p>
          </li>
          <li>
            <p>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.</p>
          </li>
        </ul>
        
        <h5 class="mb-3">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h5>
        <p>Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento que damos a los mismos, así como a rectificarlos en caso de ser inexactos o estar incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso, o haya finalizado la relación contractual o de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos. </p>
        <p>Usted podrá solicitar a Genomma Lab el ejercicio de sus derechos y la negativa para el tratamiento de las finalidades no necesarias a través del siguiente procedimiento:
        </p>
        <p>1.    Enviar un correo electrónico a la dirección datospersonales@genommalab.com, señalando su nombre completo, documentos que acrediten su identidad, o bien, la representación legal del titular, descripción clara y precisa de los datos personales respecto de los que se buscar ejercer alguno de sus derechos, así como cualquier otro elemento que facilite la localización de los datos personales.  </p>
        <p>2.    Al momento de recibir su solicitud, Genomma Lab le enviará un acuse de recibo con la fecha de recepción que corresponda. En caso de que su solicitud no cumpla con los requisitos establecidos en el punto anterior, Genomma Lab le requerirá los elementos o documentos necesarios para que subsane su solicitud en un plazo de 10 (diez) días hábiles, contados a partir de la fecha de la recepción de su solicitud. Usted contará con 20 (veinte) días hábiles para subsanar lo requerido, en caso de no hacerlo, su solicitud se tendrá como no presentada. </p>
        <p>3.    Su solicitud será contestada respecto a su procedencia en un término no mayor a 40 (cuarenta) días hábiles contados a partir de la fecha de recepción establecida en el acuse de recibo. En caso de que tu solicitud resulte procedente, Genomma Lab tendrá un plazo de 30 (treinta) días hábiles para hacerla efectiva a partir de la notificación de su procedencia. Cuando así lo justifiquen las circunstancias, los plazos anteriores podrán ser ampliados por una sola ocasión por un período igual.</p>
        <h5 class="mb-3">¿Cómo puede revocar su consentimiento para el uso de sus datos personales?</h5>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
          Toda solicitud para limitar o revocar el uso o divulgación de sus datos personales deberá ser realizada a través del mismo procedimiento y términos establecidos para ejercer sus derechos, utilizando la dirección de correo electrónico: datospersonales@genommalab.com.
          </p>
          <h5 class="mb-3">Datos de Contacto   </h5>
          <p>Si tiene alguna pregunta acerca de este Aviso, sobre el uso que le demos a su información personal o quiere ejercer alguno de sus derechos puede contactarnos por medio del siguiente correo:
    
            datospersonales@genommalab.com.</p>
          <h5 class="mb-3">El uso de tecnologías de rastreo en nuestro portal de Internet</h5>
          <p>Le informamos que en nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
    
            Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de internet, secciones consultadas, y páginas de internet accedidas previo a la nuestra, mismos que utilizamos para mejorar tu experiencia de usuario en nuestra página web, aumentar la seguridad dicha página, medir el uso de nuestros servicios y proporcionar publicidad. Asimismo, le informamos que este portal tiene ligas a otros sitios externos, de los cuales el contenido y políticas de privacidad no son responsabilidad de Genomma Lab, por lo que el usuario, en su caso, quedara sujeto a las políticas de privacidad de aquellos otros sitios, sin responsabilidad alguna a cargo de Genomma Lab.   
            Estas tecnologías podrán deshabilitarse a través de la configuración de tu navegador y de otras herramientas disponibles en tus sistemas de cómputo y/o dispositivos móviles. Sin embargo, esto puede impedir que nuestros sitios o servicios funcionasen de manera correcta</p>
    
          <h5 class="mb-3">¿Cómo Protegemos sus datos? </h5>
          <p>Genomma Lab ha adoptado medidas de seguridad razonables para proteger su información, e impedir el acceso no autorizado a sus datos o cualquier modificación, divulgación o destrucción no autorizada de los mismos. La información recolectada por Genomma Lab será mantenida de manera estrictamente confidencial. El acceso a sus datos personales está restringido a aquellos empleados, contratistas y representantes de Genomma Lab que necesitan conocer tales datos para desempeñar sus funciones y desarrollar o mejorar nuestros servicios. Genomma Lab exige a sus proveedores los mismos estándares de confidencialidad y no permite el acceso a esta información a terceros ajenos a la empresa. </p>
          <h5 class="mb-3">¿Cuánto tiempo conservamos sus datos personales?</h5>
          <p>Le informamos que Genomma Lab conservara sus datos personales por el tiempo en que sigan siendo adecuados, proporcionales y necesarios para el ámbito y las finalidades por las que fueron solicitados, o bien durante el tiempo que establezca la normativa aplicable. Revisaremos activamente los datos personales que poseemos y los eliminaremos de forma segura cuando ya no exista una necesidad legal, comercial o de los consumidores para que se conserven.</p>
    
          <h5 class="mb-3">¿Cómo puede conocer los cambios a este aviso de privacidad?</h5>
          <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
    
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestra página web y/o aplicaciones administradas por Genomma Lab. Por su seguridad, consulte en todo momento que así lo desee el contenido de este aviso de privacidad en nuestro portal.
            
            El envío que usted realice de sus datos personales a través de la página de internet de Genomma Lab se entenderá como su consentimiento para que sus datos personales sean tratados y transferidos conforme a lo señalado en el presente Aviso de Privacidad.</p>
          <h5 class="mb-3">Consentimiento y aceptación al Aviso de Privacidad </h5>
          <p>Dado que el presente aviso ha sido puesto a su disposición a través de nuestra página de internet, se entiende que usted consiente el tratamiento de sus datos personales de conformidad con lo establecido en el mismo. Usted podrá revocar dicho consentimiento de acuerdo al procedimiento establecido en el presente aviso.</p>
          </div>
        </div>
      </div>
      <div class="row container-check">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="form-check">
            <input class="form-check-input border-checkbox" type="checkbox" (click)="aceptedEvent()" value="" id="flexCheckDefault" [(ngModel)]="check">
            <label class="form-check-label fw-bold" for="flexCheckDefault">
              Acepto las políticas de privacidad, términos y condiciones
            </label>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a [routerLink]="['/aviso-legal']" target="_blank">Consulta términos y condiciones aquí</a>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="check">
          <img src="assets/loader.gif" width="70px"/>
        </div>
      </div>
    </div>
  </div>
