import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions,  ChartDataset } from 'chart.js';



@Component({
  selector: 'app-grafica-historico',
  templateUrl: './grafica-historico.component.html',
  styleUrls: ['./grafica-historico.component.css']
})
export class GraficaHistoricoComponent implements OnInit {
  
  @Input() data: any;
  vacant: any;
  years: number[] = [];
  current_year: number = 0;

  public barChartOptions: ChartOptions = {
    responsive: false,
    scales: { y: { beginAtZero: false } }
  };

  public barChartLabels: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio','Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  public barChartLegend = true;

  public barChartData: ChartDataset[] = [
    { 
      data: [12, 19, 3, 5, 2, 3, 12, 18, 8, 9, 3, 4], 
      label: 'Histórico',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }
  ];

  constructor() {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit() {
    
    const startDate = this.data["range"]["min_year"];
    const endDate = this.data["range"]["max_year"];
    for (let i = startDate; i <= endDate; i++) {
      this.years.push(i);
    }

    this.filterDate();
  }

  filterDate(){
    this.vacant = this.data["general"][0]["report"].find( (x: any) => x["year"]== this.current_year.toString())["vacants"];
    const list_month = [];
    for (let i = 1; i < 13; i++) {
      list_month.push(this.vacant["vacants_month"][i.toString()])
    }

    this.barChartData= [
      { 
        data: list_month, 
        label: 'Histórico',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ];

  }

  calculatePercentage(numerator: number, denominator: number): string {
    console.log(`Numerator: ${numerator}, Denominator: ${denominator}`);
    if (numerator === 0 || denominator <= 0) {
      return '0%';  // Retorna '0%' si el numerador es 0 o el denominador es 0 o negativo
    } else {
      let percentage = this.round((numerator / denominator) * 100);
      if (percentage > 100) {
        return `100%`;  
      }
      return `${percentage}%`;
    }
  }
  
  round(value: number): number {
    return Math.round(value);
  }
  
}
