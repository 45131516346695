import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorApiService implements HttpInterceptor {

  private countRequest = 0;
  constructor(private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   

    const exceptions = [
      'vacantes_generales/register',
      'candidato/crear',
      'postulant/job-referencys',
      'postulant/post-business-case',
      'postulant/post-form-documents',
      'vacantes_generales/cargar-documentos',
      '/postulant/comparativa-economica',
      '/generate-token'

    ];
    const authorization = [
      'reports',
      'vacantes_generales/register'
    ];


    const exep = exceptions.find(I => request.url.includes(I));
    const exepJWT = authorization.find(I => request.url.includes(I));
    const access_token = localStorage.getItem("AKSIpln");

    
    if(!exep){
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json'
        }    
      });
    }

    if(exepJWT && access_token){
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${access_token}`
        }
      });
    }
    
    this.countRequest++;
    return next.handle(request).pipe(
        finalize( () => {
            
      })
    );

  }
}
