<div>
    <table style="background-color: white;">
        <thead>
            <tr>
                <th id="timeToFillHeader">{{selectedReport}}({{selectedCluster}})</th>
                <th id="enero" class="padding-headers">Enero</th>
                <th id="febrero" class="padding-headers">Febrero</th>
                <th id="marzo" class="padding-headers">Marzo</th>
                <th id="q1" class="padding-headers">1°Q</th>
                <th id="abril" class="padding-headers">Abril</th>
                <th id="mayo" class="padding-headers">Mayo</th>
                <th id="junio" class="padding-headers">Junio</th>
                <th id="q2" class="padding-headers">2°Q</th>
                <th id="first-semestre" class="padding-headers">1er Semestre 2023</th>
                <th id="julio" class="padding-headers">Julio</th>
                <th id="agosto" class="padding-headers">Agosto</th>
                <th id="septiembre" class="padding-headers">Septiembre</th>
                <th id="q3" class="padding-headers">3°Q</th>
                <th id="octubre" class="padding-headers">Octubre</th>
                <th id="noviembre" class="padding-headers">Noviembre</th>
                <th id="diciembre" class="padding-headers">Diciembre</th>
                <th id="q4" class="padding-headers">4°Q</th>
                <th id="second-semestre" class="padding-headers">2do Semestre 2022</th>
                <th id="actual">Actual</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="n1">Time to Fill N1 (Live)</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">43</td>
                <td class="text-center">53</td>
                <td class="text-center">63</td>
                <td class="text-center">33</td>
                <td class="text-center">32</td>
                <td class="text-center">22</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
            </tr>
            <tr>
                <td class="n1">Time to Fill N2B (Live)</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">43</td>
                <td class="text-center">53</td>
                <td class="text-center">63</td>
                <td class="text-center">33</td>
                <td class="text-center">32</td>
                <td class="text-center">22</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
            </tr>
            <tr>
                <td class="n1">Time to Fill N2A Y N3 (Live)</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">43</td>
                <td class="text-center">53</td>
                <td class="text-center">63</td>
                <td class="text-center">33</td>
                <td class="text-center">32</td>
                <td class="text-center">22</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
                <td class="text-center">23</td>
            </tr>
        </tbody>
    </table>
</div>