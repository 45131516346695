<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
</div>
<div class="container-form">
    <div class="form-data">
        <div class="title">
            <h2 class="text-center">{{postulante["documento"]}}</h2>
            <p class="text-center">Para continuar con el proceso sigue las instrucciones.</p>
        </div>
        <div class="row padding-form">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Por favor sube el archivo de {{postulante["documento"]}} en formato {{ 
                    postulante["documento"].toLowerCase().includes("formato de compensación") ? "Excel(xlsx)" : ( 
                        postulante["documento"].toLowerCase().includes("caso de negocio") ? "PDF" : ""
                    )
                }}.</h5>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 margin-button">
                <label  class="color-primary mb-2">Sube aquí tu archivo <small class="text-danger">*</small></label>
               <input type="file" class="form-control"  title="Ingresa tu archivo" accept=".pdf,.xlsx" (change)="onFileSelected($event)" />    
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <button type="button" (click)="submitForm()" class="btn btn-primary btn-form mb-3">Enviar </button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>