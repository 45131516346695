<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
  </div>
  
  <div class="content" role="main">
    <div class="main-container">
      <div class="form-container mt-4">
            <h1 class="text-center mb-4">Global Privacy Policy</h1>
            <hr class="mb-3">
            <h5 class="mb-3">GLOBAL PRIVACY NOTICE</h5>
            <p>The companies that are part of the Genomma Lab group (hereinafter “Genomma Lab”) will be responsible for the processing of your personal data as the case may be and inform you of the following:</p>
             <h5 class="mb-3">What personal data is processed?</h5>
             <p>Genomma Lab, to carry out the purposes described in this privacy notice, processes the following personal data:</p>

             <ul>
                <li>
                  <h5>Personal data that you provide us directly: </h5>
                  <p>We collect data about how you use our services and products, as well as the types of content you view or interact with, or the frequency and duration of your activities. We also collect your first name, last name, email, date of birth, phone number, address and gender when you contact us.</p>
                </li>
                <li>
                  <h5>Personal data we collect automatically: </h5>
                  <p>We receive and store certain types of personal data whenever you interact with us online. We use cookies and tracking technologies to collect personal data when your web browser accesses our websites via advertisements or other content provided by Gennomma Lab.</p>
                </li>
              </ul>

              <h5 class="mb-3">For what purposes will we use your personal data?</h5>
              <p>We will use the personal data that we collect from you for the following purposes that are necessary for the service you request:</p>
              <h5 class="mb-3">Primary Purposes</h5>
              <ul>
                <li>
                  <p>Quote, monitoring and evaluation of the goods and/or services we supply.</p>
                </li>
                <li>
                  <p>Marketing, shipping and delivery of Genomma Lab products.</p>
                </li>
                <li>
                  <p>Purposes of identification and contact via telephone or email.</p>
                </li>
                <li>
                  <p>Carry out the activities necessary for the development and fulfillment of the obligations that arise and derive from the contractual and/or commercial relationship you have with Genomma Lab.</p>
                </li>
                <li>
                  <p>Attention to doubts and suggestions. </p>
                </li>
              </ul>
                <p>Additionally, and with your consent, we will use your personal information for the following secondary purposes that are not necessary for the service and/or product requested, but that allow and facilitate us to provide you with better service:</p>

             

        <h5 class="mb-3">How can you access, rectify or cancel your personal data, or oppose its use?</h5>
        <p>You have the right to access your personal data that we have and the details of the treatment we give to them, as well as to rectify them if they are inaccurate or incomplete; cancel them when you consider that they are not required for any of the purposes indicated in this notice, or the contractual or service relationship has ended, or oppose their processing for specific purposes. </p>
        <p>You may request Genomma Lab to exercise your rights and refuse to process non-necessary purposes through the following procedure:
        </p>
        <p>1. Send an email to the address datapersonales@genommalab.com, indicating your full name, documents that prove your identity, or, the legal representation of the owner, clear and precise description of the personal data with respect to which you seek to exercise any of your rights, as well as any other element that facilitates the location of personal data. </p>
        <p>2. Upon receipt of your request, Genomma Lab will send you an acknowledgment of receipt with the corresponding receipt date. In the event that your request does not meet the requirements established in the previous point, Genomma Lab will require the necessary elements or documents to correct your request within a period of 10 (ten) business days, counted from the date of the request. receipt of your request. You will have 20 (twenty) business days to correct what is required; if you do not do so, your request will be considered not submitted. </p>
        <p>3. Your request will be answered regarding its origin within a period of no more than 40 (forty) business days from the date of receipt established in the acknowledgment of receipt. If your request is approved, Genomma Lab will have a period of 30 (thirty) business days to make it effective from the notification of its origin. When circumstances justify it, the above deadlines may be extended on a single occasion for an equal period.</p>
        <h5 class="mb-3">How can you revoke your consent to the use of your personal data?</h5>
        <p>You can revoke the consent that, if applicable, you have given us for the processing of your personal data. However, it is important that you keep in mind that not in all cases we will be able to respond to your request or terminate the use immediately, since it is possible that due to some legal obligation we require to continue processing your personal data. Likewise, you must consider that, for certain purposes, the revocation of your consent will mean that we will not be able to continue providing you with the service you requested from us, or the conclusion of your relationship with us.
            Any request to limit or revoke the use or disclosure of your personal data must be made through the same procedure and terms established to exercise your rights, using the email address:datapersonales@genommalab.com.
            </p>
            <h5 class="mb-3">Contact Information </h5>
            <p>If you have any questions about this Notice, about the use we give to your personal information or want to exercise any of your rights, you can contact us through the following email:

            datospersonales@genommalab.com.</p>
            <h5 class="mb-3">The use of tracking technologies on our Internet portal</h5>
            <p>We inform you that on our Internet page we use cookies, web beacons and other technologies through which it is possible to monitor your behavior as an Internet user, as well as provide you with a better service and user experience when browsing our page.

                The personal data that we obtain from these tracking technologies are the following: browsing time, browsing time on our website, sections consulted, and internet pages accessed prior to ours, which we use to improve your user experience on our website, increase the security of our website, measure the use of our services and provide advertising. Likewise, we inform you that this portal has links to other external sites, of which the content and privacy policies are not the responsibility of Genomma Lab, so the user, if applicable, will be subject to the privacy policies of those others. sites, without any responsibility on the part of Genomma Lab.
                These technologies may be disabled through your browser settings and other tools available on your computer systems and/or mobile devices. However, this may prevent our sites or services from working properly</p>
       
              <h5 class="mb-3">How do we protect your data? </h5>
              <p>Genomma Lab has adopted reasonable security measures to protect your information, and prevent unauthorized access to your data or any unauthorized modification, disclosure or destruction thereof. The information collected by Genomma Lab will be kept strictly confidential. Access to your personal data is restricted to those Genomma Lab employees, contractors and representatives who need to know such data to perform their duties and develop or improve our services. Genomma Lab requires the same confidentiality standards from its suppliers and does not allow access to this information to third parties outside the company. </p>
              <h5 class="mb-3">How long do we keep your personal data?</h5>
              <p>We inform you that Genomma Lab will retain your personal data for as long as it remains appropriate, proportional and necessary for the scope and purposes for which it was requested, or for the time established by the applicable regulations. We will actively review the personal data we hold and securely delete it when there is no longer a legal, business or consumer need for it to be retained.</p>
       
              <h5 class="mb-3">How can you find out about changes to this privacy notice?</h5>
              <p>This privacy notice may be modified, changed or updated due to new legal requirements; of our own needs for the products or services we offer; of our privacy practices; Changes in our business model, or other causes.
       
                We are committed to keeping you informed about any changes that this privacy notice may undergo, through our website and/or applications managed by Genomma Lab. For your security, consult the content of this privacy notice at any time you wish. privacy on our portal.
               
                The sending of your personal data through the Genomma Lab website will be understood as your consent for your personal data to be processed and transferred in accordance with the provisions of this Privacy Notice.</p>
              <h5 class="mb-3">Consent and acceptance of the Privacy Notice </h5>
              <p>Given that this notice has been made available to you through our website, it is understood that you consent to the processing of your personal data in accordance with what is established therein. You may revoke said consent according to the procedure established in this notice.</p>
              </div>
    </div>
  
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
      <path id="Path_39" data-name="Path 39"
        d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
        transform="translate(142.69 -634.312)" fill="#eee" />
    </svg>
  
  </div>
  <div>
    <app-footer [idioma]="idiomaActual"></app-footer>
  </div>