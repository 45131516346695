<div style="overflow-x: scroll;">
    <table style="background-color: white;">
        <thead>
            <tr>
                <th id="timeToFillHeader">TIME TO FILL {{selectedCountry}}</th>
                <th id="enero" class="padding-headers">Enero</th>
                <th id="febrero" class="padding-headers">Febrero</th>
                <th id="marzo" class="padding-headers">Marzo</th>
                <th id="q1" class="padding-headers">1°Q</th>
                <th id="abril" class="padding-headers">Abril</th>
                <th id="mayo" class="padding-headers">Mayo</th>
                <th id="junio" class="padding-headers">Junio</th>
                <th id="q2" class="padding-headers">2°Q</th>
                <th id="first-semestre" class="padding-headers">1er Semestre {{current_year}}</th>
                <th id="julio" class="padding-headers">Julio</th>
                <th id="agosto" class="padding-headers">Agosto</th>
                <th id="septiembre" class="padding-headers">Septiembre</th>
                <th id="q3" class="padding-headers">3°Q</th>
                <th id="octubre" class="padding-headers">Octubre</th>
                <th id="noviembre" class="padding-headers">Noviembre</th>
                <th id="diciembre" class="padding-headers">Diciembre</th>
                <th id="q4" class="padding-headers">4°Q</th>
                <th id="second-semestre" class="padding-headers">2do Semestre {{current_year}}</th>
                <th id="actual">Actual</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of timeToFillCountry">
                <td class="n1">Time to Fill {{row["level"]}} (Live)</td>
                <td class="text-center">{{row["total-vacants-date"]["1"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["2"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["3"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["1q"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["4"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["5"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["6"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["2q"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["1s"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["7"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["8"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["9"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["3q"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["10"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["11"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["12"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["4q"]}}</td>
                <td class="text-center">{{row["total-vacants-date"]["2s"]}}</td>
                <td class="text-center">{{row["total-vacants"]}}</td>
            </tr>
        </tbody>
    </table>
</div>