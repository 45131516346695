import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla-reporte-global',
  templateUrl: './tabla-reporte-global.component.html',
  styleUrls: ['./tabla-reporte-global.component.css']
})
export class TablaReporteGlobalComponent implements OnInit {
  
  @Input() report_selected: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
