<div class="toolbar d-flex justify-content-between" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
    <div class="rounded-dropdown" style="padding-right: 3rem;">
        <button class="rounded-button" (click)="toggleDropdown()">
            <img src="assets/usuario.png" alt="Usuario">
        </button>
        <div class="dropdown-content" [ngClass]="{'show': isDropdownOpen}">
            <a  (click)="cerrarSesion()">Cerrar sesión</a>
            <a routerLink="/role-profile">Registrar role profile</a>
            <a routerLink="/formulario-headHunting">Registrar candidato</a>
        </div>
    </div>
    
</div>

<div class="content-dashboard" role="main">
    <h3 class="pt-2 pb-2">Reportes y KPI's</h3>
    <ul class="nav nav-pills mb-3 mt-2 options-header" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="botones-header nav-link active" id="pills-time-fill-tab" data-bs-toggle="pill" data-bs-target="#pills-time-fill" type="button" role="tab" aria-controls="pills-time-fill" aria-selected="true">
                Time to Fill</button>
        </li>
        <li class="nav-item" role="presentation">
          <button (click)="limpiarSelectCluster()" class="botones-header nav-link" id="pills-success-rate-tab" data-bs-toggle="pill" data-bs-target="#pills-success-rate" type="button" role="tab" aria-controls="pills-success-rate" aria-selected="false">
                Success Rate</button>
        </li>
        <li class="nav-item" role="presentation">
            <button (click)="onSelectReport('1')" class="botones-header nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                Reportes</button>
        </li>
      </ul>
      
  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39"
      d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
      transform="translate(142.69 -634.312)" fill="#eee" />
  </svg>

</div>


<div class="content-dashboard-table">
    <div class="tab-content" id="pills-tabContent">
        <!-- time to fill -->
        <div class="tab-pane fade show active" id="pills-time-fill" role="tabpanel" aria-labelledby="pills-time-fill-tab">
            <div class="row position-button">
                <button (click)="exportToExcelTimeToFill()"type="button" class="btn btn-historico">Exportar a Excel</button>
            </div>
            <div class="container-dashboard">
                <div class="container container-table pb-4">
                    <div class="row espacio">
                        <div class="col">
                            <div class="form-group mb-3">
                                <label class="color-primary" for="select">Selecciona un país</label>
                                <ng-select
                                class="custom"
                                [items]="paisOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona países'"
                                [(ngModel)]="selectedCountryArray"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-1">
                                <label class="color-primary" for="select">Selecciona un clúster</label>
                                <ng-select
                                class="custom"
                                [items]="clusterOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona cluster'"
                                [(ngModel)]="selectedClustersArray"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-1">
                                <label class="color-primary" for="select">Selecciona un responsable</label>
                                <ng-select
                                class="custom"
                                [items]="responsableOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona responsable'"
                                [(ngModel)]="selectedResponsableArray"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="load">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla [NameTableToFill]="NameTableToFill" [dataTimeToFill]="time_to_fill"></app-tabla>
                            </div>
                        </div>
                    </div>
                    <div class="row espacio-table mb-4" *ngFor="let country of selectedCountryArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais [selectedCountry]="country" [dataTimeToFill]="time_to_fill" [type]="'country'"></app-tabla-pais>
                            </div>
                        </div>
                    </div>
                    <div class="row espacio-table mb-4" *ngFor="let cluster of selectedClustersArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais [selectedCountry]="cluster" [dataTimeToFill]="time_to_fill" [type]="'cluster'"></app-tabla-pais>
                            </div>
                        </div>
                    </div>
                    <div class="row espacio-table" *ngFor="let responsable of selectedResponsableArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais [selectedCountry]="responsable" [dataTimeToFill]="time_to_fill" [type]="'responsable'"></app-tabla-pais>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <!-- tab success rate -->
        <div class="tab-pane fade" id="pills-success-rate" role="tabpanel" aria-labelledby="pills-success-rate-tab">
            <div class="row position-button">
                <button (click)="exportToExcelSuccessRate()"type="button" class="btn btn-historico">Exportar a Excel</button>
            </div>
          
            <div class="row position-button">
                <button (click)="onHistorico()" type="button" class="btn btn-historico" [class.activatedBtn]="activeButton">Presiona aquí para mostrar el histórico</button>
            </div>
            <div class="container-dashboard">
                <div class="container container-table pb-4">
                    <div class="row espacio">
                        <div class="col">
                            <div class="form-group mb-3">
                                <label class="color-primary" for="select">Selecciona un país</label>
                                <ng-select
                                class="custom"
                                [items]="paisOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona países'"
                                [(ngModel)]="selectedCountryArray"
                            ></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-1">
                                <label class="color-primary" for="select">Selecciona un clúster</label>
                                <ng-select
                                class="custom"
                                [items]="clusterOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona cluster'"
                                [(ngModel)]="selectedClustersArray"
                            ></ng-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-1">
                                <label class="color-primary" for="select">Selecciona un responsable</label>
                                <ng-select
                                class="custom"
                                [items]="responsableOptions"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                [placeholder]="'Selecciona responsable'"
                                [(ngModel)]="selectedResponsableArray"></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="load">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-success-rate-global id="success_rate_global" [NameTableSuccess]="NameTableSuccess" [dataSuccessRate]="success_rate"></app-tabla-success-rate-global>
                            </div>
                        </div>
                    </div>

                    <div class="row espacio-table mb-4" *ngFor="let country of selectedCountryArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais-success [selectedCountry]="country" [dataSuccessRate]="success_rate" [type]="'country'"></app-tabla-pais-success>
                            </div>
                        </div>
                    </div>

                    <div class="row espacio-table" *ngFor="let clouster of selectedClustersArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais-success [selectedCountry]="clouster" [dataSuccessRate]="success_rate" [type]="'cluster'"></app-tabla-pais-success>
                            </div>
                        </div>
                    </div>

                    <div class="row espacio-table" *ngFor="let responsable of selectedResponsableArray">
                        <div class="col-lg-12">
                            <div class="table-cent">
                                <app-tabla-pais-success [selectedCountry]="responsable" [dataSuccessRate]="success_rate" [type]="'responsable'"></app-tabla-pais-success>
                            </div>
                        </div>
                    </div>




                    <div *ngIf="viewHistorico" #historicoId class="row espacio-table">
                        <div class="col-lg-12">
                            <div class="grafica">
                                <app-grafica-historico [data]="histories" ></app-grafica-historico>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Reports -->
        <div class="tab-pane fade container-report" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            <div class="row">
                <div class="col-3">
                    <h4 class="text-center">Selecciona un reporte</h4>
                </div>
                <div class="col-9"></div>
            </div>
            <div class="row d-flex justify-content-center">
                    <div class="col-3 d-flex align-items-start list-reports">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="buttons-reports nav-link" (click)="onSelectReport('1')" id="v-pills-vacantes-oferta-tab" data-bs-toggle="pill" data-bs-target="#v-pills-vacantes-oferta" type="button" role="tab" aria-controls="v-pills-vacantes-oferta" aria-selected="true">Vacantes en oferta</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('2')" id="v-pills-vacantes-cerradas-tab" data-bs-toggle="pill" data-bs-target="#v-pills-vacantes-cerradas" type="button" role="tab" aria-controls="v-pills-vacantes-cerradas" aria-selected="false">Vacantes cerradas</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('3')" id="v-pills-vacante-sin-atencion-tab" data-bs-toggle="pill" data-bs-target="#v-pills-vacante-sin-atencion" type="button" role="tab" aria-controls="v-pills-vacante-sin-atencion" aria-selected="false">Vacante sin atención</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('4')" id="v-pills-finalista-dry-run-tab" data-bs-toggle="pill" data-bs-target="#v-pills-finalista-dry-run" type="button" role="tab" aria-controls="v-pills-finalista-dry-run" aria-selected="false">Finalistas en Dry Run</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('5')" id="v-pills-descartado-dry-run-tab" data-bs-toggle="pill" data-bs-target="#v-pills-descartado-dry-run" type="button" role="tab" aria-controls="v-pills-descartado-dry-run" aria-selected="false">Finalistas descartados en Dry Run</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('6')" id="v-pills-cerradas-internas-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cerradas-internas" type="button" role="tab" aria-controls="v-pills-cerradas-internas" aria-selected="false">Posiciones cerradas internamente</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('9')" id="v-pills-contratados-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contratados" type="button" role="tab" aria-controls="v-pills-contratados" aria-selected="false">Contratados</button>
                            <button class="buttons-reports nav-link" (click)="onSelectReport('10')" id="v-pills-turover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-turover" type="button" role="tab" aria-controls="v-pills-turover" aria-selected="false">Back Out</button>
                        </div>
                        </div>
                <div class="col-9">
                    <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-vacantes-oferta" role="tabpanel" aria-labelledby="v-pills-vacantes-oferta-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 1">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-vacantes-cerradas" role="tabpanel" aria-labelledby="v-pills-vacantes-cerradas-tab">
        
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 2">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
                    <div class="tab-pane fade" id="v-pills-vacante-sin-atencion" role="tabpanel" aria-labelledby="v-pills-vacante-sin-atencion-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected==3">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-finalista-dry-run" role="tabpanel" aria-labelledby="v-pills-finalista-dry-run-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 4">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-descartado-dry-run" role="tabpanel" aria-labelledby="v-pills-descartado-dry-run-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 5">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-cerradas-internas" role="tabpanel" aria-labelledby="v-pills-cerradas-internas-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 6">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-contratados" role="tabpanel" aria-labelledby="v-pills-contratados-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 9">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-turover" role="tabpanel" aria-labelledby="v-pills-turover-tab">
                        <div class="container-dashboard-reports">
                            <div class="container pb-1">
                                <div class="row espacio-table" >
                                    <div class="col-lg-12">
                                        <div class="table-cent" *ngIf="report_selected == 10">
                                            <app-report [selectedReport]="report_selected" [pais]="selectedCountry" [data]="reports" [type]="'vacantes'"></app-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>