import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.css'],
})
export class ModalFormComponent implements OnInit {

  @Input() idiomaActual: string;
  @Input() traducciones: any ={};
  translations: any = {};
// idiomaActual: string = 'espanol';
  // traducciones: any = {};
  idiomas: any = {
    'espanol': {
      'title': 'Llena los siguientes campos',
      'label1': 'Nombre completo',
      'placeholder1': 'Ingresa tu nombre completo',
      'error1': 'Olvidaste llenar el campo',
      'label2': 'Formación Académica',
      'error2': 'Selecciona una opción',
      'label3': 'Idiomas hablados',
      'error3': 'Selecciona una opción',
      'label4': 'Sube tu CV * (.PDF)',
      'error4': 'Olvidaste llenar el campo',
      'label5': 'Dirección',
      'placeholder5': 'Ingresa tu dirección',
      'error5': 'Olvidaste llenar el campo',
      'label6': 'Correo electrónico',
      'placeholder6': 'Ingresa tu correo electrónico',
      'error6': 'Olvidaste llenar el campo',
      'label7': 'Rango salarial',
      'placeholder7': 'Min',
      'placeholder72': 'Max',
      'error7': 'Olvidaste llenar el campo',
      'error72': 'Olvidaste llenar el campo',
      'error73': 'El valor máximo debe ser mayor que el valor mínimo.',
      'label8': 'Moneda',
      'error8': 'Selecciona una opción',
      'label9': 'Habilidades relevantes para el puesto',
      'error9': 'Olvidaste llenar el campo',
      'textButton': 'Enviar solicitud',
      'error10': ' Acepta las políticas, términos y condiciones'

    },
    'ingles': {
      "title": "Fill out the following fields",
      "label1": "Full Name",
      "placeholder1": "Enter your full name",
      "error1": "You forgot to fill in the field",
      "label2": "Educational Background",
      "error2": "Select an option",
      "label3": "Languages Spoken",
      "error3": "Select an option",
      "label4": "Upload your CV * (.PDF)",
      "error4": "You forgot to fill in the field",
      "label5": "Address",
      "placeholder5": "Enter your address",
      "error5": "You forgot to fill in the field",
      "label6": "Email Address",
      "placeholder6": "Enter your email address",
      "error6": "You forgot to fill in the field",
      "label7": "Salary Range",
      "placeholder7": "Min",
      "placeholder72": "Max",
      "error7": "You forgot to fill in the field",
      "error72": "You forgot to fill in the field",
      "error73": "The maximum value must be greater than the minimum value.",
      "label8": "Currency",
      "error8": "Select an option",
      "label9": "Relevant Skills for the Position",
      "error9": "You forgot to fill in the field",
      "textButton": "Submit Application",
      "error10": "Accept the policies, terms, and conditions"
    },
    'portugues': {
      "title": "Preencha os seguintes campos",
      "label1": "Nome completo",
      "placeholder1": "Digite seu nome completo",
      "error1": "Você esqueceu de preencher o campo",
      "label2": "Formação Acadêmica",
      "error2": "Selecione uma opção",
      "label3": "Idiomas Falados",
      "error3": "Selecione uma opção",
      "label4": "Envie seu CV * (.PDF)",
      "error4": "Você esqueceu de preencher o campo",
      "label5": "Endereço",
      "placeholder5": "Digite seu endereço",
      "error5": "Você esqueceu de preencher o campo",
      "label6": "Endereço de Email",
      "placeholder6": "Digite seu endereço de email",
      "error6": "Você esqueceu de preencher o campo",
      "label7": "Faixa Salarial",
      "placeholder7": "Mín",
      "placeholder72": "Máx",
      "error7": "Você esqueceu de preencher o campo",
      "error72": "Você esqueceu de preencher o campo",
      "error73": "O valor máximo deve ser maior que o valor mínimo.",
      "label8": "Moeda",
      "error8": "Selecione uma opção",
      "label9": "Habilidades Relevantes para o Cargo",
      "error9": "Você esqueceu de preencher o campo",
      "textButton": "Enviar Solicitação",
      "error10": "Aceite as políticas, termos e condições"
    }
    
  };
  @Output() closeModalEvent = new EventEmitter<void>();
  isModalOpen = false;
  accept_policies: boolean = false;
  modalForm!: FormGroup;
  submitted = false;
  file: any;
  statusLoader = 'Cargando';

  idiomasOptionss = [
    { id: 'Español', name: 'Español' },
    { id: 'Inglés', name: 'Inglés' },
    { id: 'Portugués', name: 'Portugués' },
    { id: 'Francés', name: 'Francés' },
    { id: 'Alemán', name: 'Alemán' },
    { id: 'Otro', name: 'Otro' },
  ];

  selectedIdiomas: number | undefined;
  closeModal() {
    this.closeModalEvent.emit();
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiServices,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.idiomaActual = 'espanol'
  }

  ngOnInit(): void {
    this.modalForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      // numero: ['',Validators.required],
      correoElectronico: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'
          ),
        ],
      ],
      formacionInput: ['', Validators.required],
      Habilidades: ['', Validators.required],
      idiomas: ['', Validators.required],
      rangoSalarialMin: ['', Validators.required],
      rangoSalarialMax: ['', Validators.required],
      moneda: ['', Validators.required],
      fileCV: ['', Validators.required],
    });

    this.traducciones = this.idiomas[this.idiomaActual];
    // this.cambiarIdioma(this.idiomaActual);
    // console.log(`Idioma inicial seleccionado: ${this.idiomaActual}`);
  }

  get fa() {
    return this.modalForm.controls;
  }

  hasInvalidInput(): boolean {
    return this.submitted && this.modalForm.invalid;
  }

  uploadFile(event: any) {
    console.log(event.target.files);
    if (event.target.files.length > 0) {
      let originalFile = event.target.files[0];
      let newFileName = "CV" + originalFile.name.slice(originalFile.name.lastIndexOf('.'));
      this.file = new File([originalFile], newFileName, {type: originalFile.type});
    }
  }

  onModalFormSubmit() {
    this.submitted = true;
    if (this.modalForm.invalid) {
      return;
    }

    this.spinner.show();

    const vacante = JSON.parse(localStorage.getItem('vacant') || '{}');

    const request = new FormData();
    request.append('nombre', this.fa['nombre'].value);
    request.append('correo', this.fa['correoElectronico'].value);
    // request.append("telefono",this.fa["numero"].value);
    request.append('id_vacante', vacante['itemidvacante']);
    request.append('direccion', this.fa['direccion'].value);
    request.append('nivel-educativo', this.fa['formacionInput'].value);
    request.append('idiomas', this.fa['idiomas'].value);
    request.append('salario-minimo', this.fa['rangoSalarialMin'].value);
    request.append('salario-maximo', this.fa['rangoSalarialMax'].value);
    request.append('divisa', this.fa['moneda'].value);
    request.append('habilidades', this.fa['Habilidades'].value);
    request.append('pais', vacante['pais']);
    request.append('cv', this.file);

    this.service.crearCandidato(request).subscribe(
      (response: any) => {
        console.log(response);
        this.statusLoader = 'Envio Exitoso';
        this.spinner.show(undefined, {
          type: 'ball-scale-multiple',
          color: '#45b8ea',
        });
        setTimeout(() => {
          this.spinner.hide();
          this.router.navigate(['/oportunidades-laborales']);
        }, 3000);
        this.modalForm.reset();
      },
      (error) => {
        console.log(error);
        this.statusLoader = 'Ha ocurrido un error intenta más tarde';
        this.spinner.show(undefined, {
          type: 'ball-scale-multiple',
          color: 'red',
        });
        setTimeout(() => {
          this.spinner.hide();
        }, 3000);
      }
    );
    this.statusLoader = 'Cargando';
  }

  onIdiomasChange(event: any) {
    this.selectedIdiomas = event.target.value.map((optionValue: string) =>
      parseInt(optionValue, 10)
    );
  }

  openModalPrivacy() {
    this.submitted = true;
    if (this.modalForm.invalid) {
      return;
    } else {
      this.isModalOpen = true;
    }
  }

  closeModalPrivacy(result: boolean) {
    this.isModalOpen = false;
    if (result) {
      this.onModalFormSubmit();
    } else {
      this.accept_policies = true;
    }
  }

  validateMaxValue() {
    const minValue = this.fa['rangoSalarialMin']?.value;
    const maxValue = this.fa['rangoSalarialMax'];

    if (minValue > maxValue.value) {
      maxValue.setErrors({ maxValueInvalid: true });
    } else {
      maxValue.setErrors(null);
    }
  }

  cambiarIdioma(lang: string) {
    // this.idiomaActual = lang;
    this.traducciones = this.idiomas[lang];
    console.log(`Idioma cambiado a: ${lang}`);
  }
}
