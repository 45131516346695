import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tabla-pais',
  templateUrl: './tabla-pais.component.html',
  styleUrls: ['./tabla-pais.component.css']
})
export class TablaPaisComponent implements OnInit {

  @Input() selectedCountry: string = '';
  @Input() dataTimeToFill: any = [];
  @Input() type: string = '';

  timeToFillCountry: any;
  current_year: number = 0;

  constructor() {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit(): void {
    if(this.type == 'country'){
      this.timeToFillCountry = this.dataTimeToFill["country"].find( (x: any) => decodeURIComponent(x["pais"]) == this.selectedCountry)["report"];
    } else if (this.type == 'cluster'){
      this.timeToFillCountry = this.dataTimeToFill["cluster"].find( (x: any) => decodeURIComponent(x["cluster"]) == this.selectedCountry)["report"]
    } else if (this.type == 'responsable'){
      this.timeToFillCountry = this.dataTimeToFill["responsable"].find( (x: any) => decodeURIComponent(x["responsable"]) == this.selectedCountry)["report"]
    }
  }
  onChanges(changes: SimpleChanges){
    if(changes['selectedCountry']){
      const newCountry =  changes['selectedCountry'].currentValue;
    }
    if(changes['dataTimeToFill']){
      const newDataTime =  changes['dataTimeToFill'].currentValue;
    }
    if(changes['type']){
      const newType =  changes['type'].currentValue;
    }
  }
}
