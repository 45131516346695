<div>
    <div>
      <table style="background-color: white; ">
          <thead>
              <tr>
                  <th id="timeToFillHeader">{{report_selected}} (Global)</th>
                  <th id="" class="padding-headers">Situación Real</th>
                  <th id="enero" class="padding-headers">Enero</th>
                  <th id="febrero" class="padding-headers">Febrero</th>
                  <th id="marzo" class="padding-headers">Marzo</th>
                  <th id="q1" class="padding-headers">1°Q</th>
                  <th id="abril" class="padding-headers">Abril</th>
                  <th id="mayo" class="padding-headers">Mayo</th>
                  <th id="junio" class="padding-headers">Junio</th>
                  <th id="first-semestre" class="padding-headers">1er Semestre 2023</th>
                  <th id="julio" class="padding-headers">Julio</th>
                  <th id="agosto" class="padding-headers">Agosto</th>
                  <th id="septiembre" class="padding-headers">Septiembre</th>
                  <th id="octubre" class="padding-headers">Octubre</th>
                  <th id="noviembre" class="padding-headers">Noviembre</th>
                  <th id="diciembre" class="padding-headers">Diciembre</th>
                  <th id="second-semestre" class="padding-headers">2do Semestre 2022</th>
                  <th id="total-actual" class="padding-headers center">TOTAL ACTUAL</th>
                  <th id="total" class="padding-headers">TOTAL</th>
                  <th id="mex-usa" class="padding-headers">MEX & USA</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td class="n1">N1 (Abiertas)</td>
                  <td class="center">Lorem ipsum dolor</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
              </tr>
              <tr>
                  <td class="n1">N1 (Canceladas)</td>
                  <td class="center">Lorem ipsum dolor</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
              </tr>
              <tr>
                  <td class="n1">N1 (Cerradas)</td>
                  <td class="center">Lorem ipsum dolor</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">74</td>
                  <td class="text-center">43</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
                  <td class="text-center">73</td>
                  <td class="text-center">45</td>
                  <td class="text-center">70</td>
              </tr>
          </tbody>
      </table>
    </div>
    <div>
      <table class="second-table">
          <thead>
              <tr>
                  <th id="timeToFillHeader">{{report_selected}}  2023(1er Semestre)</th>
                  <th id="vacio" class=""></th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td class="">TOTAL ABIERTAS</td>
                  <td class="center">Lorem ipsum dolor</td>
              </tr>
              <tr>
                  <td class="">TOTAL CANCELADAS</td>
                  <td class="center">Lorem ipsum dolor</td>
              </tr>
              <tr>
                  <td class="center ">TOTAL CERRADAS</td>
                  <td class="center">Lorem ipsum dolor</td>
              </tr>
              <tr>
                  <td class="center border-success-rate">Success Rate 2023(1er Semestre)</td>
                  <td class="center border-success-rate">Lorem ipsum dolor</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>