<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
</div>
<div class="container-form">
    <div class="form-data">
        <div class="title">
            <h2 class="text-center">Comparativa Económica</h2>
            <p class="text-center">Para continuar con el proceso sigue las instrucciones.</p>
        </div>
        <div class="row padding-form">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Por favor sube el archivo que se te solicita</h5>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 margin-button">
                <label  class="color-primary mb-2">Sube aquí tu archivo .XLSX<small class="text-danger">*</small></label>
               <!-- cambiar para que acepte archivos excel -->
                <input type="file" class="form-control" id="idArchivo"  title="Ingresa tu archivo" accept=".xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xls, .xml, .xlam, .xla" (change)="onFileSelected($event)" />    
               <div class="text-danger" *ngIf="submitted && !file">
                Este archivo es requerido *
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <button type="button" (click)="submitForm()" class="btn btn-primary btn-form mb-3">Enviar </button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
