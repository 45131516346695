<div class="modal">
    <div class="modal-content">
      <div class="container top-container" [ngClass]="{'top-container-invalid': hasInvalidInput()}">
        <div class="position-button">
          <button class="button-back" (click)="closeModal()">
            <svg  width="35" height="35" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#38ACE7"  stroke-width="2" />
              <path d="M6 6L18 18" stroke="#38ACE7" stroke-width="2" />
            </svg>
          </button>   
        </div>
        <div class="row ">
          <div class="col-sm-12 col-lg-12 col-md-12">
             <p  class="margin-h4"><Strong>{{ traducciones.title }}</Strong></p>
          </div>
      </div>
      <form [formGroup]="modalForm" (ngSubmit)="onModalFormSubmit()" >
        <div class="row">
            <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="form-group mb-3">
                        <label class="color-primary mb-1 margin-labels"><strong>{{ traducciones.label1 }} *</strong></label>
                        <input  type="text" class="form-control" formControlName="nombre" placeholder="{{ traducciones.placeholder1 }}" 
                        [ngClass]="{ 'is-invalid': submitted && fa['nombre']['errors'], 'is-valid': fa['nombre'].valid }"   />
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['nombre']['errors']"
                          class="invalid-feedback">
                          <div class="margin-labels" *ngIf="fa['nombre']['errors']['required']">
                            {{ traducciones.error1 }} *</div>
                        </small>
                      </div>

                      <div class="form-group mb-3">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="color-primary"><strong>{{ traducciones.label2 }} *</strong></label>
                              <select class="custom-select" formControlName="formacionInput" id="FormControlSelectFormacionAcademica" [ngClass]="{'is-invalid': submitted && fa['formacionInput']['errors'], 'is-valid': fa['formacionInput'].valid }">
                                <option>Primaria</option>
                                <option>Secundaria</option>
                                <option>Auxiliares de salud</option>
                                <option>Bachillerato o Preparatoria</option>
                                <option>Curso Posténico de Enfermería</option>
                                <option>Carrera Técnica</option>
                                <option>Licenciatura, Ingeniera y Técnico en superior universitario</option>
                                <option>Especialización en Educación Superior</option>
                                <option>Maestría</option>
                                <option>Doctorado</option>
                                <option>Especialidades Médicas</option>
                                <option>Curso de Alta Especialidad</option>
                              </select>
                              <small class="form-text text-muted danger" *ngIf="submitted && fa['formacionInput']['errors']"
                              class="invalid-feedback">
                                <div *ngIf="fa['formacionInput']['errors']['required']">
                                  {{ traducciones.error2 }} *</div>
                              </small>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label  class="color-primary "><strong>{{ traducciones.label3 }} *</strong></label>
                        <ng-select
                        [ngClass]="{ 'is-invalid': submitted && fa['idiomas']['errors'], 'is-valid': fa['idiomas'].valid }"
                        [items]="idiomasOptionss"
                        bindLabel="name"
                        bindValue="id"
                        [multiple]="true"
                        formControlName="idiomas"
                      ></ng-select>
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['idiomas']['errors']"
                        class="invalid-feedback">
                          <div class="" *ngIf="fa['idiomas']['errors']['required']">
                            {{ traducciones.error3 }} *</div>
                        </small>
                      </div>

                      <div class="form-group mb-3">
                        <label class="color-primary mb-1 margin-labels"><strong>{{ traducciones.label4 }} </strong></label>
                        <input type="file" class="form-control" formControlName="fileCV"  title="Carga tu CV"
                        [ngClass]="{ 'is-invalid': submitted && fa['fileCV']['errors'], 'is-valid': fa['fileCV'].valid }" (change)="uploadFile($event)" accept=".pdf" />
                        <small class="form-text text-muted danger" *ngIf="submitted && fa['fileCV']['errors']" class="invalid-feedback">
                          <div *ngIf="fa['fileCV']['errors']['required']">
                            {{ traducciones.error4 }} *</div>
                        </small>
                      </div>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6">
                <div class="form-group mb-3">
                  <label class="color-primary mb-1 margin-labels"><strong>{{ traducciones.label5 }} *</strong></label>
                  <input  type="text" class="form-control" formControlName="direccion" placeholder="{{traducciones.placeholder5}}" title="Ingresa tu dirección"
                  [ngClass]="{ 'is-invalid': submitted && fa['direccion']['errors'], 'is-valid': fa['direccion'].valid }"   />
                  <small class="form-text text-muted danger margin-labels" *ngIf="submitted && fa['direccion']['errors']"
                    class="invalid-feedback">
                    <div class="margin-labels" *ngIf="fa['direccion']['errors']['required']">
                      {{ traducciones.error5 }} *</div>
                  </small>
                </div>

                <div class="form-group mb-3">
                  <label class="color-primary mb-1 margin-labels"><strong>{{ traducciones.label6 }} *</strong></label>
                  <input type="mail" class="form-control" formControlName="correoElectronico" placeholder="{{traducciones.placeholder6}}" title="Ingresa tu correo electrónico"
                  [ngClass]="{ 'is-invalid': submitted && fa['correoElectronico']['errors'], 'is-valid': fa['correoElectronico'].valid }"   />
                  <small class="form-text text-muted danger" *ngIf="submitted && fa['correoElectronico']['errors']"
                    class="invalid-feedback">
                    <div class="margin-labels" *ngIf="fa['correoElectronico']['errors']['required']">
                      {{ traducciones.error6 }} *</div>
                  </small>
                </div>

                
                <div class="row">
                  <div class=" form-group col-md-12 col-sm-12"><label class="color-primary "><strong>{{ traducciones.label7 }}  *</strong></label></div>
                </div>

                <div class="row mb-3">
                  <div class="form-group col-md-6 col-lg-6 range-max">
                    <input type="number" class="form-control" formControlName="rangoSalarialMin" placeholder="{{traducciones.placeholder7}}" title="Ingreso mínimo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMin']['errors'], 'is-valid': fa['rangoSalarialMin'].valid }" />
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMin']['errors']"
                      class="invalid-feedback">
                      <div class="" *ngIf="fa['rangoSalarialMin']['errors']['required']">
                        {{ traducciones.error7 }} *</div>
                    </small>
                  </div>
                  <div class="form-group col-md-6 col-lg-6">
                    <input type="number" class="form-control" formControlName="rangoSalarialMax" placeholder="{{traducciones.placeholder72}}" title="Ingreso máximo"  [ngClass]="{ 'is-invalid': submitted && fa['rangoSalarialMax']['errors'], 'is-valid': fa['rangoSalarialMax'].valid } " (ngModelChange)="validateMaxValue()"/>
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['rangoSalarialMax']['errors']"
                      class="invalid-feedback">
                      <div class="" *ngIf="fa['rangoSalarialMax']['errors']['required']">
                        {{ traducciones.error72 }} *</div>
                        <div class="" *ngIf="fa['rangoSalarialMax']['errors']['maxValueInvalid']">
                          {{ traducciones.error73 }} 
                        </div>
                    </small>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="form-group col-sm-12 col-md-12 col-lg-12">
                    <label class="color-primary " for="inputMoneda"><strong>{{ traducciones.label8 }} *</strong></label>
                    <select id="inputMoneda" formControlName="moneda" class="custom-select " title="Selecciona el tipo de moneda"  [ngClass]="{ 'is-invalid': submitted && fa['moneda']['errors'], 'is-valid': fa['moneda'].valid }" >
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                      <option value="COP">COP</option>
                      <option value="PEN">PEN</option>
                      <option value="CRC">CRC</option>
                      <option value="PAB">PAB</option>
                      <option value="HNL">HNL</option>
                      <option value="SVC">SVC</option>
                      <option value="NIO">NIO</option>
                      <option value="GTQ">GTQ</option>
                      <option value="DOP">DOP</option>
                      <option value="ARS">ARS</option>
                      <option value="CLP">CLP</option>
                      <option value="UYU">UYU</option>
                      <option value="PYG">PYG</option>
                      <option value="BRL">BRL</option>
                    </select>
                    <small class="form-text text-muted danger" *ngIf="submitted && fa['moneda']['errors']"
                      class="invalid-feedback">
                      <div class="" *ngIf="fa['moneda']['errors']['required']">
                        {{ traducciones.error8 }} *</div>
                    </small>
                  </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group mb-3 gen-space">
              <label class="color-primary margin-labels" for="exampleFormControlSkills"><strong>{{ traducciones.label9 }} *</strong></label>
              <textarea class="form-control" formControlName="Habilidades" id="exampleFormControlSkills" rows="2"  [ngClass]="{ 'is-invalid': submitted && fa['Habilidades']['errors'], 'is-valid': fa['Habilidades'].valid }" ></textarea>
              <small class="form-text text-muted danger" *ngIf="submitted && fa['Habilidades']['errors']"
                class="invalid-feedback">
                <div class="margin-labels" *ngIf="fa['Habilidades']['errors']['required']">
                  {{ traducciones.error9 }} *</div>
              </small>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="btn-section">
                <button type="button" (click)="openModalPrivacy()" class="btn btn-primary btn-login mb-3">
                  {{ traducciones.textButton }}  
                </button>
                <app-privacy-policy-modal *ngIf="isModalOpen" (closeModalEvent)="closeModalPrivacy($event)"></app-privacy-policy-modal>
                <small class="form-text text-muted danger" *ngIf="accept_policies === true">
                <p class="fw-bold" style="color: red;"> {{ traducciones.error10 }} *</p>
              </small>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  </div>
  <ngx-spinner type="ball-scale-multiple"><p style="font-size: 20px; color: white">{{statusLoader}}</p></ngx-spinner>
