import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-condi',
  templateUrl: './termos-condi.component.html',
  styleUrls: ['./termos-condi.component.css']
})
export class TermosCondiComponent implements OnInit {

  idiomaActual: string = 'portugues';
  constructor() { }

  ngOnInit(): void {
  }

}
