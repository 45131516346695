<div class="toolbar" role="banner">
  <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />
</div>

<div class="content" role="main">
  <div class="main-container">
    <div class="form-container mt-4">
      <h1 class="text-center mb-4">Política de Privacidade Global</h1>
      <hr class="mb-4">
      <h5 class="mb-3">AVISO DE PRIVACIDADE GLOBAL</h5>
      <p>As empresas que fazem parte do grupo Genomma Lab (doravante “Genomma Lab”) serão responsáveis pelo tratamento
        de seus dados pessoais conforme o caso e informam o seguinte:</p>
      <h5 class="mb-3">Quais dados pessoais são tratados?</h5>
      <p>Genomma Lab, para realizar os objetivos descritos neste aviso de privacidade, trata os seguintes dados
        pessoais:</p>
      <ul>
        <li>
          <h5>Dados pessoais que você nos fornece diretamente: </h5>
          <p>Coletamos dados sobre como você usa nossos serviços e produtos, bem como os tipos de conteúdo que você
            visualiza ou com os quais interage, ou a frequência e duração de suas atividades. Também coletamos seu nome,
            sobrenome, e-mail, data de nascimento, número de telefone, endereço e gênero quando você entra em contato
            conosco.</p>
        </li>
        <li>
          <h5>Dados pessoais que coletamos automaticamente: </h5>
          <p>Recebemos e armazenamos certos tipos de dados pessoais sempre que você interage conosco online. Utilizamos
            cookies e tecnologias de rastreamento para obter dados pessoais quando seu navegador acessa nossos sites por
            meio de anúncios ou outro conteúdo fornecido pela Genomma Lab.</p>
        </li>
      </ul>
      <h5 class="mb-3">Para que fins utilizaremos seus dados pessoais?</h5>
      <p>Os dados pessoais que coletamos de você serão utilizados para os seguintes fins que são necessários para o
        serviço que você solicita:</p>
      <h5 class="mb-3">Finalidades Primárias</h5>
      <ul>
        <li>
          <p>Orçamento, acompanhamento e avaliação dos bens e/ou serviços que fornecemos.</p>
        </li>
        <li>
          <p>Comercialização, envio e entrega de produtos da Genomma Lab.</p>
        </li>
        <li>
          <p>Fins de identificação e contato por telefone ou por e-mail.</p>
        </li>
        <li>
          <p>Realizar as atividades necessárias para o desenvolvimento e cumprimento das obrigações que se originem e
            derivem da relação contratual e/ou comercial que você tenha com a Genomma Lab.</p>
        </li>
        <li>
          <p>Atendimento de dúvidas e sugestões. </p>
        </li>
      </ul>
      <p>De forma adicional, e com seu consentimento, utilizaremos suas informações pessoais para os seguintes fins
        secundários que não são necessários para o serviço e/ou produto solicitado, mas que nos permitem e facilitam
        proporcionar-lhe uma melhor atenção:</p>

      <h5 class="mb-3">Finalidades Secundárias</h5>
      <ul>
        <li>
          <p>
            Análise, elaboração de estatísticas e relatórios internos.
          </p>
        </li>
        <li>
          <p>
            Fins de marketing, publicitários e de prospecção comercial relacionados a produtos e serviços que podem ser
            realizados pela Genomma Lab ou por terceiros com os quais a Genomma Lab tenha celebrado acordos, contratos e
            uma relação comercial.
          </p>
        </li>
      </ul>
      <p>Informamos que o mecanismo que o responsável implementou para que você possa manifestar sua negativa para o
        tratamento de seus dados pessoais em relação às finalidades secundárias é através do envio de um e-mail para o
        endereço datospersonales@genommalab.com, pelo qual você manifestará essa negativa e identificará a ou as
        finalidades secundárias às quais deseja manifestar essa negativa.
        Na coleta e tratamento dos dados pessoais que você nos fornece, cumprimos todos os princípios estabelecidos nas
        diversas regulamentações aplicáveis: Licitude, qualidade, consentimento, informação, lealdade,
        proporcionalidade, responsabilidade, segurança, veracidade e exatidão, etc.</p>
      <h5 class="mb-3">Segurança da Informação</h5>
      <p>Seus dados pessoais serão armazenados em um banco de dados de propriedade da Genomma Lab e serão tratados de
        forma confidencial e com as devidas medidas de segurança que serão detalhadas posteriormente. As operações que a
        Genomma Lab realizará sobre seus dados incluem sua coleta, uso, análise, armazenamento, perfilamento, supressão,
        transferência, transmissão e, em geral, seu processamento.</p>
      <h5 class="mb-3">Transferências de dados</h5>
      <h5 class="mb-3">Com quem compartilhamos suas informações pessoais e para que fins?</h5>
      <p>Informamos que seus dados pessoais são compartilhados dentro e fora do país com as seguintes pessoas, empresas,
        organizações e autoridades distintas de nós, para os seguintes fins:</p>
      <h5 class="mb-3">Genomma Lab poderá transferir seus dados nos seguintes casos:</h5>
      <ul>
        <li>
          <p>Quando se tratar de empresas nacionais ou internacionais do mesmo grupo, como subsidiárias, filiais,
            afiliadas, controladas ou controladoras sob o controle comum do responsável ou para uma sociedade matriz ou
            para qualquer sociedade do mesmo grupo do responsável que opere sob os mesmos processos e políticas
            internas.</p>
        </li>
        <li>
          <p>Com um ou vários terceiros, com os quais tenhamos previamente celebrado contratos com cláusula de
            confidencialidade e de proteção de dados pessoais e que prestem serviços à Genomma Lab que são
            complementares e/ou relacionados aos serviços e/ou produtos que você solicitou e/ou cujos serviços prestados
            à Companhia são necessários para permitir que a Genomma Lab possa fornecer a você os serviços e/ou produtos
            que solicitou.</p>
        </li>
        <li>
          <p>Com um ou vários terceiros como fornecedores de serviços selecionados para gerenciar ou administrar tanto
            este portal quanto os dados pessoais que são coletados através deste.</p>
        </li>
        <li>
          <p>Quando a transferência for necessária ou legalmente exigida por alguma autoridade para salvaguardar um
            interesse público, ou para a procuração ou administração da justiça.</p>
        </li>
        <li>
          <p>Quando a transferência for necessária para a manutenção ou cumprimento de uma relação jurídica entre o
            responsável e o titular.</p>
        </li>
      </ul>

      <h5 class="mb-3">Como você pode acessar, retificar ou cancelar seus dados pessoais, ou se opor ao seu uso?</h5>
      <p>Você tem o direito de acessar seus dados pessoais que possuímos e aos detalhes do tratamento que damos aos
        mesmos, assim como retificá-los caso sejam inexatos ou estejam incompletos; cancelá-los quando considerar que
        não são necessários para alguma das finalidades indicadas no presente aviso, ou tenha finalizado a relação
        contratual ou de serviço, ou ainda, opor-se ao tratamento dos mesmos para fins específicos.</p>
      <p>Você poderá solicitar à Genomma Lab o exercício de seus direitos e a negativa para o tratamento das finalidades
        não necessárias através do seguinte procedimento:</p>
      <p>1. Enviar um e-mail para o endereço datospersonales@genommalab.com, indicando seu nome completo, documentos que
        comprovem sua identidade, ou então, a representação legal do titular, descrição clara e precisa dos dados
        pessoais sobre os quais se deseja exercer algum de seus direitos, bem como qualquer outro elemento que facilite
        a localização dos dados pessoais.</p>
      <p>2. No momento de receber sua solicitação, a Genomma Lab enviará um recibo de confirmação com a data de
        recebimento correspondente. Caso sua solicitação não cumpra com os requisitos estabelecidos no ponto anterior, a
        Genomma Lab solicitará os elementos ou documentos necessários para que corrija sua solicitação em um prazo de 10
        (dez) dias úteis, contados a partir da data de recebimento de sua solicitação. Você terá 20 (vinte) dias úteis
        para corrigir o necessário, caso não o faça, sua solicitação será considerada como não apresentada.</p>
      <p>3. Sua solicitação será respondida quanto à sua procedência em um prazo não superior a 40 (quarenta) dias úteis
        contados a partir da data de recebimento estabelecida no recibo de confirmação. Caso sua solicitação seja
        procedente, a Genomma Lab terá um prazo de 30 (trinta) dias úteis para torná-la efetiva a partir da notificação
        de sua procedência. Quando as circunstâncias justificarem, os prazos anteriores poderão ser ampliados por uma
        única vez por um período igual.</p>
      <h5 class="mb-3">Como você pode revogar seu consentimento para o uso de seus dados pessoais?</h5>
      <p>Você pode revogar o consentimento que, se for o caso, nos tenha concedido para o tratamento de seus dados
        pessoais. No entanto, é importante que tenha em mente que nem sempre poderemos atender sua solicitação ou
        encerrar o uso de forma imediata, pois é possível que, por alguma obrigação legal, precisemos continuar tratando
        seus dados pessoais. Além disso, você deve considerar que, para certos fins, a revogação de seu consentimento
        implicará que não possamos continuar prestando o serviço solicitado, ou a conclusão de sua relação conosco.
        Toda solicitação para limitar ou revogar o uso ou divulgação de seus dados pessoais deverá ser realizada através
        do mesmo procedimento e termos estabelecidos para exercer seus direitos, utilizando o endereço de e-mail:
        datospersonales@genommalab.com.</p>
      <h5 class="mb-3">Dados de Contato</h5>
      <p>Se tiver alguma pergunta sobre este Aviso, sobre o uso que damos à sua informação pessoal ou quiser exercer
        algum de seus direitos, pode nos contatar através do seguinte e-mail: datospersonales@genommalab.com.</p>
      <h5 class="mb-3">O uso de tecnologias de rastreamento em nosso portal de Internet</h5>
      <p>Informamos que em nossa página de Internet utilizamos cookies, web beacons e outras tecnologias através das
        quais é possível monitorar seu comportamento como usuário de Internet, assim como oferecer um melhor serviço e
        experiência de usuário ao navegar em nossa página.
        Os dados pessoais que obtemos dessas tecnologias de rastreamento são os seguintes: horário de navegação, tempo
        de navegação em nossa página de internet, seções consultadas, e páginas de internet acessadas antes da nossa,
        mesmos que utilizamos para melhorar sua experiência de usuário em nossa página web, aumentar a segurança desta
        página, medir o uso de nossos serviços e fornecer publicidade. Além disso, informamos que este portal tem links
        para outros sites externos, cujo conteúdo e políticas de privacidade não são responsabilidade da Genomma Lab,
        portanto, o usuário, se for o caso, ficará sujeito às políticas de privacidade daqueles outros sites, sem
        qualquer responsabilidade da Genomma Lab.
        Essas tecnologias podem ser desativadas através da configuração de seu navegador e de outras ferramentas
        disponíveis em seus sistemas de computação e/ou dispositivos móveis. No entanto, isso pode impedir que nossos
        sites ou serviços funcionem corretamente.</p>
      <h5 class="mb-3">Como protegemos seus dados?</h5>
      <p>A Genomma Lab adotou medidas de segurança razoáveis para proteger suas informações e impedir o acesso não
        autorizado aos seus dados ou qualquer modificação, divulgação ou destruição não autorizada dos mesmos. As
        informações coletadas pela Genomma Lab serão mantidas de maneira estritamente confidencial. O acesso aos seus
        dados pessoais está restrito àqueles funcionários, contratantes e representantes da Genomma Lab que precisam
        conhecer tais dados para desempenhar suas funções e desenvolver ou melhorar nossos serviços. A Genomma Lab exige
        de seus fornecedores os mesmos padrões de confidencialidade e não permite o acesso a essas informações a
        terceiros alheios à empresa.</p>
      <h5 class="mb-3">Por quanto tempo conservamos seus dados pessoais?</h5>
      <p>Informamos que a Genomma Lab conservará seus dados pessoais pelo tempo em que continuem sendo adequados,
        proporcionais e necessários para o âmbito e as finalidades para as quais foram solicitados, ou durante o tempo
        estabelecido pela normativa aplicável. Revisaremos ativamente os dados pessoais que possuímos e os eliminaremos
        de forma segura quando não houver mais uma necessidade legal, comercial ou dos consumidores para que se
        conservem.</p>
      <h5 class="mb-3">Como você pode conhecer as mudanças a este aviso de privacidade?</h5>
      <p>O presente aviso de privacidade pode sofrer modificações, mudanças ou atualizações derivadas de novos
        requisitos legais; de nossas próprias necessidades pelos produtos ou serviços que oferecemos; de nossas práticas
        de privacidade; de mudanças em nosso modelo de negócios, ou por outras causas.
        Nos comprometemos a mantê-lo informado sobre as mudanças que este aviso de privacidade possa sofrer, através de
        nosso site e/ou aplicativos administrados pela Genomma Lab. Para sua segurança, consulte a qualquer momento que
        desejar o conteúdo deste aviso de privacidade em nosso portal.
        O envio que você fizer de seus dados pessoais através do site da Genomma Lab será entendido como seu
        consentimento para que seus dados pessoais sejam tratados e transferidos conforme indicado no presente Aviso de
        Privacidade.</p>
      <h5 class="mb-3">Consentimento e aceitação do Aviso de Privacidade</h5>
      <p>Visto que o presente aviso foi disponibilizado a você através de nosso site, entende-se que você consente com o
        tratamento de seus dados pessoais de acordo com o estabelecido no mesmo. Você poderá revogar esse consentimento
        de acordo com o procedimento estabelecido no presente aviso.</p>
    </div>
  </div>

  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39"
      d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
      transform="translate(142.69 -634.312)" fill="#eee" />
  </svg>

</div>

<div>
  <app-footer [idioma]="idiomaActual"></app-footer>
</div>
