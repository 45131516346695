<div class="toolbar" role="banner">
    <img style="padding-left: 3rem;" width="200" src="assets/genommalab-logo.svg" />   
</div>


<div class="content" role="main">
    <div class="main-container">
      <div class="form-container mt-3">
        <h2 class="text-center mb-3">TERMOS E CONDIÇÕES</h2>
            <hr class="mb-4">
            <p>A rede de sites (coletivamente, os "Sites" ou "Site") operados pelas empresas que fazem parte do Genomma Lab (doravante "Genomma Lab" ou "nós") é composta por vários sites e páginas da web. Os termos, condições e avisos aqui contidos (coletivamente, os "Termos") se aplicam a todos os sites do Genomma Lab.</p>
            <p>O acesso e uso do Site está sujeito aos termos e condições descritos neste documento e a todas as leis e regulamentos aplicáveis, incluindo aqueles que regulam os direitos autorais, marcas, patentes ou qualquer outro direito de propriedade intelectual em qualquer um dos países onde o Genomma Lab opera. AO ACESSAR O SITE, VOCÊ CONFIRMA E CONCORDA EM ACEITAR, SEM LIMITAÇÃO OU QUALIFICAÇÃO, A TOTALIDADE DESTES TERMOS E CONDIÇÕES. Genomma Lab reserva-se o direito de alterar estes termos e condições de tempos em tempos atualizando esta publicação. Se você não concorda com estes Termos, não use este Site nem qualquer outro Site do Genomma Lab.</p>
            <p>O uso dos Sites do Genomma Lab também está sujeito ao Aviso de Privacidade do Genomma Lab, que está disponível em https://www.genommalab.com/politicas.</p>
            <p>Salvo indicação em contrário, o Site é apenas para seu uso pessoal e não comercial. Você não pode modificar, copiar, distribuir, comunicar publicamente, transmitir, exibir, executar, reproduzir, publicar, licenciar, criar trabalhos derivados, transferir ou vender qualquer um dos conteúdos ou comunicações contidos ou exibidos no Site ("Conteúdo"), incluindo, mas não se limitando a, textos, gráficos, fotografias, imagens, imagens em movimento, som, ilustrações, informações, software, produtos ou serviços e seus respectivos arranjos, exceto conforme expressamente permitido neste documento. O Conteúdo é propriedade do Genomma Lab e é protegido por direitos autorais, marcas, segredos comerciais e outros direitos decorrentes das leis vigentes sobre propriedade intelectual. Você reconhece que qualquer uso não autorizado de qualquer Conteúdo pode nos causar um dano irreparável e concorda que, em caso de tal uso não autorizado, teremos o direito de tomar as medidas legais apropriadas, além de qualquer outro recurso disponível de acordo com as leis e/ou regulamentos aplicáveis. Qualquer outro uso do Conteúdo, incluindo modificação, reprodução, comunicação pública, distribuição, publicação, exibição ou transmissão de qualquer Conteúdo, sem nossa permissão prévia por escrito, é estritamente proibido.</p>
            <p>A informação publicada neste Site sobre condições de saúde não deve ser considerada como diagnóstico final ou opinião profissional sobre qualquer mal-estar ou condição. O conteúdo do Site é meramente informativo, pelo que o Genomma Lab recomenda que qualquer condição ou dúvida derivada das informações apresentadas seja atendida diretamente por um especialista de saúde. Caso qualquer pessoa use a informação publicada como diagnóstico final ou opinião profissional, o Genomma Lab se isenta de qualquer responsabilidade presente ou futura decorrente do uso indevido das informações contidas no Site.</p>
            <p>O SITE E AS INFORMAÇÕES CONTIDAS NELE SÃO FORNECIDOS "TAL COMO ESTÃO", SEM GARANTIAS DE NENHUM TIPO, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO A, GARANTIAS DE TÍTULO OU PROPRIEDADE, OU GARANTIAS IMPLÍCITAS DE COMERCIABILIDADE OU ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO, EXCETO PELAS GARANTIAS QUE SÃO IMPOSTAS E INCAPAZES DE SER EXCLUÍDAS, RESTRINGIDAS OU MODIFICADAS DE ACORDO COM AS LEIS E/OU REGULAMENTOS APLICÁVEIS A ESTES TERMOS.</p>
            <p>NA MEDIDA EM QUE AS LEIS E/OU REGULAMENTOS APLICÁVEIS PERMITIREM, NEM GENOMMA LAB NEM QUALQUER DE SUAS ENTIDADES CONTROLADORAS, SUBSIDIÁRIAS, DIVISÕES, AFILIADOS, AGENTES, REPRESENTANTES OU LICENCIANTES TERÃO QUALQUER RESPONSABILIDADE PERANTE VOCÊ OU QUALQUER OUTRA PESSOA POR PERDAS OU DANOS OU POR QUAISQUER DANOS DIRETOS, INDIRETOS, INCIDENTAIS, CONSEQUENCIAIS, ESPECIAIS, PUNITIVOS OU DANOS SEMELHANTES QUE SURJAM, OU DECORRAM, DO SEU ACESSO OU USO, OU DA SUA INCAPACIDADE DE ACESSAR OU USAR, ESTE SITE.</p>
            <p>As informações comunicadas nos Sites podem conter informações de terceiros e/ou links para páginas externas de terceiros. Genomma Lab não é responsável, de forma alguma, por qualquer conteúdo de terceiros que seja publicado, carregado, criado, compartilhado ou que, de qualquer outra forma, exista em qualquer presença na web do Genomma Lab a que se aplicam estes Termos. Genomma Lab não tem qualquer responsabilidade com você ou com qualquer outra parte pela existência ou remoção de qualquer conteúdo de terceiros. O conteúdo de terceiros não reflete as opiniões, crenças, declarações ou ações do Genomma Lab. Genomma Lab rejeita e exclui especificamente qualquer responsabilidade que possa surgir do mencionado anteriormente. Além disso e sem limitar o anterior, as informações apresentadas em tais páginas de terceiros são de responsabilidade dos titulares das mesmas. Genomma Lab isenta-se de qualquer responsabilidade pelas informações publicadas em nomes de domínio e/ou páginas da web de terceiros.</p>
            <p>Você reconhece: (i) que é tecnicamente impossível fornecer o Site livre de qualquer defeito e que o Genomma Lab não pode se comprometer a fazê-lo; (ii) que os defeitos podem tornar o Site temporariamente indisponível; e (iii) que o funcionamento do Site pode ser afetado por eventos e/ou questões que o Genomma Lab não controla, como, por exemplo, os meios de transmissão e comunicação entre você e o Genomma Lab e entre o Genomma Lab e outras redes, pelo que o Genomma Lab se isenta pela interrupção nos serviços, pelo atraso na operação ou transmissão da informação, por possíveis vírus de computador, perda de dados ou qualquer outra falha relacionada ao funcionamento do Site.</p>
            <p>Genomma Lab disponibiliza o nosso aviso de privacidade no site https://www.genommalab.com/politicas, por isso é recomendável lê-lo na íntegra antes de usar este ou qualquer outro Site e/ou qualquer um de nossos serviços via internet ou através de aplicativos informáticos, uma vez que o referido Aviso de Privacidade regula o nosso tratamento de qualquer informação pessoal, incluindo os dados pessoais que nos fornecer. Todos os usuários deste Site devem considerar que certas informações, declarações, dados e conteúdo fornecidos voluntariamente ao Genomma Lab poderiam, ou muito provavelmente, ser utilizados para identificar os titulares de tais dados ou revelar seu sexo, origem étnica, nacionalidade, idade ou outros dados pessoais.</p>
            <p>Estes Termos e qualquer disputa ou controvérsia que surja serão regidos e interpretados de acordo com as leis e os tribunais da localidade aplicável, renunciando a qualquer outra jurisdição que possa corresponder às partes por razões de seu domicílio ou qualquer outro motivo.</p>
          </div>
    </div>
  
    <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
      <path id="Path_39" data-name="Path 39"
        d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
        transform="translate(142.69 -634.312)" fill="#eee" />
    </svg>
  
  </div>

<div>
    <app-footer [idioma]="idiomaActual"></app-footer>
</div>