import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnChanges  {

  @Input() idioma: string = 'espanol';
  traducciones: any = {};
  idiomas: any = {
    'espanol': {
      'footerText1': ' Somos una familia que vive haciendo conexiones. Unimos a las mentes que crean innovación, con personas de verdad que la necesitan. Unimos a quienes distribuyen bienestar, con la gente que lo espera. Hay quien lo llama cadena de suministro:',
      'footerText2': 'NOSOTROS LE LLAMAMOS COMPROMISO.',
      'TextLink1': 'Políticas de privacidad',
      'UrlLink1': '/politicas',
      'TextLink2': 'Términos y Condiciones',
      'UrlLink2': '/aviso-legal'
    },
    'ingles': {
      'footerText1': 'We are a family that lives making connections. We unite the minds that create innovation, with real people who need it. We unite those who distribute well-being, with the people who expect it. Some people call it a supply chain:',
      'footerText2': 'WE CALL IT COMMITMENT',
      'TextLink1': 'Privacy Policy',
      'UrlLink1': '/privacy-policy',
      'TextLink2': 'Terms and Conditions',
      'UrlLink2': '/terms-conditions'
    },
    'portugues': {
      'footerText1': 'Somos uma família que vive fazendo conexões. Unimos as mentes que criam inovação, com pessoas reais que precisam dela. Unimos quem distribui bem-estar, com quem o espera. Algumas pessoas chamam isso de cadeia de suprimentos:',
      'footerText2': 'CHAMAMOS ISSO DE COMPROMISSO',
      'TextLink1': 'Política de Privacidade',
      'UrlLink1': '/politicas-po',
      'TextLink2': 'Termos e Condições',
      'UrlLink2': '/terminos-po'
    }
  };
  // @Input() translate: boolean | undefined= undefined;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['idioma']) {
      this.actualizarTraducciones();
    }
  }


  actualizarTraducciones() {
    this.traducciones = this.idiomas[this.idioma];
    console.log(`Idioma en el footer cambiado a: ${this.idioma}`);
  }

}
