import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm, AbstractControl } from '@angular/forms';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-role-profile',
  templateUrl: './role-profile.component.html',
  styleUrls: ['./role-profile.component.css']
})
export class RoleProfileComponent implements OnInit {

  loginForm!: FormGroup;
  openMenu: boolean = false;
  submitted = false;
  files: File[] = [];
  selectedFiles: File[] = [];
  disableButton = false;
  msgSuccess = '';
  statusLoader = 'Cargando'
  imageSelected: boolean = false;
  countryToCurrency: {[key: string]: string} = {
    '1': 'MXN',
    '2': 'USD',
    '3': 'COP',
    '4': 'PEN',
    '5': 'CRC',
    '6': 'PAB',
    '7': 'HNL',
    '8': 'SVC',
    '9': 'NIO',
    '10': 'GTQ',
    '11': 'DOP',
    '12': 'ARS',
    '13': 'CLP',
    '14': 'UYU',
    '15': 'PYG',
    '16': 'BRL',
  }


  constructor(private formBuilder: FormBuilder, private apiService: ApiServices, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      nombreVacante: ['', Validators.required],
      jefeDirecto: ['', Validators.required],
      rangoSalarialMin:['', Validators.required],
      rangoSalarialMax: ['', Validators.required],
      area: ['', Validators.required],
      nivel: ['', Validators.required],
      tipoVacante: ['', Validators.required],
      ubicacion: ['', Validators.required],
      FormacionAcademica: ['', Validators.required],
      descripcionVacante:['', Validators.required],
      selectedPais:['', Validators.required],
      experiencia: ['', Validators.required],
      moneda: ['', Validators.required],
      esquemaTrabajo:['', Validators.required],
    });
  }
  get fa() {
    return this.loginForm.controls;
  }


  onLoginFormSubmit() {
    this.submitted = true;
    this.disableButton = true;    
    if (this.loginForm.invalid) {
      console.log("error")
      return;
    }
    this.spinner.show();

    const formData = new FormData();
    formData.append('nombreVacante', this.fa["nombreVacante"].value);
    formData.append('jefeDirecto', this.fa["jefeDirecto"].value);
    formData.append('rangoSalarialMin',this.fa["rangoSalarialMin"].value);
    formData.append('rangoSalarialMax',this.fa["rangoSalarialMax"].value);
    formData.append('area', this.fa["area"].value);
    formData.append('nivel', this.fa["nivel"].value);
    formData.append('tipoVacante', this.fa["tipoVacante"].value);
    formData.append('ubicacion', this.fa["ubicacion"].value);
    formData.append('descripcionVacante',this.fa["descripcionVacante"].value);
    formData.append('selectedPais',this.fa["selectedPais"].value);
    formData.append('experiencia', this.fa["experiencia"].value);
    formData.append('moneda', this.fa["moneda"].value);
    formData.append('FormacionAcademica', this.fa['FormacionAcademica'].value)
    formData.append('esquema-trabajo', this.fa['esquemaTrabajo'].value)
    
    if (this.files.length > 0) {
      formData.append('imagen', this.files[0]);
    }
  
    this.apiService.postVacants(formData).subscribe(
      
      (response:any) => {
        if (response.ok){
          console.log("Vacancy Successfuly Registered:", response.message);
          this.disableButton = false;
          this.submitted = false;
          this.statusLoader = 'Envio Exitoso';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: '#45b8ea',
          });
          this.loginForm.reset();
        } else {
          console.log("An ocurred error", response.Error);
          this.disableButton = false;
          this.submitted = false;
          this.statusLoader = 'Ha ocurrido un error intenta más tarde';
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 2500);
        
      },
      (error: HttpErrorResponse) => {
        this.statusLoader = "Error al procesar la petición"
        if (error.status == 422 || error.status == 403 || error.status == 401) {
          this.statusLoader = "Token Invalido o Expirado"
        }
        this.spinner.show(undefined, {
          type: 'ball-scale-multiple',
          color: 'red',
        });
        setTimeout(() => {
          this.spinner.hide();
          if (error.status == 422 || error.status == 403 || error.status == 401) {
            localStorage.clear()
            this.router.navigate(['/login']);
          }
        }, 2100);
      }
    );
    this.statusLoader = 'Cargando';
  }
  

  onSelectedCountryChanged(){
    const selectedCountry = this.fa['selectedPais'].value;
    const selectedCorrency = this.countryToCurrency[selectedCountry];

    if (selectedCorrency){
      this.fa["moneda"].setValue(selectedCorrency);
    } else {
      this.fa["moneda"].setValue('');
    }
  
  }

  validateMaxValue() {
    const minValue = this.fa['rangoSalarialMin']?.value;
    const maxValue = this.fa['rangoSalarialMax'];

    if (minValue > maxValue.value) {
      maxValue.setErrors({ maxValueInvalid: true });
    } else {
      maxValue.setErrors(null);
    }
  }
  
  onSelect(event:any) {
  console.log(event);
  this.files = [];
  this.files.push(...event.addedFiles);
  this.imageSelected = true;
  }

  onRemove(event:any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.imageSelected = false;
  }

  cerrarSesion(){
    localStorage.clear()
    this.router.navigate(['/login']);
  }

}