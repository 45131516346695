import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-timetofill-two',
  templateUrl: './table-timetofill-two.component.html',
  styleUrls: ['./table-timetofill-two.component.css']
})
export class TableTimetofillTwoComponent implements OnInit {
  @Input() selectedCountry: string = '';
  constructor() { }

  ngOnInit(): void {
  }
  onChanges(changes: SimpleChanges){
    if(changes['selectedCountry']){
      const newCountry =  changes['selectedCountry'].currentValue;
    }
  }
}
