import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-formulario-head-hunting',
  templateUrl: './formulario-head-hunting.component.html',
  styleUrls: ['./formulario-head-hunting.component.css']
})
export class FormularioHeadHuntingComponent implements OnInit {
  isModalOpen = false;
  accept_policies: boolean = false;
  modalForm!: FormGroup;
  submitted = false;
  statusLoader = 'Cargando'
  vacante: any = {
    "id": null,
    "pais": null,
    "vacante": null
  }
  file: any;
  idiomasOptions = [
    { id: "Español", name: 'Español' },
    { id: "Inglés", name: 'Inglés' },
    { id: "Portugués", name: 'Portugués' },
    { id: "Francés", name: 'Francés' },
    { id: "Alemán", name: 'Alemán' },
    { id: "Otro", name: 'Otro' },
  ];
  
  selectedIdiomas: number | undefined;
  closeModalEvent: any;
  
  constructor(private formBuilder: FormBuilder, private router: Router, private service: ApiServices, private spinner: NgxSpinnerService) { 
    var url = window.location.href;

    try {
      this.vacante["id"] = url.split("?id=")[1].split("&")[0];
      this.vacante["pais"] = url.split("&pais=")[1].split("&")[0];
      this.vacante["vacante"] = decodeURIComponent(url.split("&vacante=")[1]);
    }
    catch(e){
      console.log(e)
    }

    if(!this.vacante["id"] && !this.vacante["pais"]){
      this.router.navigate(['/oportunidades-laborales']);
    }
  }

  ngOnInit(): void {  
    this.modalForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      // numero: ['', [Validators.required, this.phoneNumberValidator]],
      correoElectronico: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      formacionInput: ['', Validators.required],
      Habilidades: ['', Validators.required],
      idiomas: ['', Validators.required],
      rangoSalarialMin: ['', Validators.required],
      rangoSalarialMax: ['', Validators.required],
      moneda: ['', Validators.required],
      fileCV: ['', Validators.required],
      isActiveToNotyfy: ['', Validators.required]

    });
  }
  get fa() {
    return this.modalForm.controls;
  }

  uploadFile(event: any){
    console.log(event.target.files);
    if(event.target.files.length > 0){
      this.file = event.target.files[0];
    }
  }
 
  blockLetras(e:any){
    // Permitir la tecla para borrar
    if (e.key == 'Backspace') {return true;}

    // Permitir flecha izquierda
    if (e.key == 'ArrowLeft') {return true;}

    // Permitir flecha derecha
    if (e.key == 'ArrowRight') {return true;}

    // Bloquear tecla de espacio
    if (e.key == ' ') {return false;}

    // Bloquear tecla si no es un numero
    if (isNaN(e.key)) {return false;}
    return true;
  }

  phoneNumberValidator(control:any) {
    const value = control.value; 
    console.log(value)
    if (value === null || value === undefined || value === '') {
      return null;     
    }  

    const phoneNumber = value.toString().replace(/\D/g, '');   

    if (phoneNumber.length === 10) {
      return null;  
    } 
    else {
      return { invalidPhoneNumber: true };    
    }  
  }
  
  onModalFormSubmit(){
        
        // this.submitted = true;
        // if (this.modalForm.invalid) {
        //   return;
        // }

    this.spinner.show();
    const request = new FormData();
    request.append("nombre",this.fa["nombre"].value);
    request.append("correo",this.fa["correoElectronico"].value);
    // request.append("telefono",this.fa["numero"].value);
    request.append("id_vacante",this.vacante["id"]);
    request.append("direccion",this.fa["direccion"].value);
    request.append("nivel-educativo",this.fa["formacionInput"].value);
    request.append("idiomas",this.fa["idiomas"].value);
    request.append("salario-minimo",this.fa["rangoSalarialMin"].value);
    request.append("salario-maximo",this.fa["rangoSalarialMax"].value);
    request.append("divisa",this.fa["moneda"].value);
    request.append("habilidades",this.fa["Habilidades"].value);
    request.append("pais", decodeURIComponent(this.vacante["pais"]));
    request.append("cv", this.file);
    request.append('isActiveToNotyfy', this.fa['isActiveToNotyfy'].value);

    this.service.crearCandidato(request).subscribe(
      (response: any)=>{
        console.log(response);
        this.statusLoader = 'Envio Exitoso';
        this.spinner.show(undefined, { 
          type: 'ball-scale-multiple', 
          color: '#45b8ea',
        });
        setTimeout(() => {
          this.spinner.hide();
          window.location.reload()
        }, 3000);
        this.modalForm.reset();
      },
      error=>{
        console.log(error);
        this.statusLoader = 'Ha ocurrido un error intenta más tarde';
        this.spinner.show(undefined, { 
          type: 'ball-scale-multiple', 
          color: 'red', 
        });
        setTimeout(() => {
          this.spinner.hide();
        }, 3000);
      }
    );
    this.statusLoader = 'Cargando';
  }

  onIdiomasChange(event: any) {
    this.selectedIdiomas = event.target.value.map((optionValue: string) => parseInt(optionValue, 10));
  }

  openModal() {
    this.submitted = true;
    if (this.modalForm.invalid) {
      return;
    }else{
      this.isModalOpen = true;
    }
  }

  closeModal(result: boolean) {
    this.isModalOpen = false;
    if (result) {
      this.onModalFormSubmit()
    }else{
      this.accept_policies = true;
    }
    
  }
}
