import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-privacidad',
  templateUrl: './politicas-privacidad.component.html',
  styleUrls: ['./politicas-privacidad.component.css']
})
export class PoliticasPrivacidadComponent implements OnInit {

  idiomaActual: string = 'espanol';
  constructor() { }

  ngOnInit(): void {
  }

}
