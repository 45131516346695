<div class="row espacio">
    <div class="col-12">
        <div class="row position-button">
            <button type="button" (click)="exportToExcelReport()" class="btn btn-historico">Exportar a Excel</button>
        </div>
        <div class="form-group">
            <label class="color-primary" for="select">Selecciona un país</label>
            <select class="custom-select" id="FormControlSelectPais" (change)="filterByCountry($event)" title="Selecciona el país">
                <option value="Todos" selected>Todos los países</option>
                <option>México</option>
                <option>EE.UU</option>
                <option>Colombia</option>
                <option>Perú</option>
                <option>Costa Rica</option>
                <option>Panamá</option>
                <option>Honduras</option>
                <option>El Salvador</option>
                <option>Nicaragua</option>
                <option>Guatemala</option>
                <option>República Dominicana</option>
                <option>Argentina</option>
                <option>Chile</option>
                <option>Uruguay</option>
                <option>Paraguay</option>
                <option>Brazil</option>
            </select>
        </div>
    </div>
    <div class="col-6" *ngIf="selectedReport==9">
        <label class="color-primary" for="fecha">Rango de fecha 1:</label>
        <input class="form-control" type="date" id="fecha" name="fecha" (change)="filterByDate('start',$event)" (ngModel)="startDate">
    </div>
    <div class="col-6" *ngIf="selectedReport==9">
        <label class="color-primary" for="fecha">Rango de fecha 2:</label>
        <input class="form-control" type="date" id="fecha" name="fecha" [min]="minDate" (ngModel)="endDate" (change)="filterByDate('end',$event)">
    </div>
</div>
<div class="row espacio-table">
    <div class="col-lg-12">
        <div class="table-cent">
            <div class="container container-report">
                <div>
                    <h3><strong>{{pais}}</strong></h3>
                </div>
                <div class="total">
                    <h5><strong>Total: {{
                            getTotal()
                            }}</strong></h5>
                </div>
                <div class="row mt-4">
                    <table *ngIf="selectedReport == 1">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <th id="responsable-header">{{header.title5}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of vacantes_oferta">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.nombre}}</strong></td>
                                    <td><strong>{{vacante.nivel}}</strong></td>
                                    <td><strong>{{vacante["fecha-apertura"]}}</strong></td>
                                    <td><strong>{{vacante["dias-transcurridos"].split(".")[0]}} days</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante["responsable"]["name"]}}</strong><br />{{vacante["responsable"]["mail"][0]}}
                                    </td>
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 2">
                        <thead>
                            <tr>
                                <!-- <ng-container *ngIf="header.id === 2"> -->
                                <th id="title">Nombre de la vacante</th>
                                <th id="nivel-header">Nivel</th>
                                <th id="fecha-header">Días transcurridos</th>
                                <th id="days-header">Estatus de cierre</th>
                                <th id="responsable-header">Responsable</th>
                                <!-- </ng-container> -->
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of vacantes_cerradas_mes">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.nombre}}</strong></td>
                                    <td><strong>{{vacante.nivel}}</strong></td>
                                    <td><strong>{{vacante["dias-transcurridos"].split(".")[0]}} days</strong></td>
                                    <td><strong>{{vacante.estatus}}</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante["responsable"]["name"]}}</strong><br />{{vacante["responsable"]["mail"][0]}}
                                    </td>
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 3">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <th id="responsable-header">{{header.title5}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of vacantes_sin_atender">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.nombre}}</strong></td>
                                    <td><strong>{{vacante.nivel}}</strong></td>
                                    <td><strong>{{vacante["dias-transcurridos"].split(".")[0]}} days</strong></td>
                                    <td><strong>{{vacante.estatus}}</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante["responsable"]["name"]}}</strong><br />{{vacante["responsable"]["mail"][0]}}
                                    </td>
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 4">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <th id="responsable-header">{{header.title5}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of finalistas_dry_run">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.finalista}}</strong></td>
                                    <td><strong>{{vacante.estatus}}</strong></td>
                                    <td><strong>{{vacante.resultado}}</strong></td>
                                    <td><strong>{{vacante.vacante}}</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante.responsable}}</strong></td>
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 5">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <th id="responsable-header">{{header.title5}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of descartados_finalistas_dry_run">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.finalista}}</strong></td>
                                    <td><strong>{{vacante.estatus}}</strong></td>
                                    <td><strong>{{vacante.motivo}}</strong></td>
                                    <td><strong>{{vacante.vacante}}</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante.responsable}}</strong></td>
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 6">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <!-- <th id="days-header">{{header.title4}}</th> -->
                                    <!-- <th id="responsable-header">{{header.title5}}</th> -->
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of vacantes_internas_cerradas">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.nombre}}</strong></td>
                                    <td class="td-border-r">
                                        <strong>{{vacante["responsable"]["name"]}}</strong><br />{{vacante["responsable"]["mail"][0]}}
                                    </td>
                                    <td><strong>{{vacante["fecha-ingreso"].split(" ")[0]}}</strong></td>
                                    <!-- <td><strong>{{vacante.fecha}}</strong></td> -->
                                    <!-- <td class="td-border-r"><strong>{{vacante.responsable[0]}}</strong><br/>{{vacante.responsable[1]}}</td> -->
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 9">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <!-- <th id="responsable-header">{{header.title5}}</th> -->
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let contratados of contratados">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{contratados.finalista}}</strong></td>
                                    <td><strong>{{contratados.vacante}}</strong></td>
                                    <td><strong>{{contratados.nivel}}</strong></td>
                                    <td><strong>{{contratados.responsable}}</strong></td>
                                    <!-- <td class="td-border-r"><strong>{{contratados .responsable[0]}}</strong><br/>{{contratados .responsable[1]}}</td> -->
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                    <table *ngIf="selectedReport == 10">
                        <thead>
                            <tr *ngFor="let header of headers">
                                <ng-container *ngIf="header.id === selectedReport">
                                    <th id="title">{{header.title1}}</th>
                                    <th id="nivel-header">{{header.title2}}</th>
                                    <th id="fecha-header">{{header.title3}}</th>
                                    <th id="days-header">{{header.title4}}</th>
                                    <!-- <th id="responsable-header">{{header.title5}}</th> -->
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let vacante of turnover">
                                <tr class="tr-border">
                                    <td class="td-border"><strong>{{vacante.candidato}}</strong></td>
                                    <td><strong>{{vacante.vacante}}</strong></td>
                                    <td><strong>{{vacante.nivel}}</strong></td>
                                    <td><strong>{{vacante.responsable}}</strong></td>
                                    <!-- <td class="td-border-r"><strong>{{ejemplo.responsable[0]}}</strong><br/>{{ejemplo.responsable[1]}}</td> -->
                                </tr>
                                <tr class="espacio-tr"></tr>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>