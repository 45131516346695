<div class="toolbar" role="banner">
  <img
    style="padding-left: 3rem"
    width="200"
    src="assets/genommalab-logo.svg"
  />
</div>
<div class="container-form">
  <div class="form-data">
    <div class="title">
      <h2 class="text-center">Referencias Laborales</h2>
      <p class="text-center">
        Para continuar, por favor completa los campos requeridos.
      </p>
    </div>
    <form
      class="padding-content"
      [formGroup]="referenciasForm"
      (ngSubmit)="submitForm()"
    >
      <!-- Referencia 1 -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h5>Referencia 1:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Nombre <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="nombreReferencia1"
            [ngClass]="{
              'is-invalid': submitted && fa['nombreReferencia1']['errors'],
              'is-valid': fa['nombreReferencia1'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['nombreReferencia1']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['nombreReferencia1']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Cargo <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="cargoReferencia1"
            [ngClass]="{
              'is-invalid': submitted && fa['cargoReferencia1']['errors'],
              'is-valid': fa['cargoReferencia1'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['cargoReferencia1']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['cargoReferencia1']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Dirección de correo electrónico
            <small class="text-danger">*</small></label
          >
          <input
            type="email"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="correoReferencia1"
            [ngClass]="{
              'is-invalid': submitted && fa['correoReferencia1']['errors'],
              'is-valid': fa['correoReferencia1'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['correoReferencia1']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['correoReferencia1']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Número de teléfono <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="numeroTelefonoReferencia1"
            [ngClass]="{
              'is-invalid':
                submitted && fa['numeroTelefonoReferencia1']['errors'],
              'is-valid': fa['numeroTelefonoReferencia1'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['numeroTelefonoReferencia1']['errors']"
            class="invalid-feedback"
          >
            <div
              class=""
              *ngIf="fa['numeroTelefonoReferencia1']['errors']['required']"
            >
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Empresa <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="empresaReferencia1"
            [ngClass]="{
              'is-invalid':
                submitted && fa['empresaReferencia1']['errors'],
              'is-valid': fa['empresaReferencia1'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['empresaReferencia1']['errors']"
            class="invalid-feedback"
          >
            <div
              class=""
              *ngIf="fa['empresaReferencia1']['errors']['required']"
            >
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
      </div>

      <!-- Referencia 2 -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h5>Referencia 2:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Nombre <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="nombreReferencia2"
            [ngClass]="{
              'is-invalid': submitted && fa['nombreReferencia2']['errors'],
              'is-valid': fa['nombreReferencia2'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['nombreReferencia2']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['nombreReferencia2']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Cargo <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="cargoReferencia2"
            [ngClass]="{
              'is-invalid': submitted && fa['cargoReferencia2']['errors'],
              'is-valid': fa['cargoReferencia2'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['cargoReferencia2']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['cargoReferencia2']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Dirección de correo electrónico
            <small class="text-danger">*</small></label
          >
          <input
            type="email"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="correoReferencia2"
            [ngClass]="{
              'is-invalid': submitted && fa['correoReferencia2']['errors'],
              'is-valid': fa['correoReferencia2'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['correoReferencia2']['errors']"
            class="invalid-feedback"
          >
            <div class="" *ngIf="fa['correoReferencia2']['errors']['required']">
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Número de teléfono <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="numeroTelefonoReferencia2"
            [ngClass]="{
              'is-invalid':
                submitted && fa['numeroTelefonoReferencia2']['errors'],
              'is-valid': fa['numeroTelefonoReferencia2'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['numeroTelefonoReferencia2']['errors']"
            class="invalid-feedback"
          >
            <div
              class=""
              *ngIf="fa['numeroTelefonoReferencia2']['errors']['required']"
            >
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 margin-button">
          <label class="color-primary"
            >Empresa <small class="text-danger">*</small></label
          >
          <input
            type="text"
            class="form-control form-control-sm d-flex align-items-center"
            formControlName="empresaReferencia2"
            [ngClass]="{
              'is-invalid':
                submitted && fa['empresaReferencia2']['errors'],
              'is-valid': fa['empresaReferencia2'].valid
            }"
          />
          <small
            class="form-text text-muted danger"
            *ngIf="submitted && fa['empresaReferencia2']['errors']"
            class="invalid-feedback"
          >
            <div
              class=""
              *ngIf="fa['empresaReferencia2']['errors']['required']"
            >
              Olvidaste llenar el campo *
            </div>
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
          <button type="submit" class="btn btn-primary btn-login mb-3">
            Enviar 
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"
  ><p style="font-size: 20px; color: white">{{ statusLoader }}</p></ngx-spinner
>
